import { action, computed, observable, runInAction } from 'mobx';

import formatToShortLocaleDate from 'shared-between-everything/src/date-time-abstractions/formatToShortLocaleDate/formatToShortLocaleDate';
import moment from 'moment-timezone';

import {
  filter,
  find,
  getOr,
  isEmpty,
  identity,
  map,
  maxBy,
  overSome,
  conforms,
  pipeline,
  pipelineBreak,
  reject,
  sortBy,
  uniqBy,
} from 'shared-between-everything/src/functionalProgramming';

import NotificationsModel from 'shared-between-front-ends/src/components/public/Notifications/NotificationsModel';
import ActivationModel from 'shared-between-front-ends/src/components/public/Popover/ActivationModel/ActivationModel';
import SelectionInputModel from 'shared-between-front-ends/src/components/public/SelectionInput/SelectionInputModel';
import TextInputModel from 'shared-between-front-ends/src/components/public/TextInput/TextInputModel';
import formatToShortWeekday from 'shared-between-front-ends/src/date-time-abstractions/formatToShortWeekday/formatToShortWeekday';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import browserStorageImport from 'shared-between-front-ends/src/doings/browserStorage/browserStorage';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import required from 'shared-between-front-ends/src/validators/required/requiredValidator';
import DataPopulationModel from '../../ResourceDashboardModel/DataPopulationModel/DataPopulationModel';
import InteractiveEffortModel from '../InteractiveEffort/InteractiveEffortModel/InteractiveEffortModel';
import callForEffortDefinitionCatalogsImport from './callForEffortDefinitionCatalogs/callForEffortDefinitionCatalogs';
import callForSubmitEffortEntriesImport from './callForSubmitEffortEntries/callForSubmitEffortEntries';
import withLaziness from '../../../team/UpdateTeam/withLaziness/withLaziness';
import formatToDateTime from 'shared-between-everything/src/date-time-abstractions/formatToDateTime/formatToDateTime';

export default class AppointmentEffortEntriesModel {
  static isSingleton = false;

  dependencies = {};

  // the modified timestamp of the report efforts
  _modifiedTimestamp = null;

  constructor({
    routingModel = getModel(RoutingModel),
    notificationsModel = getModel(NotificationsModel),
    dataPopulationModel = getModel(DataPopulationModel),
    activationModel = getModel(ActivationModel),
    callForEffortDefinitionCatalogs = callForEffortDefinitionCatalogsImport,
    callForSubmitEffortEntries = callForSubmitEffortEntriesImport,
    browserStorage = browserStorageImport,
    modifiedTime = new Date(),
  } = {}) {
    this.dependencies.routingModel = routingModel;
    this.dependencies.notificationsModel = notificationsModel;
    this.dependencies.dataPopulationModel = dataPopulationModel;
    this.dependencies.activationModel = activationModel;
    this.dependencies.callForEffortDefinitionCatalogs = callForEffortDefinitionCatalogs;
    this.dependencies.callForSubmitEffortEntries = callForSubmitEffortEntries;
    this.dependencies.browserStorage = browserStorage;

    this.catalogItemSearchInput = new TextInputModel();

    this._modifiedTimestamp = modifiedTime.toISOString();
  }

  @computed
  get dataIsAvailable() {
    return (
      this.dependencies.dataPopulationModel.fullDataIsAvailable &&
      this._appointmentReload.promiseStatus.fulfilled
    );
  }

  @computed
  get resourceId() {
    return this.dependencies.routingModel.pathParameters.resourceId;
  }

  @computed
  get appointmentId() {
    return this.dependencies.routingModel.pathParameters.appointmentId;
  }

  @computed
  get currentResourceName() {
    return this.dependencies.dataPopulationModel.currentResourceName;
  }

  @computed
  get teamName() {
    return this.dependencies.dataPopulationModel.team.name;
  }

  @observable _newManualEfforts = new Map();

  _appointmentReload = withLaziness(async () => {
    //console.log('CALL', this.appointmentId);
    const startTime = formatToDateTime(
      moment(this._normalizedItemForAppointment.date),
    );

    return await this.dependencies.dataPopulationModel._updateByStartTime(
      startTime,
    );
  });

  get _normalizedItemForAppointment() {
    return pipeline(
      this.dependencies.dataPopulationModel.currentResourceNormalizedItems,
      find({ appointment: { id: this.appointmentId } }),
    );
  }

  _toInteractiveEffortFor = expanded => effort =>
    new InteractiveEffortModel({
      expanded,
      effort,
      reportDate: this._normalizedItemForAppointment.date,
      teamResources: this.dependencies.dataPopulationModel.teamResources,
      currentResource: this.dependencies.dataPopulationModel.currentResource,
      browserStorage: this.dependencies.browserStorage,
      notificationsModel: this.dependencies.notificationsModel,
      activationModel: this.dependencies.activationModel,
    });

  _toExpandedInteractiveEffort = this._toInteractiveEffortFor(true);
  _toNonExpandedInteractiveEffort = this._toInteractiveEffortFor(false);

  @computed
  get _normalizedItem() {
    const toEffortModel = effort =>
      this.catalogItemIdsForEffortsThatAreOpen.has(
        effort.effortDefinitionCatalogItem.id,
      )
        ? this._toExpandedInteractiveEffort(effort)
        : this._toNonExpandedInteractiveEffort(effort);

    return pipeline(
      this._normalizedItemForAppointment,

      ({ issuedEfforts, manualEfforts, date, workOrder }) => ({
        issuedEfforts: pipeline(
          issuedEfforts,
          map(toEffortModel),
          sortBy('effortDefinitionCatalogItem.name'),
        ),

        manualEfforts: pipeline(
          manualEfforts,
          map(toEffortModel),
          sortBy('effortDefinitionCatalogItem.name'),
        ),

        date,

        workOrder,
      }),
    );
  }

  @computed
  get returnToResourceDashboardString() {
    return pipeline(
      this._normalizedItem,
      ({ workOrder, date }) =>
        `${workOrder.name} ${formatToShortWeekday(
          date,
        )} ${formatToShortLocaleDate(date)}`,
    );
  }

  @computed
  get issuedEfforts() {
    return this._normalizedItem.issuedEfforts;
  }

  @computed
  get manualEfforts() {
    return this._normalizedItem.manualEfforts;
  }

  @computed
  get newManualEfforts() {
    return [...this._newManualEfforts.values()].reverse();
  }

  @computed
  get _allEfforts() {
    return [
      ...this.issuedEfforts,
      ...this.manualEfforts,
      ...this.newManualEfforts,
    ];
  }

  @computed
  get spaceBetweenNewAndOldManualEffortsNeedsGutter() {
    return !isEmpty(this.newManualEfforts) && !isEmpty(this.manualEfforts);
  }

  @computed
  get issuedEffortsAreShown() {
    return !isEmpty(this.issuedEfforts);
  }

  @computed
  get manualEffortsAreShown() {
    return !isEmpty(this.newManualEfforts) || !isEmpty(this.manualEfforts);
  }

  @computed
  get hasEfforts() {
    return this.issuedEffortsAreShown || this.manualEffortsAreShown;
  }

  returnToResourceDashboard = () => {
    this.dependencies.routingModel.setRouteTo({
      name: 'resource-dashboard-select-appointment',

      pathParameters: {
        resourceId: this.dependencies.routingModel.pathParameters.resourceId,
        appointmentId: this.appointmentId,
      },
    });
  };

  @computed
  get catalogsAreAvailable() {
    return !!this._catalogs;
  }

  @observable
  _catalogs;

  @computed
  get _unusedCatalogs() {
    const usedCatalogItemIds = pipeline(
      [...this.issuedEfforts, ...this.manualEfforts, ...this.newManualEfforts],
      map('effortDefinitionCatalogItem.id'),
      ids => new Set(ids),
    );

    return pipeline(
      this._catalogs,
      reject(({ catalogItem }) => usedCatalogItemIds.has(catalogItem.id)),
    );
  }

  @computed
  get catalogSelectionOptions() {
    return pipeline(
      this._unusedCatalogs,
      uniqBy('catalog.id'),
      map(({ catalog }) => ({
        id: catalog.id,
        name: catalog.name,
        props: { 'data-catalog-e2e-test': catalog.name },
      })),
      sortBy('name'),
    );
  }

  @computed
  get catalogItemSelectionOptions() {
    const selectedCatalog = this.newEffortDefinitionCatalog.internalValue;

    if (!selectedCatalog) {
      return [];
    }

    return pipeline(
      this._unusedCatalogs,
      filter({ catalog: { id: selectedCatalog.id } }),
      map(({ catalogItem, catalog }) => ({
        id: catalogItem.id,
        name: catalogItem.name,
        catalog,
        catalogItem,
      })),
      this.catalogItemSearchInput.internalValue
        ? filter(
            overSome([
              conforms({
                name: includesCaseInsensitive(
                  this.catalogItemSearchInput.internalValue,
                ),
              }),
            ]),
          )
        : identity,
      sortBy('name'),
    );
  }

  toCatalogItemWithPopover = catalogItem => {
    const activationId = `popover-effort-description-${catalogItem.id}`;

    return {
      activationId,
      closeEffortDescriptionPopover: () => {
        this.dependencies.activationModel.deactivate(activationId);
      },
    };
  };

  openPopOverForAddingEffortDefinition = async () => {
    this.dependencies.activationModel.activate(
      'adding-of-effort-definition-pop-over',
      {
        showOverlay: true,
      },
    );

    const {
      response: normalizedCatalogs,
    } = await this.dependencies.callForEffortDefinitionCatalogs({
      teamId: this.dependencies.dataPopulationModel.team.id,
    });

    runInAction(() => {
      this._catalogs = normalizedCatalogs;
    });

    pipeline(
      this._allEfforts,
      reject('effortDefinitionCatalog.relevantTo'),
      maxBy('expectedEffortDefinition.modifiedDateTime'),

      getOr(
        pipelineBreak,
        'effortDefinitionCatalogItem.effortDefinitionCatalogId',
      ),

      effortDefinitionCatalogId =>
        find(
          { id: effortDefinitionCatalogId },
          this.newEffortDefinitionCatalog.valueOptions,
        ),

      getOr(pipelineBreak, 'id'),

      this.newEffortDefinitionCatalog.setInboundValue,
    );
  };

  newEffortDefinitionCatalog = new SelectionInputModel({
    getValueOptions: () => this.catalogSelectionOptions,
    validators: [required],
    onChange: () => {
      this.newEffortDefinitionCatalogItem = null;
    },
  });

  @observable
  newEffortDefinitionCatalogItem = null;

  @action
  setCatalogItem = item => {
    this.newEffortDefinitionCatalogItem = item;
  };

  isCatalogItemSelected = item => this.newEffortDefinitionCatalogItem === item;

  @computed
  get newEffortDefinitionCanBeSubmitted() {
    return (
      this.newEffortDefinitionCatalog.isValid &&
      !!this.newEffortDefinitionCatalogItem
    );
  }

  @action
  addEffortDefinition = () => {
    const effortDefinitionCatalogItem = this.newEffortDefinitionCatalogItem;

    this._newManualEfforts.set(
      effortDefinitionCatalogItem.id,

      this._toExpandedInteractiveEffort({
        expectedEffortDefinition: {
          type: 'manual',
          effortDefinitionCatalogItemId: effortDefinitionCatalogItem.id,
          effort: effortDefinitionCatalogItem.effort,
          duration: effortDefinitionCatalogItem.duration,
          modifiedDateTime: '3015-10-21T07:28:00Z',
        },

        effortDefinitionCatalogItem: {
          ...effortDefinitionCatalogItem,
          effortDefinitionCatalogId: this.newEffortDefinitionCatalogItem.id,
        },

        roundedDurationPerUnit: {
          amount:
            Math.round(effortDefinitionCatalogItem.duration.amount * 100) / 100,
          measurementUnitId: 'hour',
        },

        actualEffortEntries: [],
      }),
    );

    this.dependencies.activationModel.deactivate(
      'adding-of-effort-definition-pop-over',
    );
  };

  cancelAddingOfManualEffort = () => {
    this.dependencies.activationModel.deactivate(
      'adding-of-effort-definition-pop-over',
    );
  };

  catalogItemIdsForEffortsThatAreOpen = new Set();

  @computed
  get _catalogItemIdsForEffortsThatAreOpen() {
    return pipeline(
      this._allEfforts,
      filter('expectedEffortEntryIsExpanded.internalValue'),
      map('effortDefinitionCatalogItem.id'),
    );
  }

  reloadPage = () => {
    window.location.reload();
  };

  openReportUpdatePopover = () => {
    this.dependencies.activationModel.activate(
      'report-updated-notice-pop-over',
      {
        showOverlay: true,
      },
    );
  };

  submitEffortEntries = () => {
    this.catalogItemIdsForEffortsThatAreOpen.clear();

    this._catalogItemIdsForEffortsThatAreOpen.forEach(catalogItemId => {
      this.catalogItemIdsForEffortsThatAreOpen.add(catalogItemId);
    });

    const callForProcessResponse = async response => {
      if (response.callWasSuccessful) {
        await this.dependencies.dataPopulationModel.refresh();
      } else {
        this.openReportUpdatePopover();
      }

      return response;
    };

    return pipeline(
      [
        ...this._normalizedItem.issuedEfforts,
        ...this._normalizedItem.manualEfforts,
        ...this.newManualEfforts,
      ],

      efforts => ({
        resourceId: this.resourceId,

        appointmentId: this.appointmentId,

        modifiedTimestamp: this._modifiedTimestamp,

        effortEntries: efforts.map(
          ({ effortDefinitionCatalogItem, participations }) => ({
            effortDefinitionCatalogItemId: effortDefinitionCatalogItem.id,

            participations: participations.map(participation => ({
              effortAmount: participation.actualEffortInput.internalValue,
              comment: participation.comment.internalValue,

              participants: participation.participatingResourceIds.map(
                resourceId => ({
                  resourceId,
                }),
              ),
            })),
          }),
        ),
      }),

      this.dependencies.callForSubmitEffortEntries,

      callForProcessResponse,

      response => {
        runInAction(() => {
          this._newManualEfforts.clear();
        });

        return response;
      },
    );
  };

  submitEffortEntriesAndClose = async () => {
    const response = await this.submitEffortEntries();
    /* istanbul ignore next */
    response.callWasSuccessful && this.returnToResourceDashboard();
  };
}

const includesCaseInsensitive = a => b => new RegExp(a, 'i').test(b);
