import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';

import InputWrapper from '../../private/InputWrapper/InputWrapper';
import Input from '../../private/Input/Input';

function EmailInput({
  label,
  model = {},
  value = model.outboundValue,
  onChange = model.setValue,
  readOnly = model.readOnly,
  errorText = model.validationTranslationKey,
  ...props
}) {
  return (
    <InputWrapper label={label} errorText={errorText}>
      <Input
        border={{
          size: 'sm',
          color: 'grey5',
          radius: { size: 'zero' },
        }}
        type="email"
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        {...props}
      />
    </InputWrapper>
  );
}

export const NotObserverEmailInput = EmailInput;
export default decorate(observer)(EmailInput);
