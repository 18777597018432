import { action, observable, onBecomeObserved, runInAction } from 'mobx';

import { once } from 'shared-between-everything/src/functionalProgramming';

const withLaziness = toBeDecorated => {
  const refresh = async () => {
    promiseStatus.setFulfilled(false);

    const value = await toBeDecorated();

    runInAction(() => {
      promiseStatus.setValue(value);
      promiseStatus.setFulfilled(true);
    });
  };

  const promiseStatus = observable({
    fulfilled: false,

    setFulfilled: action(function (value) {
      this.fulfilled = value;
    }),

    value: null,

    setValue: action(function (value) {
      this.value = value;
    }),

    refresh,
  });

  const decorated = once(refresh);

  decorated.promiseStatus = promiseStatus;

  onBecomeObserved(promiseStatus, 'value', decorated);
  onBecomeObserved(promiseStatus, 'fulfilled', decorated);

  return decorated;
};

export default withLaziness;
