import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import schedulerTranslations from '../../../../../../schedulerTranslations';

const translate = localTranslate(schedulerTranslations);

const ConfirmationMessage = ({
  isResourceErpIdsSent,
  resourceErpIdsSuccessMessage,
  isResourceDataValid,
}) => {
  return (
    <Div
      data-confirmation-message
      absolutePosition="my-top-left-of-parent-with-sm-offset"
      width="md"
    >
      {isResourceErpIdsSent && (
        <Div>
          {resourceErpIdsSuccessMessage === 'sentSuccessfullyToIFS' && (
            <Flex centeredVertically>
              <BodyText
                size="3xs"
                color="action"
                width="xlg"
                alignTextTo="right"
              >
                {resourceErpIdsSuccessMessage &&
                  translate(resourceErpIdsSuccessMessage)}
              </BodyText>

              <Gutter size="xs" />

              <Icon name={'check'} color="action" />
            </Flex>
          )}

          {resourceErpIdsSuccessMessage === 'resourceErpIdNotRegistered' && (
            <Flex centeredVertically>
              <BodyText
                size="3xs"
                color="error"
                width="xlg"
                alignTextTo="right"
              >
                {resourceErpIdsSuccessMessage &&
                  translate(resourceErpIdsSuccessMessage)}
              </BodyText>

              <Gutter size="xs" />

              <Icon name="circle-exclamation" color="error" />
            </Flex>
          )}
        </Div>
      )}

      {!isResourceErpIdsSent &&
        resourceErpIdsSuccessMessage === 'resourceErpIdCallNotSuccessful' && (
          <Flex centeredVertically>
            <BodyText size="3xs" color="error" width="xlg" alignTextTo="right">
              {resourceErpIdsSuccessMessage &&
                translate(resourceErpIdsSuccessMessage)}
            </BodyText>

            <Gutter size="xs" />

            <Icon name="mug-saucer" color="error" />
          </Flex>
        )}

      {!isResourceDataValid && isResourceErpIdsSent === false && (
        <Flex centeredVertically>
          <BodyText size="3xs" color="error" width="xlg" alignTextTo="right">
            {translate('resourceValidationWarning')}
          </BodyText>

          <Gutter size="xs" />

          <Icon name="circle-exclamation" color="error" />
        </Flex>
      )}
    </Div>
  );
};

export default decorate(observer)(ConfirmationMessage);
