import { action, computed, observable, runInAction, untracked } from 'mobx';
import moment from 'moment-timezone';
import addDays from 'shared-between-everything/src/date-time-abstractions/addDays/addDays';
import addHours from 'shared-between-everything/src/date-time-abstractions/addHours/addHours';
import formatToDate from 'shared-between-everything/src/date-time-abstractions/formatToDate/formatToDate';
import formatToDateTime from 'shared-between-everything/src/date-time-abstractions/formatToDateTime/formatToDateTime';
import formatToShortLocaleDate from 'shared-between-everything/src/date-time-abstractions/formatToShortLocaleDate/formatToShortLocaleDate';
import getCurrentDate from 'shared-between-everything/src/date-time-abstractions/getCurrentDate/getCurrentDate';
import getDifferenceInHours from 'shared-between-everything/src/date-time-abstractions/getDifferenceInHours/getDifferenceInHours';
import isBeforeThan from 'shared-between-everything/src/date-time-abstractions/isBeforeThan/isBeforeThan';
import isToday from 'shared-between-everything/src/date-time-abstractions/isToday/isToday';
import isTomorrow from 'shared-between-everything/src/date-time-abstractions/isTomorrow/isTomorrow';
import getRandomIdImport from 'shared-between-everything/src/doings/getRandomId/getRandomId';
import {
  conforms,
  every,
  filter,
  find,
  flatMap,
  flow,
  get,
  getOr,
  identity,
  isEmpty,
  isNull,
  join,
  map,
  matches,
  memoize,
  orderBy,
  over,
  overSome,
  partition,
  pipeline,
  range,
  reject,
  sortBy,
  take,
  uniqBy,
} from 'shared-between-everything/src/functionalProgramming';
import BooleanInputModel from 'shared-between-front-ends/src/components/public/CheckboxInput/BooleanInputModel';
import NotificationsModel from 'shared-between-front-ends/src/components/public/Notifications/NotificationsModel';
import ActivationModel from 'shared-between-front-ends/src/components/public/Popover/ActivationModel/ActivationModel';
import TextInputModel from 'shared-between-front-ends/src/components/public/TextInput/TextInputModel';
import formatToShortWeekday from 'shared-between-front-ends/src/date-time-abstractions/formatToShortWeekday/formatToShortWeekday';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import resourceDashboardTranslations from '../resourceDashboardTranslations';
import callForSavingOfWorkOrderDescriptionImport from './callForSavingOfWorkOrderDescription/callForSavingOfWorkOrderDescription';
import callForSelfSchedulingOfAppointmentImport from './callForSelfSchedulingOfAppointment/callForSelfSchedulingOfAppointment';
import DataPopulationModel from './DataPopulationModel/DataPopulationModel';
import scrollIntoViewImport from './scrollIntoView/scrollIntoView';

const translate = localTranslate(resourceDashboardTranslations);

export default class ResourceDashboardModel {
  dependencies = {};

  constructor({
    routingModel = getModel(RoutingModel),
    dataPopulationModel = getModel(DataPopulationModel),
    activationModel = getModel(ActivationModel),
    callForSavingOfWorkOrderDescription = callForSavingOfWorkOrderDescriptionImport,
    notificationsModel = getModel(NotificationsModel),
    callForSelfSchedulingOfAppointment = callForSelfSchedulingOfAppointmentImport,
    getRandomId = getRandomIdImport,
    maximumNumberOfShownTeamWorkOrders = 100,
    scrollIntoView = scrollIntoViewImport,
  } = {}) {
    this.dependencies.routingModel = routingModel;
    this.dependencies.dataPopulationModel = dataPopulationModel;
    this.dependencies.activationModel = activationModel;
    this.dependencies.callForSavingOfWorkOrderDescription = callForSavingOfWorkOrderDescription;
    this.dependencies.notificationsModel = notificationsModel;
    this.dependencies.callForSelfSchedulingOfAppointment = callForSelfSchedulingOfAppointment;
    this.dependencies.getRandomId = getRandomId;
    this.dependencies.maximumNumberOfShownTeamWorkOrders = maximumNumberOfShownTeamWorkOrders;
    this.dependencies.scrollIntoView = scrollIntoView;
  }

  shouldAutoScrollToCurrentDateOnRender = true;

  @computed
  get dataIsAvailable() {
    return this.dependencies.dataPopulationModel.dataIsAvailable;
  }

  @computed
  get currentResourceName() {
    return this.dependencies.dataPopulationModel.currentResourceName;
  }

  @computed
  get teamName() {
    return this.dependencies.dataPopulationModel.team.name;
  }

  get teamId() {
    return this.dependencies.dataPopulationModel.team.id;
  }

  @computed
  get _uncroppedTeamWorkOrders() {
    return pipeline(
      this.dependencies.dataPopulationModel.teamWorkOrders,

      this.teamWorkOrderSearchInput.debouncedInternalValue
        ? filter(
            overSome([
              conforms({
                name: includesCaseInsensitive(
                  this.teamWorkOrderSearchInput.debouncedInternalValue,
                ),
              }),

              conforms({
                erpId: includesCaseInsensitive(
                  this.teamWorkOrderSearchInput.debouncedInternalValue,
                ),
              }),
            ]),
          )
        : identity,

      map(workOrder => ({
        erpIdIsDefined: !!workOrder.erpId,
        ...workOrder,
      })),

      orderBy(['erpIdIsDefined', 'erpId', 'name'], ['desc', 'desc', 'asc']),

      map(workOrder => ({
        workOrder,

        selfSchedule: async date => {
          this.shouldAutoScrollToCurrentDateOnRender = false;

          const appointment = {
            id: this.dependencies.getRandomId(),
            teamId: this.teamId,
            workOrderId: workOrder.id,
            resourceId: this.currentResourceId,
            startDateTime: pipeline(formatToDateTime(date), addHours(8)),
            endDateTime: pipeline(formatToDateTime(date), addHours(8)),
          };

          this.dependencies.callForSelfSchedulingOfAppointment(appointment);

          runInAction(() => {
            this.dependencies.dataPopulationModel.data.normalizedItems.push({
              date,
              resource: {
                id: this.currentResourceId,
                firstName: this.dependencies.dataPopulationModel.currentResource
                  .firstName,
                lastName: this.dependencies.dataPopulationModel.currentResource
                  .lastName,
              },
              workOrder,
              appointment: {
                ...appointment,
                info: null,
                date,
              },

              efforts: pipeline(
                this.dependencies.dataPopulationModel.data.normalizedItems,
                find({ workOrder: { id: workOrder.id } }),
                getOr([], 'efforts'),
              ),
            });
          });

          this.dependencies.activationModel.deactivate(
            `pop-up-for-self-scheduling-${date}`,
          );
        },
      })),
    );
  }

  @computed
  get teamWorkOrders() {
    return pipeline(
      this._uncroppedTeamWorkOrders,
      take(this.dependencies.maximumNumberOfShownTeamWorkOrders),
    );
  }

  @computed
  get teamWorkOrdersAreCropped() {
    return (
      this._uncroppedTeamWorkOrders.length >
      this.dependencies.maximumNumberOfShownTeamWorkOrders
    );
  }

  @computed
  get currentResourceId() {
    return this.dependencies.routingModel.pathParameters.resourceId;
  }

  @computed
  get selectedAppointmentId() {
    return this.dependencies.routingModel.pathParameters.appointmentId;
  }

  @observable isLoading = false;

  @action
  setIsLoading = isLoading => {
    this.isLoading = isLoading;
  };

  @computed
  get normalizedItems() {
    return pipeline(
      this.dependencies.dataPopulationModel.currentResourceNormalizedItems,

      map(normalizedItem => {
        const actualEfforts = pipeline(
          [...normalizedItem.manualEfforts, ...normalizedItem.issuedEfforts],
          flatMap('actualEffortEntries'),
          reject(
            flow(get('participations'), map('effort.amount'), every(isNull)),
          ),
        );

        const actualEffortsForDay = pipeline(
          actualEfforts,
          filter({ date: normalizedItem.date }),
        );

        const workOrderDescriptionInput = getUncommittedWorkOrderDescriptionInput(
          normalizedItem.workOrder.id,
          normalizedItem.workOrder.description,
        );

        const committedWorkOrderDescriptionInput = getCommittedWorkOrderDescriptionInput(
          normalizedItem.workOrder.id,
          normalizedItem.workOrder.description,
        );

        return {
          ...normalizedItem,

          durationString: `${getDifferenceInHours(
            normalizedItem.appointment.startDateTime,
            normalizedItem.appointment.endDateTime,
          )} h`,

          startEndTimesToString: `${moment(
            normalizedItem.appointment.startDateTime,
          ).format('HH:mm')} - ${moment(
            normalizedItem.appointment.endDateTime,
          ).format('HH:mm')}`,

          appointmentDetailVisibility: untracked(
            () =>
              new BooleanInputModel({
                initialInternalValue:
                  normalizedItem.appointment.id === this.selectedAppointmentId,
              }),
          ),

          hasActualEffortEntries: !isEmpty(actualEfforts),
          hasActualEffortEntriesForDay: !isEmpty(actualEffortsForDay),

          issuedEffortsAreShown: !isEmpty(normalizedItem.issuedEfforts),

          manualEffortsAreShown: !isEmpty(normalizedItem.manualEfforts),

          addressOrErpIdIsPresent: overSome([
            'address',
            'postalCode',
            'city',
            'erpId',
          ])(normalizedItem.workOrder),

          resourceDashboardModel: this,

          showWorkOrderDescriptionPopover: () => {
            this.dependencies.activationModel.activate(
              `work-order-description-popover-for-${normalizedItem.appointment.id}`,
              { showOverlay: true },
            );
          },

          cancelEditingOfWorkOrderDescription: () => {
            this.dependencies.activationModel.deactivate(
              `work-order-description-popover-for-${normalizedItem.appointment.id}`,
            );

            workOrderDescriptionInput.setInternalValue(
              committedWorkOrderDescriptionInput.internalValue,
            );
          },

          workOrderDescriptionInput,

          committedWorkOrderDescriptionInput,

          activationModel: this.dependencies.activationModel,

          saveWorkOrderDescription: async () => {
            this.setIsLoading(true);

            await this.dependencies.callForSavingOfWorkOrderDescription({
              workOrderId: normalizedItem.workOrder.id,
              workOrderDescription: workOrderDescriptionInput.internalValue,
            });

            this.dependencies.activationModel.deactivate(
              `work-order-description-popover-for-${normalizedItem.appointment.id}`,
            );

            committedWorkOrderDescriptionInput.setInternalValue(
              workOrderDescriptionInput.internalValue,
            );

            this.dependencies.notificationsModel.setSuccess(
              translate('workOrderDescriptionUpdateSuccess'),

              {
                'data-notification-for-successful-edit-e2e-test': true,
              },
            );

            this.setIsLoading(false);
          },

          navigateToEffortEntries: () => {
            this.dependencies.routingModel.setRouteTo({
              name: 'resource-dashboard/appointment-effort-entries',

              pathParameters: {
                resourceId: this.currentResourceId,
                appointmentId: normalizedItem.appointment.id,
              },
            });
          },

          otherResourcesInSameWorkOrderString: pipeline(
            normalizedItem.participants,
            uniqBy('id'),
            map(({ firstName, lastName }) => `${firstName} ${lastName}`),
            join(', '),
          ),

          issuedEfforts: pipeline(
            normalizedItem.issuedEfforts,

            map(
              toEffortWithPopoverFor(
                this.dependencies.activationModel.deactivate,
              ),
            ),

            sortBy('effortDefinitionCatalogItem.name'),
          ),

          manualEfforts: pipeline(
            normalizedItem.manualEfforts,

            map(
              toEffortWithPopoverFor(
                this.dependencies.activationModel.deactivate,
              ),
            ),

            sortBy('effortDefinitionCatalogItem.name'),
          ),
        };
      }),
    );
  }

  @computed
  get days() {
    return pipeline(
      getDatesForTwoWeeksInPastAndFuture(),
      map(date => [date, this.normalizedItems.filter(matches({ date }))]),
      map(([date, normalizedItems]) => ({
        date,

        dateAlias: getDateAlias(date),

        dateString: pipeline(
          date,
          over([formatToShortWeekday, formatToShortLocaleDate]),
          join(' '),
        ),

        scrollIntoView: this.dependencies.scrollIntoView,

        shouldAutoScrollToCurrentDateOnRender: this
          .shouldAutoScrollToCurrentDateOnRender,

        teamWorkOrders: this.teamWorkOrders,

        teamWorkOrdersAreCropped: this.teamWorkOrdersAreCropped,

        maximumNumberOfShownTeamWorkOrders: this.dependencies
          .maximumNumberOfShownTeamWorkOrders,

        teamWorkOrderSearchInput: this.teamWorkOrderSearchInput,

        cancelSelfScheduling: () => {
          this.dependencies.activationModel.deactivate(
            `pop-up-for-self-scheduling-${date}`,
          );
        },

        showSelfSchedulingPopOver: () => {
          this.dependencies.activationModel.activate(
            `pop-up-for-self-scheduling-${date}`,
            {
              showOverlay: true,
            },
          );
        },

        normalizedItems: pipeline(
          normalizedItems,
          filter({ date }),
          orderBy(['appointment.startDateTime'], ['asc']),
        ),
      })),
      orderBy(['date'], ['asc']),
      partition(flow(get('date'), isBeforeThan(getCurrentDate()))),
      ([pastDays, [firstFutureDay, ...restFutureDays]]) => [
        ...pastDays,
        { delimiter: true },
        { firstFutureDay: true, ...firstFutureDay },
        ...restFutureDays,
      ],
    );
  }

  scrollToFutureAppointments = () => {
    this.dependencies.scrollIntoView(
      document.querySelector('[data-current-date]'),
    );
  };

  scrollToSelectedAppointment = () => {
    const appointmentId = this.selectedAppointmentId;
    const targetEl =
      appointmentId &&
      document.querySelector(`[data-appointment-id="${appointmentId}"]`);

    /* istanbul ignore next */
    targetEl && this.dependencies.scrollIntoView(targetEl);
  };

  teamWorkOrderSearchInput = new TextInputModel();
}

const getDateAlias = date => {
  return isToday(date)
    ? translate('today')
    : isTomorrow(date)
    ? translate('tomorrow')
    : '';
};

const toEffortWithPopoverFor = deactivate => effort => {
  const effortDescriptionPopoverActivationId = `effort-description-pop-over/${effort.appointment.id}/${effort.effortDefinitionCatalogItem.id}`;

  return {
    ...effort,

    expectedEffortIsShown: effort.expectedEffortDefinition.type === 'issued',

    descriptionIsShown: !!effort.effortDefinitionCatalogItem.description,

    includingIsShown: !!effort.effortDefinitionCatalogItem.including,

    excludingIsShown: !!effort.effortDefinitionCatalogItem.excluding,

    effortDescriptionPopoverActivationId,

    closeEffortDescriptionPopover: () => {
      deactivate(effortDescriptionPopoverActivationId);
    },
  };
};

const getCommittedWorkOrderDescriptionInput = memoize(
  (workOrderId, workOrderDescription) =>
    untracked(
      () =>
        new TextInputModel({
          initialInternalValue: workOrderDescription,
        }),
    ),
);

const getUncommittedWorkOrderDescriptionInput = memoize(
  (workOrderId, workOrderDescription) =>
    untracked(
      () =>
        new TextInputModel({
          initialInternalValue: workOrderDescription,
        }),
    ),
);

const getDatesForTwoWeeksInPastAndFuture = () =>
  pipeline(
    range(-13, 16),
    map(daysOffset => addDays(daysOffset, getCurrentDate())),
    map(formatToDate),
  );

const includesCaseInsensitive = a => b => new RegExp(a, 'i').test(b);
