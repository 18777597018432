import { computed, reaction } from 'mobx';
import getWorkOrderTypeValueObject from 'shared-between-everything/src/getWorkOrderTypeValueObject';

import CompletionInputModel from 'shared-between-front-ends/src/components/public/CompletionInput/CompletionInputModel/CompletionInputModel';

export default class WorkOrderModel {
  constructor({
    callForModifyingUrgencyOfWorkOrder,
    callForModifyingDoneStatusOfWorkOrder,
    callForModifyingNotificationStatusOfWorkOrder,
    callForModifyingNightShiftStatusOfWorkOrder,
    callForModifyingLunchStatusOfWorkOrder,
    setSelectedWorkOrderId,
    schedulerModel,
    workOrderData,
  }) {
    this.schedulerModel = schedulerModel;
    this.callForModifyingUrgencyOfWorkOrder = callForModifyingUrgencyOfWorkOrder;
    this.callForModifyingDoneStatusOfWorkOrder = callForModifyingDoneStatusOfWorkOrder;
    this.callForModifyingNotificationStatusOfWorkOrder = callForModifyingNotificationStatusOfWorkOrder;
    this.callForModifyingNightShiftStatusOfWorkOrder = callForModifyingNightShiftStatusOfWorkOrder;
    this.callForModifyingLunchStatusOfWorkOrder = callForModifyingLunchStatusOfWorkOrder;
    this.setSelectedWorkOrderId = setSelectedWorkOrderId;

    this.done = new CompletionInputModel({
      initialInternalValue: workOrderData.done,
    });

    this.urgent = new CompletionInputModel({
      initialInternalValue: workOrderData.urgent,
    });

    this.notification = new CompletionInputModel({
      initialInternalValue: workOrderData.notification,
    });

    this.nightShift = new CompletionInputModel({
      initialInternalValue: workOrderData.nightShift,
    });

    this.name = workOrderData.name;
    this.erpId = workOrderData.erpId;
    this.teamId = workOrderData.teamId;
    this.address = workOrderData.address;
    this.type = workOrderData.type;
    this.customerName = workOrderData.customerName;
    this.link = workOrderData.link;
    this.description = workOrderData.description;
    this.postalCode = workOrderData.postalCode;
    this.city = workOrderData.city;
    this.deadlineDate = workOrderData.deadlineDate;
    this.billableHours = workOrderData.billableHours;
    this.id = workOrderData.id;
    this.color = workOrderData.color;

    reaction(
      () => this.done.internalValue,
      () => void this.updateDone(),
      { delay: 500 },
    );

    reaction(
      () => this.urgent.internalValue,
      () => void this.updateUrgency(),
      { delay: 500 },
    );

    reaction(
      () => this.notification.internalValue,
      () => void this.updateNotification(),
      { delay: 500 },
    );
  }

  updateDone = () => {
    this.callForModifyingDoneStatusOfWorkOrder({
      workOrderId: this.id,
      done: this.done.internalValue,
    });
  };

  updateUrgency = () => {
    this.callForModifyingUrgencyOfWorkOrder({
      workOrderId: this.id,
      urgent: this.urgent.internalValue,
    });
  };

  updateNotification = () => {
    this.callForModifyingNotificationStatusOfWorkOrder({
      workOrderId: this.id,
      notification: this.notification.internalValue,
    });
  };

  @computed
  get backgroundColor() {
    if (!!this.done.internalValue) {
      return 'doneBackground';
    }

    const workOrderType = getWorkOrderTypeValueObject(this.type);

    return workOrderType.colorIsUserSelectable
      ? this.color
      : workOrderType.color;
  }

  @computed
  get border() {
    return this.urgent.internalValue
      ? { color: 'urgentBackground', size: 'md' }
      : false;
  }

  @computed get highlight() {
    return this.id === this.schedulerModel.selectedWorkOrderId;
  }

  selectForScheduling = () => {
    const workOrderWasAlreadySelected =
      this.id === this.schedulerModel.selectedWorkOrderId;

    if (workOrderWasAlreadySelected) {
      this.setSelectedWorkOrderId(null);

      return;
    }

    this.setSelectedWorkOrderId(this.id);
  };
}
