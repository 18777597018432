import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';
import pathParametersInputMapper from 'shared-between-front-ends/src/doings/pathParametersInputMapper/pathParametersInputMapper';

const callForResourceDashboardDto = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/resource-dashboard/{resourceId}?startTime={startTime}',
    method: 'GET',
    inputMapper: pathParametersInputMapper,
  },
});

export default callForResourceDashboardDto;
