import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

const getAppointments = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/scheduler/appointments/{teamId}',
    method: 'GET',
  },
  decoratorConfig: {
    spinnerName: 'getting-appointments',
    showSpinnerInstantly: false,
  },
});

export default getAppointments;
