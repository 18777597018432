import React from 'react';
import { observer } from 'mobx-react';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import RouteLink from 'shared-between-front-ends/src/components/public/RouteLink/RouteLink';
import SemanticButton from 'shared-between-front-ends/src/components/public/SemanticButton/SemanticButton';
import SensuelContentBox from '../../../../shared/SensuelContentBox/SensuelContentBox';

const AbsenceWorkOrder = ({
  districtId,
  teamId,
  absenceWorkOrder: {
    backgroundColor,
    name,
    id: workOrderId,
    highlight,
    selectForScheduling,
  },
}) => {
  return (
    <SensuelContentBox
      draggable={true}
      onDragStart={event =>
        event.dataTransfer.setData(
          'appointmentOrWorkOrder',
          JSON.stringify({
            workOrderId,
          }),
        )
      }
      data-work-order-box-test
      leftArea={{
        backgroundColor,
        highlight,
        'data-absence-work-order-box-left-area-test': true,
        padding: { size: 'zero' },
        content: (
          <SemanticButton
            block
            onClick={selectForScheduling}
            padding={{ horizontal: 'sm' }}
            draggable={false}
            occupyVerticalSpace
            color="white"
            hoverColor="grey2"
            data-select-work-order-for-scheduling-test={workOrderId}
          >
            <Flex centered occupyVerticalSpace>
              <Icon
                name={highlight ? 'calendar-check' : 'calendar'}
                size="md"
                data-select-absence-work-order-for-scheduling-test
              />
            </Flex>
          </SemanticButton>
        ),
      }}
      rightArea={{
        backgroundColor: 'white',
        padding: { size: 'zero' },
        content: (
          <RouteLink
            block
            draggable={false}
            routeName="district-team-update-work-order-from-absence-scheduler"
            pathParameters={{
              districtId,
              workOrderId,
              teamId,
            }}
            padding={{
              vertical: 'xxs',
              horizontal: 'sm',
            }}
            data-update-absence-work-order-test={workOrderId}
          >
            <Flex
              centeredVertically
              occupyVerticalSpace
              block
              padding={{ vertical: 'xs' }}
            >
              <BodyText>{name}</BodyText>
            </Flex>
          </RouteLink>
        ),
      }}
    />
  );
};

export default decorate(observer)(AbsenceWorkOrder);
