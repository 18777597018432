import defaultLanguage from 'shared-between-everything/src/defaultLanguage';
import browserStorage from '../browserStorage/browserStorage';

export const dependencies = {
  defaultLanguage,
  browserStorage,
};

export default () =>
  dependencies.browserStorage.get('language') || dependencies.defaultLanguage;
