/* istanbul ignore file */
import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import MinimalAppointment from '../MinimalAppointment/MinimalAppointment';

const AppointmentDetails = ({
  appointment,
  listedWorkOrders,
  showScheduledWorkOrders,
  ...props
}) => {
  return (
    <Flex stretchToSameHeight {...props}>
      <MinimalAppointment
        appointment={appointment}
        listedWorkOrders={listedWorkOrders}
        showScheduledWorkOrders={showScheduledWorkOrders}
      />

      <Div
        padding={{
          right: 'xxs',
          left: 'zero',
          horizontal: 'sm',
        }}
        flexItem
        backgroundColor="white"
      >
        <Flex spaceBetween centeredVertically>
          <Div flexItem color="black" padding={{ left: 'xs' }}>
            <BodyText singleLine block>
              {appointment.workOrderName}
            </BodyText>

            <BodyText singleLine block size="3xs">
              {appointment.workOrderErpId}
            </BodyText>
          </Div>

          {appointment.info.value && (
            <Icon
              name="file-lines"
              size="sm"
              color="grey90"
              title={appointment.info.value}
            />
          )}
        </Flex>
      </Div>
    </Flex>
  );
};

export default decorate(observer)(AppointmentDetails);
