import bodyParametersInputMapper from 'shared-between-everything/src/doings/bodyParametersInputMapper/bodyParametersInputMapper';
import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

const sendResourceDataToDataVault = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/send-resource-data-to-ifs',
    method: 'POST',
    inputMapper: bodyParametersInputMapper,
  },
});

export default sendResourceDataToDataVault;
