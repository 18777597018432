import { observer } from 'mobx-react';
import React from 'react';

import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import RouteLink from 'shared-between-front-ends/src/components/public/RouteLink/RouteLink';
import Title from 'shared-between-front-ends/src/components/public/Title/Title';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';

import resourceTranslations from '../../../../resource/resourceTranslations';

const translate = localTranslate(resourceTranslations);

const ResourceRowHeader = ({
  resource: {
    id: resourceId,
    backgroundColor,
    firstName,
    lastName,
    competences,
  },
  userRights,
}) => {
  const notUpdatedCompetences = translate('competenceUpdate');
  return (
    <Div
      relative
      occupyVerticalSpace
      padding={{ vertical: 'xs' }}
      backgroundColor={backgroundColor}
    >
      <Icon
        color="grey40"
        shown={userRights.reorderResourcesInTeams}
        size="sm"
        name="grip-lines-vertical"
        absolutePosition="my-center-left-to-center-left-of-parent"
      />

      <Flex
        centeredVertically
        occupyVerticalSpace
        padding={{
          horizontal: 'md',
        }}
      >
        <RouteLink
          draggable={false}
          routeName="resource-dashboard"
          pathParameters={{
            resourceId,
          }}
          hideOverflow
          data-update-resource-test
        >
          <Flex occupyVerticalSpace pileVertically>
            <Title singleLine>
              {lastName}, {firstName}
            </Title>
            <BodyText singleLine size="3xs" color="fontColor">
              {competences ? competences : notUpdatedCompetences}
            </BodyText>
          </Flex>
        </RouteLink>
      </Flex>
    </Div>
  );
};

export default decorate(observer)(ResourceRowHeader);
