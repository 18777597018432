import flow from 'lodash/fp/flow';
import { get } from '../../functionalProgramming';

export const pipelineBreak = Symbol('pipelineBreak');

export default (...functions) =>
  flow(...functions.map(toTentativeAsyncWrapper));

const toTentativeAsyncWrapper = func => argument =>
  withTentativeAsync(withSkippingForPipelineBreakFor(func), argument);

const isAsync = thing => !!get('then', thing);

const withTentativeAsync = (func, argument) =>
  isAsync(argument) ? Promise.resolve(argument).then(func) : func(argument);

const withSkippingForPipelineBreakFor = func => argument => {
  if (argument === pipelineBreak) {
    return pipelineBreak;
  }

  return func(argument);
};
