import { computed } from 'mobx';

export default class ResourceModel {
  constructor({ id, erpId, firstName, lastName, competences, role }) {
    this.id = id;
    this.erpId = erpId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.competences = competences;
    this.role = role;
  }

  @computed get backgroundColor() {
    return mapRoleToColor[this.role] || 'lightGrey';
  }
}

const mapRoleToColor = {
  mechanic: 'grey1',
  designer: 'grey2',
  coordinator: 'grey3',
  foreman: 'grey4',
  subcontractor: 'grey5',
  partTimeEmp: 'grey7',
  specialResource: 'specialResource',
};
