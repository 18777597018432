import { action, computed, observable, reaction, runInAction } from 'mobx';
import moment from 'moment-timezone';
import formatHoursToDateTime from 'shared-between-everything/src/date-time-abstractions/formatHourToDate/formatHoursToDateTime';
import formatToDate from 'shared-between-everything/src/date-time-abstractions/formatToDate/formatToDate';
import formatToYearAndShortMonth from 'shared-between-everything/src/date-time-abstractions/formatToYearAndShortMonth/formatToYearAndShortMonth';
import getDifferenceInHours from 'shared-between-everything/src/date-time-abstractions/getDifferenceInHours/getDifferenceInHours';
import isWeekend from 'shared-between-everything/src/date-time-abstractions/isWeekend/isWeekend';
import setHours from 'shared-between-everything/src/date-time-abstractions/setHours/setHours';
import setMinutes from 'shared-between-everything/src/date-time-abstractions/setMinutes/setMinutes';
import setSeconds from 'shared-between-everything/src/date-time-abstractions/setSeconds/setSeconds';
import { pipeline } from 'shared-between-everything/src/functionalProgramming';
import getWorkOrderTypeValueObject from 'shared-between-everything/src/getWorkOrderTypeValueObject';
import CompletionInputModel from 'shared-between-front-ends/src/components/public/CompletionInput/CompletionInputModel/CompletionInputModel';
import HourInputModel from 'shared-between-front-ends/src/components/public/HourInput/HourInputModel';
import TextInputModel from 'shared-between-front-ends/src/components/public/TextInput/TextInputModel';
import when from 'shared-between-front-ends/src/decorators/when/when';
import withDebounce from 'shared-between-front-ends/src/decorators/withDebounce/withDebounce';
import observed from 'shared-between-front-ends/src/observed';

export default class AppointmentModel {
  dependencies = {};

  constructor(
    callForModifyingLunchStatusOfAppointment,
    callForModifyingNightShiftStatusOfAppointment,
    userRights,
    createAppointment,
    callForModifyAppointment,
    workOrdersById,
    schedulerModel,
    activationModel,
    routingModel,
    callForCancelAppointment,
    appointmentData,
  ) {
    this.dependencies.callForModifyingLunchStatusOfAppointment = callForModifyingLunchStatusOfAppointment;
    this.dependencies.callForModifyingNightShiftStatusOfAppointment = callForModifyingNightShiftStatusOfAppointment;

    this.userRights = userRights;
    this.createAppointment = createAppointment;
    this.callForModifyAppointment = callForModifyAppointment;
    this.workOrdersById = workOrdersById;
    this.schedulerModel = schedulerModel;
    this.activationModel = activationModel;
    this.routingModel = routingModel;
    this.callForCancelAppointment = callForCancelAppointment;

    this.id = appointmentData.id;
    this._isObfuscated = appointmentData.obfuscated;

    this.teamId = appointmentData.teamId;
    this.workOrderId = appointmentData.workOrderId;

    this.info = new TextInputModel({
      defaultValue: appointmentData.info,
      readOnly: !userRights.doScheduling,
    });

    this.startTime = new TextInputModel({
      required: false,
    });

    this.endTime = new TextInputModel({
      required: false,
    });

    this.duration = new HourInputModel({
      readOnly: !userRights.doScheduling,
      defaultValue: getDifferenceInHours(
        appointmentData.startDateTime,
        appointmentData.endDateTime,
      ),
    });

    this.startEndTimesToString = new TextInputModel();

    this.lunch = new CompletionInputModel({
      initialInternalValue: appointmentData.lunch,
    });

    this.nightShift = new CompletionInputModel({
      initialInternalValue: appointmentData.nightShift,
    });

    runInAction(() => {
      this._resourceId = appointmentData.resourceId;

      this.startDateTime = appointmentData.startDateTime;
      this.endDateTime = appointmentData.endDateTime;
      this.startTime.setValue(moment(this.startDateTime).format('HH:mm'));
      this.endTime.setValue(moment(this.endDateTime).format('HH:mm'));
      this.startEndTimesToString.setValue(
        `${this.startTime.value} - ${this.endTime.value}`,
      );
      this.nightShiftStatus = appointmentData.nightShift;
    });

    reaction(
      () => this.lunch.internalValue,
      () => this.updateLunch(),
      { delay: 500 },
    );

    reaction(
      () => this.nightShift.internalValue,
      () => this.updateNightShift(),
      { delay: 500 },
    );
  }

  updateLunch = () => {
    this.dependencies.callForModifyingLunchStatusOfAppointment({
      appointmentId: this.id,
      lunch: this.lunch.internalValue,
    });
  };

  updateNightShift = () => {
    this.dependencies.callForModifyingNightShiftStatusOfAppointment({
      appointmentId: this.id,
      nightShift: this.nightShift.internalValue,
    });
  };

  @when(
    observed(
      'duration.value',
      'startTime.value',
      'endTime.value',
      'info.value',
    ),
  )
  @withDebounce(500)
  debouncedUpdate = () => {
    this.update();
  };

  update = () => {
    this.duration.setValue(
      getDifferenceInHours(this.dateTimeRange.start, this.dateTimeRange.end),
    );
    this.startEndTimesToString.setValue(
      `${this.startTime.value} - ${this.endTime.value}`,
    );
    this.callForModifyAppointment({
      id: this.id,
      teamId: this.teamId,
      workOrderId: this.workOrderId,
      resourceId: this.resourceId,
      startDateTime: this.dateTimeRange.start,
      endDateTime: this.dateTimeRange.end,
      info: this.info.value,
      lunch: this.lunch.value,
      nightShift: this.nightShiftStatus,
    });
  };

  @computed
  get detailsAreVisible() {
    if (
      isWeekend(this.startDateTime) &&
      !this.schedulerModel.weekendsAreShown
    ) {
      return false;
    }

    if (
      getWorkOrderTypeValueObject(this.workOrderType).appointmentsAreSimplified
    ) {
      return false;
    }

    return this.schedulerModel.weeksInView < 4;
  }

  @computed
  get isObfuscated() {
    return !this.workOrder || this._isObfuscated;
  }

  @computed
  get border() {
    return this.workOrder.border;
  }

  @computed
  get backgroundColor() {
    return this.workOrder.backgroundColor;
  }

  @computed
  get color() {
    return this.workOrder.color || 'danger';
  }

  @observable startDateTime;

  @computed
  get dateTimeRange() {
    return {
      start: formatHoursToDateTime(this.startDateTime, this.startTime.value),
      end: formatHoursToDateTime(this.startDateTime, this.endTime.value),
    };
  }

  @observable deleted = false;

  @computed
  get resourceId() {
    return this.deleted ? null : this._resourceId;
  }

  @computed
  get date() {
    return this.deleted ? null : formatToDate(this.dateTimeRange.start);
  }

  @computed
  get yearAndMonth() {
    return formatToYearAndShortMonth(this.dateTimeRange.start);
  }

  @computed
  get resourceIdAndDate() {
    return `${this.resourceId}/${this.date}`;
  }

  @observable _resourceId;

  @computed
  get workOrder() {
    return this.workOrdersById.get(this.workOrderId);
  }

  @computed
  get workOrderName() {
    return this.workOrder && this.workOrder.name;
  }

  @computed
  get titleForScheduler() {
    const tooManyWeeksInView = this.schedulerModel.weeksInView > 4;

    if (tooManyWeeksInView) {
      return null;
    }

    if (
      isWeekend(this.startDateTime) &&
      !this.schedulerModel.weekendsAreShown
    ) {
      return null;
    }

    if (
      getWorkOrderTypeValueObject(this.workOrderType).appointmentsAreSimplified
    ) {
      return this.workOrderName;
    }

    return this.duration.value;
  }

  @computed
  get titleForAbsenceScheduler() {
    const tooManyWeeksInView = this.schedulerModel.weeksInView > 4;

    if (tooManyWeeksInView) {
      return null;
    }

    return this.workOrderName;
  }

  @computed
  get workOrderErpId() {
    return this.workOrder && this.workOrder.erpId;
  }

  @computed
  get workOrderType() {
    return this.workOrder && this.workOrder.type;
  }

  @computed
  get workOrderLink() {
    return this.workOrder.link;
  }

  @computed
  get isRelevantToAbsenceScheduling() {
    return !!getWorkOrderTypeValueObject(this.workOrderType).absenceScheduling;
  }

  @computed
  get _appointmentIsRelatedToSelectedWorkOrder() {
    return this.workOrderId === this.schedulerModel.selectedWorkOrderId;
  }

  @computed
  get disabled() {
    const workOrderIsSelected =
      this.schedulerModel.selectedWorkOrderId !== null;

    if (!workOrderIsSelected) {
      return false;
    }

    return !this._appointmentIsRelatedToSelectedWorkOrder;
  }

  setStartingTime = () => {
    const workOrderStartTime = moment(this.startDateTime)
      .set('hour', 7)
      .set('minutes', 30);
    const otherWorkStartTime = moment(this.startDateTime)
      .set('hour', 8)
      .set('minutes', 0);

    const startTime = moment(this.startDateTime).format('HH:mm');
    /* istanbul ignore next */
    const startDateTime =
      startTime === '00:00'
        ? this.workOrderType === 'workOrder'
          ? moment(workOrderStartTime)
          : moment(otherWorkStartTime)
        : moment(this.startDateTime);

    this.startTime.setValue(startDateTime.utc().format('HH:mm'));
    return startDateTime.utc().format();
  };

  setEndingTime = () => {
    const endTime = moment(this.endDateTime).format('HH:mm');
    /* istanbul ignore next */
    const endDateTime =
      endTime === '00:00'
        ? moment(this.endDateTime).set('hour', 16).set('minutes', 0)
        : moment(this.endDateTime);

    this.endTime.setValue(endDateTime.utc().format('HH:mm'));
    return endDateTime.utc().format();
  };

  setLunchStatus = () => {
    this.lunch.setValue(
      this.workOrderType === 'workOrder' ? moment().utc().format() : null,
    );
    return this.workOrderType === 'workOrder' ? moment().utc().format() : null;
  };

  createInBackend = () => {
    const startTime = this.setStartingTime();
    const endTime = this.setEndingTime();

    this.duration.setValue(getDifferenceInHours(startTime, endTime));

    this.startEndTimesToString.setValue(
      `${this.startTime.value} - ${this.endTime.value}`,
    );

    this.createAppointment({
      pathParameters: {
        teamId: this.teamId,
      },
      bodyParameters: {
        startDateTime: startTime,
        endDateTime: endTime,
        id: this.id,
        resourceId: this.resourceId,
        workOrderId: this.workOrderId,
        teamId: this.teamId,
        info: this.info.value,
        lunch: this.setLunchStatus(),
        nightShift: this.nightShiftStatus,
      },
    });
  };

  navigateToUpdateWorkOrder = () => {
    this.routingModel.setRouteTo({
      name: 'district-team-update-work-order-from-scheduler',
      pathParameters: {
        districtId: this.schedulerModel.districtId,
        teamId: this.teamId,
        workOrderId: this.workOrderId,
        dateTimeRange: this.dateTimeRange,
        lunch: this.lunch.value,
      },
    });
  };

  select = () => {
    if (this._appointmentIsRelatedToSelectedWorkOrder) {
      this.delete();

      return;
    }

    if (!!this.schedulerModel.selectedWorkOrderId) {
      this.schedulerModel.scheduleWorkOrderToGridCell({
        workOrderId: this.schedulerModel.selectedWorkOrderId,
        resourceId: this.resourceId,
        date: this.date,
      });

      return;
    }

    if (this.routingModel.routeName !== 'district-team-scheduler') {
      return;
    }

    this.activationModel.activate(`popover-for-appointment-${this.id}`, {
      showOverlay: true,
    });
  };

  @action
  delete = () => {
    this.activationModel.deactivateAll();

    this.callForCancelAppointment({
      appointmentId: this.id,
    });

    this.deleted = true;
  };

  @action
  relocate = async ({ resourceId, date }) => {
    const startDateTime = pipeline(
      date,
      setHours(8),
      setMinutes(0),
      setSeconds(0),
    );

    this._resourceId = resourceId;
    this.startDateTime = startDateTime;

    await this.update();
  };

  @computed
  get isDraggable() {
    return this.userRights.doScheduling;
  }

  selectWorkOrder = () => {
    this.activationModel.deactivateAll();

    this.workOrder.selectForScheduling();
    this.schedulerModel.setSelectedHours(this.duration.value);
    this.schedulerModel.setSelectedStartTime(
      moment(this.dateTimeRange.start).format('HH:mm'),
    );
    this.schedulerModel.setSelectedEndTime(
      moment(this.dateTimeRange.end).format('HH:mm'),
    );
    this.schedulerModel.setSelectedNightShiftStatus(this.nightShift.value);
  };

  get _workOrderTypeValueObject() {
    return getWorkOrderTypeValueObject(this.workOrderType);
  }

  @computed
  get workOrderDoneStatusCanBeUpdated() {
    return (
      this._workOrderTypeValueObject.canBeDone &&
      !!this.schedulerModel.userRights.maintainWorkOrders
    );
  }

  @computed
  get workOrderUrgencyCanBeUpdated() {
    return (
      this._workOrderTypeValueObject.canBeUrgent &&
      !!this.schedulerModel.userRights.maintainWorkOrders
    );
  }
}
