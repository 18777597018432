import { find, get, pipeline } from './functionalProgramming';

import {
  doScheduling,
  scheduleWorkOrdersOfAllTypes,
} from './userRightConstants';
import workOrderCategories from './workOrderCategories';

import workOrderColors from './workOrderColors';

const defaultWorkOrderColor = pipeline(
  workOrderColors,
  find({ default: true }),
  get('value'),
);

export default {
  workOrder: {
    type: 'workOrder',
    relevantForResourceCoOperation: true,
    category: workOrderCategories.work.id,
    isObfuscatedWithoutUserRight: false,
    canBeScheduledWithUserRight: doScheduling,
    absenceScheduling: null,
    canBeDone: true,
    canBeUrgent: true,
    isTeamSpecific: true,
    isCountedInAbsenceBudget: false,
    canBeDeleted: true,
    typeCanBeChanged: true,
    appointmentsAreSimplified: false,
    colorIsUserSelectable: true,
    color: defaultWorkOrderColor,
    translations: {
      en: { name: 'Work order' },
      fi: { name: 'Työtilaus' },
      pl: { name: 'Zlecenie pracy' },
      se: { name: 'Arbetsorder' },
    },
  },

  teamActivity: {
    type: 'teamCommon',
    relevantForResourceCoOperation: true,
    category: workOrderCategories.sharedBetweenTeamMembers.id,
    canBeScheduledWithUserRight: doScheduling,
    isObfuscatedWithoutUserRight: false,
    absenceScheduling: null,
    canBeDone: true,
    canBeUrgent: true,
    isTeamSpecific: true,
    isCountedInAbsenceBudget: false,
    canBeDeleted: true,
    typeCanBeChanged: true,
    appointmentsAreSimplified: true,
    colorIsUserSelectable: true,
    color: '#424297',
    translations: {
      en: { name: 'Resource' },
      fi: { name: 'Resurssi' },
      pl: { name: 'Zasób' },
      se: { name: 'Resurs' },
    },
  },

  sharedActivity: {
    type: 'commonWork',
    relevantForResourceCoOperation: true,
    category: workOrderCategories.sharedBetweenEveryone.id,
    canBeScheduledWithUserRight: scheduleWorkOrdersOfAllTypes,
    isObfuscatedWithoutUserRight: false,
    absenceScheduling: null,
    canBeDone: false,
    canBeUrgent: false,
    isTeamSpecific: false,
    isCountedInAbsenceBudget: false,
    canBeDeleted: false,
    typeCanBeChanged: false,
    appointmentsAreSimplified: false,
    colorIsUserSelectable: true,
    color: '#d13e12',

    translations: {
      en: { name: 'Common work' },
      fi: {
        name: 'Yleinen työ (kaikille tiimeille yhteinen)',
      },
      pl: { name: 'Wspólna praca' },
      se: { name: 'Gemensamt arbete' },
    },
  },

  annualLeave: {
    type: 'annualLeave',
    relevantForResourceCoOperation: false,
    category: workOrderCategories.sharedBetweenEveryone.id,
    canBeScheduledWithUserRight: scheduleWorkOrdersOfAllTypes,
    isObfuscatedWithoutUserRight: true,

    absenceScheduling: {
      sortOrder: 0,
      countValueAs: 'balance',
      balancePathInIntegration: 'VacationDaysBalance',
      valueBackgroundColor: 'white',
    },
    canBeDone: false,
    canBeUrgent: false,
    isTeamSpecific: false,
    isCountedInAbsenceBudget: true,
    canBeDeleted: false,
    typeCanBeChanged: false,
    appointmentsAreSimplified: true,
    colorIsUserSelectable: false,
    color: '#d13e12',
    translations: {
      en: {
        shortBalanceHeader: 'Hol.',
        name: 'Holiday',
      },
      fi: {
        shortBalanceHeader: 'Loma',
        name: 'Loma',
      },
      pl: {
        shortBalanceHeader: 'Ann.',
        name: 'Annual leave',
      },
      se: {
        shortBalanceHeader: 'Sem.',
        name: 'Semester',
      },
    },
  },

  ATKLeave: {
    type: 'ATKLeave',
    relevantForResourceCoOperation: false,
    category: workOrderCategories.sharedBetweenEveryone.id,
    canBeScheduledWithUserRight: scheduleWorkOrdersOfAllTypes,
    isObfuscatedWithoutUserRight: true,
    absenceScheduling: {
      sortOrder: 1,
      countValueAs: 'balance',
      balancePathInIntegration: 'WorkTimeReductionDaysATK',
      valueBackgroundColor: 'white',
    },
    canBeDone: false,
    canBeUrgent: false,
    isTeamSpecific: false,
    isCountedInAbsenceBudget: true,
    canBeDeleted: false,
    typeCanBeChanged: false,
    appointmentsAreSimplified: true,
    colorIsUserSelectable: false,
    color: '#d13e12',
    translations: {
      en: {
        shortBalanceHeader: 'ATK',
        name: 'ATK',
      },
      fi: {
        shortBalanceHeader: 'ATK',
        name: 'ATK',
      },
      pl: {
        shortBalanceHeader: 'ATK',
        name: 'ATK',
      },
      se: {
        shortBalanceHeader: 'ATK',
        name: 'ATK',
      },
    },
  },

  COMPLeave: {
    type: 'COMPLeave',
    relevantForResourceCoOperation: false,
    category: workOrderCategories.sharedBetweenEveryone.id,
    canBeScheduledWithUserRight: scheduleWorkOrdersOfAllTypes,
    isObfuscatedWithoutUserRight: true,
    absenceScheduling: {
      sortOrder: 2,
      countValueAs: 'balance',
      balancePathInIntegration: 'CompensationDaysCOMP',
      valueBackgroundColor: 'white',
    },
    canBeDone: false,
    canBeUrgent: false,
    isTeamSpecific: false,
    isCountedInAbsenceBudget: true,
    canBeDeleted: false,
    typeCanBeChanged: false,
    appointmentsAreSimplified: true,
    colorIsUserSelectable: false,
    color: '#d13e12',
    translations: {
      en: {
        shortBalanceHeader: 'Komp',
        name: 'Komp',
      },
      fi: {
        shortBalanceHeader: 'Komp',
        name: 'Komp',
      },
      pl: {
        shortBalanceHeader: 'Komp',
        name: 'Komp',
      },
      se: {
        shortBalanceHeader: 'Komp',
        name: 'Komp',
      },
    },
  },

  VABLeave: {
    type: 'VABLeave',
    relevantForResourceCoOperation: false,
    category: workOrderCategories.sharedBetweenEveryone.id,
    canBeScheduledWithUserRight: scheduleWorkOrdersOfAllTypes,
    isObfuscatedWithoutUserRight: true,
    absenceScheduling: {
      sortOrder: 3,
      countValueAs: 'total-count',
      valueBackgroundColor: 'lightGrey',
    },
    canBeDone: false,
    canBeUrgent: false,
    isTeamSpecific: false,
    isCountedInAbsenceBudget: true,
    canBeDeleted: false,
    typeCanBeChanged: false,
    appointmentsAreSimplified: true,
    colorIsUserSelectable: false,
    color: '#d13e12',
    translations: {
      en: {
        shortBalanceHeader: 'Vab',
        name: 'Vab',
      },
      fi: {
        shortBalanceHeader: 'Vab',
        name: 'Vab',
      },
      pl: {
        shortBalanceHeader: 'Vab',
        name: 'Vab',
      },
      se: {
        shortBalanceHeader: 'Vab',
        name: 'Vab',
      },
    },
  },

  common: {
    type: 'common',
    relevantForResourceCoOperation: false,
    category: workOrderCategories.sharedBetweenEveryone.id,
    canBeScheduledWithUserRight: scheduleWorkOrdersOfAllTypes,
    isObfuscatedWithoutUserRight: true,
    absenceScheduling: {
      sortOrder: 4,
      countValueAs: 'total-count',
      valueBackgroundColor: 'lightGrey',
    },
    canBeDone: false,
    canBeUrgent: false,
    isTeamSpecific: false,
    isCountedInAbsenceBudget: true,
    canBeDeleted: false,
    typeCanBeChanged: false,
    appointmentsAreSimplified: true,
    colorIsUserSelectable: false,
    color: '#d13e12',
    translations: {
      en: {
        shortBalanceHeader: 'Abs.',
        name: 'Common leave',
      },
      fi: {
        shortBalanceHeader: 'Muut',
        name: 'Poissaolo (kaikille tiimeille yhteinen)',
      },
      pl: {
        shortBalanceHeader: 'Nieobec.',
        name: 'Urlop wspólny',
      },
      se: {
        shortBalanceHeader: 'Sjuk',
        name: 'Gemensam ledighet',
      },
    },
  },
};
