import filter from 'lodash/fp/filter';
import { action, computed } from 'mobx';
import pipeline from 'shared-between-everything/src/doings/pipeline/pipeline';
import {
  conforms,
  constant,
  flow,
  get,
  includes,
  map,
  orderBy,
  overSome,
  take,
} from 'shared-between-everything/src/functionalProgramming';
import getWorkOrderTypeValueObject from 'shared-between-everything/src/getWorkOrderTypeValueObject';
import workOrderCategories from 'shared-between-everything/src/workOrderCategories';
import TextInputModel from 'shared-between-front-ends/src/components/public/TextInput/TextInputModel';

export default class WorkOrderListModel {
  constructor(
    routingModel,
    sessionModel,
    workOrdersById,
    maximumAmountOfListedWorkOrders,
    appointmentsById,
    showScheduledWorkOrders,
  ) {
    this.routingModel = routingModel;
    this.workOrdersById = workOrdersById;
    this.sessionModel = sessionModel;
    this.maximumAmountOfListedWorkOrders = maximumAmountOfListedWorkOrders;
    this.appointmentsById = appointmentsById;
    this.showScheduledWorkOrders = showScheduledWorkOrders;
  }

  filterStringInput = new TextInputModel();

  @computed
  get selectedWorkOrderCategory() {
    return (
      this.routingModel.queryParameters.selectedWorkOrderCategory ||
      workOrderCategories.work.id
    );
  }

  @computed
  get listedWorkOrders() {
    return pipeline(
      [...this.workOrdersById.values()],
      filter(toWorkOrdersOfSelectedCategory(this.selectedWorkOrderCategory)),
      filter(toWorkOrdersThatAreNotDone),
      filter(
        toScheduledWorkOrders(
          this.appointmentsById.toJSON(),
          this.showScheduledWorkOrders.value,
        ),
      ),
      filter(toWorkOrdersUserHasRightToSchedule(this.sessionModel.userRights)),
      filter(
        toWorkOrdersThatMatchToSearch(
          this.filterStringInput.debouncedInternalValue,
        ),
      ),
      orderBy(
        [pathIsTruthy('urgent.internalValue'), 'erpId'],
        ['desc', 'desc'],
      ),
      take(this.maximumAmountOfListedWorkOrders),
      // tap(console.log),
    );
  }

  @action
  changeListedWorkOrders = type => {
    this.routingModel.mergeQueryParameters({
      selectedWorkOrderCategory: type,
    });
  };
}

const pathIsTruthy = path => flow(get(path), x => !!x);

// TODO: This needs to be replaced with better solution
// We could get rid of buttons in AppointmentMemo and save details on change
// which would allow us using "done"-value again.
const toWorkOrdersThatAreNotDone = ({ backgroundColor }) =>
  backgroundColor !== 'doneBackground';

const toScheduledWorkOrders = (appointments, isAllWorkOrdersShown) => ({
  id: workOrderId,
  type,
}) => {
  const isScheduledWorkOrder = () => {
    const appointmentWorkOrderIds = getAppointmentWorkOrderIds(appointments);

    return !(
      isOfTypeWorkOrder(type) && includes(workOrderId, appointmentWorkOrderIds)
    );
  };

  return isAllWorkOrdersShown ? true : isScheduledWorkOrder(appointments);
};

const isOfTypeWorkOrder = type => type === 'workOrder';

const toWorkOrdersUserHasRightToSchedule = userRights => ({
  type: workOrderType,
}) => {
  if (userRights.scheduleWorkOrdersOfAllTypes) {
    return true;
  }

  const {
    canBeScheduledWithUserRight: requiredUserRight,
  } = getWorkOrderTypeValueObject(workOrderType);

  return userRights[requiredUserRight];
};

const toWorkOrdersOfSelectedCategory = selectedCategory => ({
  type: workOrderType,
}) => getWorkOrderTypeValueObject(workOrderType).category === selectedCategory;

const toWorkOrdersThatMatchToSearch = searchQuery => {
  const matchesSearchQuery = includesCaseInvariantFor(searchQuery);

  return overSome([
    constant(isFalsy(searchQuery)),
    conforms({ name: matchesSearchQuery }),
    conforms({ erpId: matchesSearchQuery }),
    conforms({ address: matchesSearchQuery }),
  ]);
};

const isFalsy = value => !value;

const getAppointmentWorkOrderIds = map(appointment =>
  get('workOrderId', appointment),
);

const includesCaseInvariantFor = searchQuery => value => {
  const regex = new RegExp(searchQuery, 'i');

  return regex.test(value);
};
