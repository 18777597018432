import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

const getResources = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/resources',
    method: 'GET',
  },
  decoratorConfig: {
    spinnerName: 'loading-resources',
  },
});

export default getResources;
