import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import AdditionalButton from 'shared-between-front-ends/src/components/public/AdditionalButton/AdditionalButton';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Link from 'shared-between-front-ends/src/components/public/Link/Link';
import WeekInput from 'shared-between-front-ends/src/components/public/WeekInput/WeekInput';
import withModel from 'shared-between-front-ends/src/decorators/withModel/withModel';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';

import schedulerTranslations from '../../../schedulerTranslations';
import SchedulerModel from '../../SchedulerModel/SchedulerModel';

const translate = localTranslate(schedulerTranslations);

const SchedulerDateRangeActions = ({
  goBackToCurrentWeek,
  goToNextWeek,
  goToPreviousWeek,
  goToDate,
}) => {
  return (
    <Flex spaceBetween centeredVertically>
      <AdditionalButton
        onClick={goBackToCurrentWeek}
        size="sm"
        data-current-week-test
        data-go-to-current-week-e2e-test
      >
        {translate('today')}
      </AdditionalButton>

      <Gutter size="sm" />

      <WeekInput model={goToDate} data-go-to-date-test />

      <Gutter size="sm" />

      <Link
        onClick={goToPreviousWeek}
        defaultColors
        data-previous-week-test
        data-go-to-previous-week-e2e-test
      >
        <Icon block name="chevron-left" size="md" />
      </Link>

      <Gutter size="xs" />

      <Link
        onClick={goToNextWeek}
        defaultColors
        data-next-week-test
        data-go-to-next-week-e2e-test
      >
        <Icon block name="chevron-right" size="md" />
      </Link>

      <Div flexItem />
    </Flex>
  );
};

export default decorate(
  withModel({
    Model: SchedulerModel,
    props: [
      {
        name: 'goBackToCurrentWeek',
        modelPath: 'goBackToCurrentWeek',
      },
      {
        name: 'goToPreviousWeek',
        modelPath: 'goToPreviousWeek',
      },
      { name: 'goToNextWeek', modelPath: 'goToNextWeek' },
      { name: 'goToDate', modelPath: 'goToDate' },
    ],
  }),
)(SchedulerDateRangeActions);
