import { computed } from 'mobx';
import { matches } from 'shared-between-everything/src/functionalProgramming';
import getWorkOrderTypeValueObject from 'shared-between-everything/src/getWorkOrderTypeValueObject';
import workOrderColors from 'shared-between-everything/src/workOrderColors';
import ColorInputModel from 'shared-between-front-ends/src/components/public/ColorInput/ColorInputModel';
import NotificationsModel from 'shared-between-front-ends/src/components/public/Notifications/NotificationsModel';
import NumberInputModel from 'shared-between-front-ends/src/components/public/NumberInput/NumberInputModel';
import RadioButtonGroupModel from 'shared-between-front-ends/src/components/public/RadioButtonGroup/RadioButtonGroupModel';
import PostalCodeInputModel from 'shared-between-front-ends/src/components/public/TextInput/PostalCodeInputModel';
import TextInputModel from 'shared-between-front-ends/src/components/public/TextInput/TextInputModel';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import SessionModel from 'shared-between-front-ends/src/models/SessionModel/SessionModel';
import isPositiveNumber from 'shared-between-front-ends/src/validators/isPositiveNumber/isPositiveNumber';
import isUrl from 'shared-between-front-ends/src/validators/isUrl/isUrl';
import callForCreateWorkOrderImport from '../callForCreateWorkOrder';
import getWorkOrderTypeValues from '../getWorkOrderTypeValues/getWorkOrderTypeValues';
import workOrderTranslations from '../workOrderTranslations';
import workOrderTypes from 'shared-between-everything/src/workOrderTypes';

const translate = localTranslate(workOrderTranslations);

const defaultWorkOrderColor = workOrderColors.find(matches({ default: true }))
  .value;

export default class CreateWorkOrderModel {
  static isSingleton = false;

  dependencies = {};

  constructor(
    getEveryInputModelIsValid = require('shared-between-front-ends/src/doings/getEveryInputModelIsValid/getEveryInputModelIsValid')
      .default,
    routingModel = getModel(RoutingModel),
    callForCreateWorkOrder = callForCreateWorkOrderImport,
    notificationsModel = getModel(NotificationsModel),
    sessionModel = getModel(SessionModel),
  ) {
    this.dependencies.getEveryInputModelIsValid = getEveryInputModelIsValid;
    this.dependencies.routingModel = routingModel;
    this.dependencies.callForCreateWorkOrder = callForCreateWorkOrder;
    this.dependencies.notificationsModel = notificationsModel;
    this.dependencies.sessionModel = sessionModel;

    this.type = new RadioButtonGroupModel({
      readOnly: false,
      required: false,
      defaultValue: workOrderTypes.workOrder.type,
      observeValues: () =>
        getWorkOrderTypeValues(
          sessionModel.userRights.createWorkOrdersOfAllTypes,
        ),
    });
  }

  customerName = new TextInputModel({ required: true });
  name = new TextInputModel({ required: true });
  link = new TextInputModel({
    required: false,
    validators: [isUrl],
  });
  description = new TextInputModel({ required: false });
  address = new TextInputModel({ required: false });

  postalCode = new PostalCodeInputModel({
    required: false,
  });

  city = new TextInputModel({ required: false });
  deadlineDate = new TextInputModel({ required: false });

  billableHours = new NumberInputModel({
    required: false,
    validators: [isPositiveNumber],
    defaultValue: 0,
  });

  color = new ColorInputModel({
    required: false,
    initialInternalValue: defaultWorkOrderColor,
  });

  submit = async () => {
    const workOrderType = getWorkOrderTypeValueObject(this.type.value);

    await this.dependencies.callForCreateWorkOrder({
      customerName: this.customerName.value,
      name: this.name.value,
      link: this.link.value,
      description: this.description.value,
      address: this.address.value,
      postalCode: this.postalCode.value,
      city: this.city.value,
      deadlineDate: this.deadlineDate.value,
      billableHours: this.billableHours.value,
      type: this.type.value,
      teamId: workOrderType.isTeamSpecific
        ? this.dependencies.routingModel.pathParameters.teamId
        : 'null',
      color: this.color.value,
    });

    this.dependencies.notificationsModel.setSuccess(translate('createSuccess'));

    this.dependencies.routingModel.goBack();
  };

  @computed
  get isValid() {
    return this.dependencies.getEveryInputModelIsValid(
      this.customerName,
      this.name,
      this.link,
      this.description,
      this.address,
      this.postalCode,
      this.city,
      this.deadlineDate,
      this.billableHours,
      this.color,
    );
  }

  @computed
  get colorInputIsVisible() {
    return getWorkOrderTypeValueObject(this.type.internalValue)
      .colorIsUserSelectable;
  }
}
