import { observer } from 'mobx-react';
import React from 'react';
import { Router } from 'react-router-dom';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import ConfirmationDialog from './ConfirmationDialog/ConfirmationDialog';
import Div from '../components/private/Element/Div/Div';
import ErrorTentative from './ErrorTentative/ErrorTentative';
import Notifications from '../components/public/Notifications/Notifications';
import withReactErrorBoundary from '../decorators/withReactErrorBoundary/withReactErrorBoundary';
import Overlay from './Overlay/Overlay';
import moment from 'moment-timezone';

moment.tz.setDefault('Etc/UTC');

const App = ({
  history,
  errorStateModel,
  routePageModel,
  notificationsModel,
}) => {
  return (
    <Router history={history}>
      <Div backgroundColor="lightGrey">
        <Overlay />

        <ConfirmationDialog />

        <Notifications model={notificationsModel} />

        <ErrorTentative errorStateModel={errorStateModel}>
          {routePageModel.page}
        </ErrorTentative>
      </Div>
    </Router>
  );
};

export default decorate(withReactErrorBoundary, observer)(App);
