import parameterInputMapperFor from 'shared-between-everything/src/doings/parameterInputMapperFor/parameterInputMapperFor';
import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

const callForModifyingLunchStatusOfAppointment = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/appointment/{appointmentId}/modify-lunch',
    method: 'POST',
    inputMapper: parameterInputMapperFor({
      pathParameters: {
        appointmentId: 'appointmentId',
      },

      bodyParameters: {
        lunch: 'lunch',
      },
    }),
  },
  decoratorConfig: {
    spinnerName: 'modifying-lunch-status-of-appointment',
    showSpinnerInstantly: false,
  },
});

export default callForModifyingLunchStatusOfAppointment;
