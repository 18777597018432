import { action, computed, observable, runInAction, untracked } from 'mobx';
import {
  filter,
  find,
  getOr,
  isEmpty,
  isNull,
  join,
  get,
  map,
  not,
  pipeline,
  every,
  isOneOf,
  some,
  orderBy,
  sortBy,
  forEach,
  sum,
} from 'shared-between-everything/src/functionalProgramming';

import BooleanInputModel from 'shared-between-front-ends/src/components/public/CheckboxInput/BooleanInputModel';
import ParticipationModel from '../EffortContent/Participations/Participation/ParticipationModel/ParticipationModel';

export default class InteractiveEffortModel {
  dependencies = {};

  constructor({
    expanded,
    reportDate,
    teamResources,
    effort,
    currentResource,
    browserStorage,
    notificationsModel,
    activationModel,
  }) {
    this.dependencies.browserStorage = browserStorage;
    this.dependencies.notificationsModel = notificationsModel;
    this.dependencies.activationModel = activationModel;

    this._effort = effort;
    this._reportDate = reportDate;
    this._teamResources = teamResources;
    this._currentResource = currentResource;

    this.expectedEffortEntryIsExpanded = untracked(
      () =>
        new BooleanInputModel({
          initialInternalValue: expanded,
        }),
    );

    this.isParticipationNewDefault = untracked(
      () =>
        new BooleanInputModel({
          initialInternalValue: false,
        }),
    );

    this.expectedEffortEntryDescriptionIsExpanded = untracked(
      () =>
        new BooleanInputModel({
          initialInternalValue: false,
        }),
    );

    this.participantInputs = getParticipantInputs(teamResources);

    runInAction(() => {
      this._addExistingParticipations();

      const defaultParticipationNeedsToBeAdded = !this._participationMap.has(
        this._defaultParticipationId,
      );

      if (defaultParticipationNeedsToBeAdded) {
        this._addDefaultParticipation();
      }
    });
  }

  _addExistingParticipations = () => {
    pipeline(
      this._effort.actualEffortEntries,
      find({ date: this._reportDate }),
      getOr([], 'participations'),
      map(participation => {
        const participationId = this._getParticipationIdForResources(
          participation.participants,
        );

        return participationId === this._defaultParticipationId
          ? this._toExpandedParticipationModel(participation)
          : this._toCollapsedParticipationModel(participation);
      }),

      forEach(participationModel => {
        this._participationMap.set(
          participationModel.participationId,
          participationModel,
        );
      }),
    );
  };

  _addDefaultParticipation = () => {
    const defaultParticipation = {
      comment: null,

      effort: {
        amount: null,
        measurementUnitId: this.effortDefinitionCatalogItem.measurementUnitId,
      },

      participants: this._defaultParticipationResources,
    };

    this._participationMap.set(
      this._defaultParticipationId,
      this._toExpandedParticipationModel(defaultParticipation),
    );
  };

  get _defaultParticipationId() {
    return this._getParticipationIdForResources(
      this._defaultParticipationResources,
    );
  }

  @observable _participationMap = new Map();

  @computed
  get participations() {
    return pipeline(
      [...this._participationMap.values()],
      orderBy(['justCreated'], ['desc']),
    );
  }

  _toParticipationModelFor = ({
    initiallyExpanded,
    justCreated,
  }) => participation =>
    untracked(
      () =>
        new ParticipationModel({
          participation,
          expectedEffortDefinition: this.expectedEffortDefinition,
          teamResources: this._teamResources,
          effortDefinitionCatalogItem: this.effortDefinitionCatalogItem,
          interactiveEffortModel: this,
          initiallyExpanded,
          justCreated,
        }),
    );

  _toNewlyCreatedParticipationModel = this._toParticipationModelFor({
    initiallyExpanded: true,
    justCreated: true,
  });

  _toExpandedParticipationModel = this._toParticipationModelFor({
    initiallyExpanded: true,
    justCreated: false,
  });

  _toCollapsedParticipationModel = this._toParticipationModelFor({
    initiallyExpanded: false,
    justCreated: false,
  });

  get _defaultParticipationResources() {
    const defaultParticipationIds = this.dependencies.browserStorage.get(
      'work-order-effort-report-participant-defaults',
    );

    if (!defaultParticipationIds) {
      return [{ resourceId: this._currentResource.id }];
    }

    const teamResourceIds = this._teamResources.map(get('resource.id'));

    return pipeline(
      defaultParticipationIds,
      map('resourceId'),
      every(isOneOf(teamResourceIds)),
      participationBelongsToTeam =>
        participationBelongsToTeam
          ? defaultParticipationIds
          : [{ resourceId: this._currentResource.id }],
    );
  }

  get activationModel() {
    return this.dependencies.activationModel;
  }

  get descriptionIsShown() {
    return !!this._effort.effortDefinitionCatalogItem.description;
  }

  get includingIsShown() {
    return !!this._effort.effortDefinitionCatalogItem.including;
  }

  get excludingIsShown() {
    return !!this._effort.effortDefinitionCatalogItem.excluding;
  }

  get effortDefinitionCatalog() {
    return this._effort.effortDefinitionCatalog;
  }

  get effortAmount() {
    return pipeline(this.participations, map('effortAmount'), sum);
  }

  get roundedTotalDuration() {
    const totalDuration =
      this.effortAmount * this.effortDefinitionCatalogItem.duration.amount;

    return {
      amount: Math.round(totalDuration * 100) / 100,
      measurementUnitId: 'hour',
    };
  }

  get effortDefinitionCatalogItem() {
    return this._effort.effortDefinitionCatalogItem;
  }

  get expectedEffortDefinition() {
    return this._effort.expectedEffortDefinition;
  }

  get roundedDurationPerUnit() {
    return this._effort.roundedDurationPerUnit;
  }

  @computed
  get effortEntryHighlightColor() {
    return pipeline(
      this.participations,
      map('actualEffortInput.internalValue'),
      some(not(isNull)),
      effortIsInputted => (effortIsInputted ? 'primary' : 'grey100'),
    );
  }

  showAddParticipationPopover = () => {
    this.dependencies.activationModel.activate(
      `add-participation-popover-for-${this.effortDefinitionCatalogItem.id}`,
      {
        showOverlay: true,
      },
    );
  };

  @action
  submitAddingParticipation = () => {
    if (!this._participationMap.has(this._participationIdToBeAdded)) {
      this._participationMap.set(
        this._participationIdToBeAdded,

        this._toNewlyCreatedParticipationModel({
          effort: {
            amount: null,
            measurementUnitId: this.effortDefinitionCatalogItem.effort
              .measurementUnitId,
          },

          comment: null,

          participants: this._participants.map(({ resource }) => ({
            resourceId: resource.id,
          })),
        }),
      );
    }

    const participation = this._participationMap.get(
      this._participationIdToBeAdded,
    );

    participation.participationIsExpanded.setValueToTrue();

    if (this.isParticipationNewDefault.internalValue) {
      this.dependencies.browserStorage.set(
        'work-order-effort-report-participant-defaults',

        participation.participatingResourceIds.map(resourceId => ({
          resourceId,
        })),
      );
    }

    this._closeAddParticipationPopover();
  };

  _closeAddParticipationPopover = () => {
    this.dependencies.activationModel.deactivate(
      `add-participation-popover-for-${this.effortDefinitionCatalogItem.id}`,
    );
  };

  cancelSubmittingParticipation = () => {
    this._closeAddParticipationPopover();
  };

  _getParticipationIdForResources = resources =>
    pipeline(resources, map('resourceId'), join('-'));

  @computed
  get _participationIdToBeAdded() {
    return this._getParticipationIdForResources(
      this._participants.map(({ resource }) => ({
        resourceId: resource.id,
      })),
    );
  }

  @computed
  get participationCanBeAdded() {
    return pipeline(this._participants, not(isEmpty));
  }

  @computed
  get _participants() {
    return pipeline(
      this.participantInputs,
      filter({ isParticipating: { internalValue: true } }),
    );
  }
}

const getParticipantInputs = teamResources =>
  pipeline(
    teamResources,
    sortBy('resource.firstName'),

    map(teamResource => ({
      isParticipating: untracked(
        () =>
          new BooleanInputModel({
            initialInternalValue: false,
          }),
      ),

      ...teamResource,
    })),
  );
