import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import { PropTypes } from 'prop-types';
import React from 'react';
import wrapEachComponentWithKey from '../../../doings/wrapEachComponentWithKey/wrapEachComponentWithKey';
import Div from '../../private/Element/Div/Div';
import Element from '../../private/Element/Element';
import BodyText from '../BodyText/BodyText';
import Heading from '../Heading/Heading';
import styles from './Table.module.scss';
import TableCell from './TableCell/TableCell';
import TableHeadingCell from './TableHeadingCell/TableHeadingCell';
import TableRow from './TableRow/TableRow';

const mapThatShowsRunningIndex = map.convert({
  cap: false,
});

const renderCols = flow(
  map(({ width, className }) => <col width={width} className={className} />),
  wrapEachComponentWithKey,
);

function StickyTable({
  heading,
  cols = [],
  headers = [],
  footers = [],
  rows,
  striped,
  className,
  children: childrenCallback,
  ...props
}) {
  return (
    <BodyText
      tagName="div"
      data-indicators-e2e-test={rows.length > 0 ? 'non-empty' : 'empty'}
      className={[
        styles.table,
        { [styles.table__striped]: striped },
        className,
      ]}
      {...props}
    >
      {heading && (
        <Heading size="xs" block useDefaultBottomMargin>
          {heading}
        </Heading>
      )}
      <Div className={styles.table_stickyHeader}>
        <Element
          tagName="table"
          className={styles.table_table}
          occupyVerticalSpace
        >
          <colgroup>{renderCols(cols)}</colgroup>

          <thead>{wrapEachComponentWithKey(headers)}</thead>
        </Element>
      </Div>

      <Element
        tagName="table"
        className={styles.table_table}
        occupyVerticalSpace
      >
        <colgroup>{renderCols(cols)}</colgroup>

        <tbody>
          {mapThatShowsRunningIndex((row, runningIndex) =>
            childrenCallback(row, {
              numberOfThisRow: runningIndex,
              totalNumberOfRows: rows.length,
            }),
          )(rows)}
        </tbody>
      </Element>

      <Div className={styles.table_stickyFooter}>
        <Element
          tagName="table"
          className={styles.table_table}
          occupyVerticalSpace
        >
          <colgroup>{renderCols(cols)}</colgroup>

          <tfoot>{wrapEachComponentWithKey(footers)}</tfoot>
        </Element>
      </Div>
    </BodyText>
  );
}

StickyTable.Cell = TableCell;
StickyTable.HeadingCell = TableHeadingCell;
StickyTable.Row = TableRow;

StickyTable.propTypes = {
  cols: PropTypes.arrayOf(
    PropTypes.shape({
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      className: PropTypes.any,
      content: PropTypes.element,
    }),
  ),
  children: PropTypes.func,
};

export default StickyTable;
