import bodyParametersInputMapper from 'shared-between-everything/src/doings/bodyParametersInputMapper/bodyParametersInputMapper';
import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

const callForCreateEffortDefinitionCatalog = getAuthenticatedCall({
  apiCallConfig: {
    path:
      '/api/effort-definition-catalog-maintenance/create-effort-definition-catalog',
    method: 'POST',
    inputMapper: bodyParametersInputMapper,
  },
  decoratorConfig: {},
});

export default callForCreateEffortDefinitionCatalog;
