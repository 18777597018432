import React from 'react';

import Table from 'shared-between-front-ends/src/components/public/Table/Table';

const SchedulerTableHeadingCell = ({
  backgroundColor = 'white',
  children,
  ...props
}) => {
  return (
    <Table.HeadingCell
      padding={{ size: 'xxs' }}
      backgroundColor={backgroundColor}
      {...props}
    >
      {children}
    </Table.HeadingCell>
  );
};

export default SchedulerTableHeadingCell;
