import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';
import pathParametersInputMapper from 'shared-between-front-ends/src/doings/pathParametersInputMapper/pathParametersInputMapper';

const callForDeleteTeam = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/delete-team/{teamId}',
    method: 'POST',
    inputMapper: pathParametersInputMapper,
  },
  decoratorConfig: {
    spinnerName: 'deleting-team',
    showSpinnerInstantly: true,
  },
});

export default callForDeleteTeam;
