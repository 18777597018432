import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';
import pathParametersInputMapper from 'shared-between-front-ends/src/doings/pathParametersInputMapper/pathParametersInputMapper';

const getTeam = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/modify-team/{teamId}',
    method: 'GET',
    inputMapper: pathParametersInputMapper,
  },
  decoratorConfig: {
    spinnerName: 'getting-team',
    showSpinnerInstantly: true,
  },
});

export default getTeam;
