import getClassNames from 'classnames';
import camelCase from 'lodash/fp/camelCase';
import contains from 'lodash/fp/contains';
import invoke from 'lodash/fp/invoke';
import isEmpty from 'lodash/fp/isEmpty';
import noop from 'lodash/fp/noop';
import upperFirst from 'lodash/fp/upperFirst';
import PropTypes from 'prop-types';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import {
  isOneOf,
  pipeline,
  startsWith,
} from 'shared-between-everything/src/functionalProgramming';

import availableBreakpoints from '../../../styles/breakpoints';
import availableColors from '../../../styles/colors.json';
import flexStyles from '../../public/Flex/Flex.module.scss';
import colors from './Colors.module.scss';
import styles from './Element.module.scss';
import getBorderRadiusClasses from './getBorderRadiusClasses';
import getMarginClasses from './getMarginClasses';
import getPaddingClasses from './getPaddingClasses';
import hiddenStyles from './Hidden.module.scss';

const Element = ({
  className,
  tagName,
  tag: Tag = tagName,
  children,
  margin,
  padding,
  bold,
  color,
  hoverColor,
  backgroundColor,
  backgroundHoverColor,
  inlineBlock,
  block,
  sticky,
  shown,
  hidden = shown === undefined ? undefined : !shown,
  visuallyHidden,
  enabled,
  disabled = enabled === undefined ? undefined : !enabled,
  rendered,
  notRendered = rendered === undefined ? undefined : !rendered,
  occupyContentSpaceButHide,
  flexItem,
  boxShadow,
  responsiveHidden,
  occupyVerticalSpace,
  occupyHorizontalSpace,
  forceWrap,
  style: explicitInlineStyles = {},
  hideOverflow,
  showOverflow,
  scrollOverflow,
  alignTextTo,
  border,
  href,
  onClick,
  stopEventPropagation = !!onClick,
  clickableAppearance = !disabled && (!!href || !!onClick),
  nonClickableAppearance = disabled,
  relative,
  absolutePosition,
  highlight,
  hoverParent,
  overflowWhenHoverParentIsHovered,
  multiLineWhenHoverParentIsHovered,
  hiddenUnlessHoverParentIsHovered,
  shownUnlessHoverParentIsHovered,
  bringForward,
  pushBehind,
  forwardedRef,
  width,
  minWidth,
  maxWidth,
  height,
  minHeight,
  maxHeight,
  draggableFor,
  draggableValue,
  droppableFor,
  onDragOver2 = noop,
  onDragEnter2 = noop,
  onDragLeave2 = noop,
  onDrop2,
  striped,
  onEnterKey,
  ...props
}) => {
  if (notRendered) {
    return null;
  }

  const classNameParameters = [styles.element];

  if (margin === false || margin === null) margin = { size: 'zero' };
  if (margin) classNameParameters.push(getMarginClasses(margin));
  if (padding) classNameParameters.push(getPaddingClasses(padding));

  if (border) {
    classNameParameters.push(styles.element__border);

    if (border.size) {
      classNameParameters.push(
        styles[`element__borderSize${upperFirst(border.size)}`],
      );
    }

    if (border.color) {
      classNameParameters.push(
        styles[`element__borderColor${upperFirst(border.color)}`],
      );
    }

    if (border.radius) {
      classNameParameters.push(getBorderRadiusClasses(border.radius));
    }
  }

  if (bold) classNameParameters.push(styles.element__bold);

  if (striped) classNameParameters.push(styles.element__striped);

  if (color && !disabled) classNameParameters.push(colors[`color__${color}`]);

  if (disabled) classNameParameters.push(colors['color__disabledText']);

  if (hoverColor && !disabled)
    classNameParameters.push(colors[`color__${hoverColor}Hoverable`]);

  if (block) classNameParameters.push(styles.element__block);

  if (sticky === 'top') {
    classNameParameters.push(styles.element__stickyTop);
  }

  if (sticky === 'bottom') {
    classNameParameters.push(styles.element__stickyBottom);
  }

  if (inlineBlock) classNameParameters.push(styles.element__inlineBlock);

  const backgroundColorInlineStyles = {};
  if (backgroundColor) {
    const colorIsRgb = pipeline(backgroundColor, startsWith('#'));

    if (colorIsRgb) {
      backgroundColorInlineStyles.backgroundColor = backgroundColor;
    } else {
      classNameParameters.push(colors[`color_background__${backgroundColor}`]);
    }
  }

  if (backgroundHoverColor)
    classNameParameters.push(
      colors[`color_background__${backgroundHoverColor}Hoverable`],
    );

  if (className) classNameParameters.push(className);

  if (hidden !== undefined)
    classNameParameters.push({
      [styles.element__hidden]: hidden,
      'hidden-e2e-test': hidden,
      'shown-e2e-test': !hidden,
    });

  if (visuallyHidden) {
    classNameParameters.push(hiddenStyles.visuallyHidden);
  }

  if (occupyContentSpaceButHide)
    classNameParameters.push(styles.element__occupyContentSpaceButHide);

  if (flexItem) classNameParameters.push(flexStyles.flex_flexItem);

  if (boxShadow) {
    const boxShadowStyle =
      boxShadow.size === 'lg'
        ? styles.element__boxShadowSizeLg
        : styles.element__boxShadow;

    classNameParameters.push(boxShadowStyle);
  }

  if (alignTextTo)
    classNameParameters.push(
      styles[`element__alignTextTo${upperFirst(alignTextTo)}`],
    );

  if (responsiveHidden) {
    if (responsiveHidden.thinnerThan)
      classNameParameters.push(
        hiddenStyles[
          `hidden__thinnerThan${upperFirst(responsiveHidden.thinnerThan)}`
        ],
      );

    if (responsiveHidden.widerThan)
      classNameParameters.push(
        hiddenStyles[
          `hidden__widerThan${upperFirst(responsiveHidden.widerThan)}`
        ],
      );

    if (responsiveHidden.at)
      classNameParameters.push(
        hiddenStyles[`hidden__at${upperFirst(responsiveHidden.at)}`],
      );
  }

  if (occupyVerticalSpace)
    classNameParameters.push(styles.element__occupyVerticalSpace);

  if (occupyHorizontalSpace)
    classNameParameters.push(styles.element__occupyHorizontalSpace);

  if (forceWrap) classNameParameters.push(styles.element__forceWrap);
  if (hideOverflow) classNameParameters.push(styles.element__hideOverflow);
  if (showOverflow) classNameParameters.push(styles.element__showOverflow);
  if (scrollOverflow) classNameParameters.push(styles.element__scrollOverflow);

  if (clickableAppearance)
    classNameParameters.push(styles.element__clickableAppearance);

  if (nonClickableAppearance) {
    classNameParameters.push(styles.element__nonClickableAppearance);
  }

  if (relative) {
    classNameParameters.push(styles.element__relative);
  }

  if (absolutePosition) {
    classNameParameters.push({
      [styles[
        `element__absolutePosition${upperFirst(camelCase(absolutePosition))}`
      ]]: true,
      [styles.element__absolute]: true,
    });
  }

  if (highlight) classNameParameters.push(styles.element__highlight);

  if (hoverParent) classNameParameters.push(styles.hoverParent);

  if (overflowWhenHoverParentIsHovered)
    classNameParameters.push(styles.hoverParent_hoverChild__showOverflow);

  if (hiddenUnlessHoverParentIsHovered)
    classNameParameters.push(styles.hoverParent_hoverChild__hidden);

  if (shownUnlessHoverParentIsHovered)
    classNameParameters.push(styles.hoverParent_hoverChild__shown);

  if (multiLineWhenHoverParentIsHovered)
    classNameParameters.push(styles.hoverParent_typography__multiline);

  if (bringForward) {
    classNameParameters.push(styles.element__bringForward);
  }

  if (pushBehind) {
    classNameParameters.push(styles.element__pushBehind);
  }

  if (flexItem && flexItem.alignSelfTo) {
    classNameParameters.push(
      styles[`element__alignSelfTo${upperFirst(flexItem.alignSelfTo)}`],
    );
  }

  const flexItemInlineStyles = {};
  if (flexItem && flexItem.width) {
    flexItemInlineStyles.flexBasis = flexItem.width;
  }

  if (width) {
    classNameParameters.push(styles[`element__width${upperFirst(width)}`]);
  }

  if (minWidth) {
    classNameParameters.push(
      styles[`element__minWidth${upperFirst(minWidth)}`],
    );
  }

  if (maxWidth) {
    classNameParameters.push(
      styles[`element__maxWidth${upperFirst(maxWidth)}`],
    );
  }

  if (height) {
    classNameParameters.push(styles[`element__height${upperFirst(height)}`]);
  }

  if (minHeight) {
    classNameParameters.push(
      styles[`element__minHeight${upperFirst(minHeight)}`],
    );
  }

  if (maxHeight) {
    classNameParameters.push(
      styles[`element__maxHeight${upperFirst(maxHeight)}`],
    );
  }

  const allInlineStyles = {
    ...backgroundColorInlineStyles,
    ...flexItemInlineStyles,
    ...explicitInlineStyles,
  };

  const inlineStylesIfPresent = isEmpty(allInlineStyles)
    ? {}
    : { style: allInlineStyles };

  const tentativeOnClick = getTentativeOnClick(
    onClick,
    stopEventPropagation,
    disabled,
  );

  const tentativeOnEnter = getTentativeOnKeyDown(onEnterKey);

  const vanillaElementDisabilityForButton =
    tagName === 'button' && disabled ? { disabled: true } : {};

  const draggabilityProps = draggableFor
    ? {
        draggable: true,

        onDragOver: event => void event.preventDefault(),

        onDragEnter: event => void event.preventDefault(),

        onDragStart: event => {
          event.dataTransfer.setData(
            draggableFor,
            JSON.stringify(draggableValue),
          );
        },
      }
    : {};

  const droppabilityProps = droppableFor
    ? {
        onDragOver: event => {
          event.preventDefault();

          const droppingPurposeIsRelevant = isOneOf(event.dataTransfer.types)(
            droppableFor,
          );

          if (droppingPurposeIsRelevant) {
            const dragOrientation = getDragOrientation(event);

            onDragOver2({ dragOrientation });
          }
        },

        onDragEnter: event => {
          event.preventDefault();

          const droppingPurposeIsRelevant = isOneOf(event.dataTransfer.types)(
            droppableFor,
          );

          if (droppingPurposeIsRelevant) {
            onDragEnter2();
          }
        },

        onDragLeave: event => {
          const droppingPurposeIsRelevant = isOneOf(event.dataTransfer.types)(
            droppableFor,
          );

          if (droppingPurposeIsRelevant) {
            onDragLeave2();
          }
        },

        onDrop: event => {
          event.preventDefault();

          const draggableValueString = event.dataTransfer.getData(droppableFor);

          if (draggableValueString) {
            const draggableValue = JSON.parse(draggableValueString);
            const dragOrientation = getDragOrientation(event);

            onDrop2(draggableValue, { dragOrientation });
          }
        },
      }
    : {};

  if (draggableFor) {
    classNameParameters.push(styles.element__draggable);
  }

  const classNames = getClassNames(...classNameParameters);

  if (contains('undefined', classNames)) {
    throw new Error(
      `Undefined class name encountered in Element "${tagName}": "${classNames}"`,
    );
  }

  return (
    <Tag
      className={classNames}
      href={href}
      {...inlineStylesIfPresent}
      {...tentativeOnClick}
      {...tentativeOnEnter}
      {...vanillaElementDisabilityForButton}
      {...draggabilityProps}
      {...droppabilityProps}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </Tag>
  );
};

const withStoppedEventPropagation = toBeDecorated => (event, ...args) => {
  invoke('stopPropagation', event);

  return toBeDecorated(event, ...args);
};

const withPreventedDefaultBehavior = toBeDecorated => (event, ...args) => {
  invoke('preventDefault', event);

  return toBeDecorated(event, ...args);
};

const supportedColors = Object.keys(availableColors);

const dimensions = ['3xs', 'xxs', 'xs', 'sm', 'md', 'lg', 'xlg'];

Element.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  tagName: PropTypes.string,
  tag: PropTypes.func,
  color: PropTypes.oneOf(supportedColors),
  hoverColor: PropTypes.oneOf(supportedColors),
  backgroundHoverColor: PropTypes.oneOf(supportedColors),
  flexItem: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  responsiveHidden: PropTypes.shape({
    thinnerThan: PropTypes.oneOf(availableBreakpoints),
    widerThan: PropTypes.oneOf(availableBreakpoints),
    at: PropTypes.oneOf(availableBreakpoints),
  }),
  alignTextTo: PropTypes.oneOf(['left', 'right', 'center']),
  width: PropTypes.oneOf(dimensions),
  minWidth: PropTypes.oneOf(dimensions),
  maxWidth: PropTypes.oneOf(dimensions),
  height: PropTypes.oneOf([
    ...dimensions,
    ...dimensions.map(dimension => `${dimension}OrViewport`),
  ]),
  minHeight: PropTypes.oneOf(dimensions),
  maxHeight: PropTypes.oneOf([
    ...dimensions,
    ...dimensions.map(dimension => `${dimension}OrViewport`),
  ]),
};

export default Element;

const getTentativeOnClick = (onClick, stopEventPropagation, disabled) => {
  const onClickWithPreventedDefault = withPreventedDefaultBehavior(onClick);

  if (disabled) {
    return {
      onClick: withStoppedEventPropagation(noop),
    };
  }

  if (!onClick && !stopEventPropagation) {
    return {};
  }

  if (!onClick && stopEventPropagation) {
    return {
      onClick: withStoppedEventPropagation(noop),
    };
  }

  if (onClick && stopEventPropagation) {
    return {
      onClick: withStoppedEventPropagation(onClickWithPreventedDefault),
    };
  }

  return {
    onClick: onClickWithPreventedDefault,
  };
};

const getTentativeOnKeyDown = onEnterKey => {
  if (!onEnterKey) {
    return {};
  }

  return {
    onKeyDown: decorate(
      withStoppedEventPropagation,
      withPreventedDefaultBehavior,
      withActionOnlyOnEnter,
    )(onEnterKey),
  };
};

const getDragOrientation = event => {
  const dropTargetBounds = event.target.getBoundingClientRect();

  const dropTargetMiddleY = dropTargetBounds.y + dropTargetBounds.height / 2;
  const pointerY = event.clientY;

  return pointerY < dropTargetMiddleY ? 'above' : 'below';
};

const withActionOnlyOnEnter = toBeDecorated => (event, ...args) => {
  if (event.keyCode !== 13) {
    return;
  }

  return toBeDecorated(event, ...args);
};
