import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import { camelCase } from 'shared-between-everything/src/functionalProgramming';
import Map from 'shared-between-front-ends/src/components/public/Map/Map';
import Table from 'shared-between-front-ends/src/components/public/Table/Table';
import SchedulerTableCell from '../SchedulerTableCell/SchedulerTableCell';
import ResourceDayCell from './ResourceDayCell/ResourceDayCell';
import styles from './ResourceRow.module.scss';
import ResourceRowHeader from './ResourceRowHeader/ResourceRowHeader';

const ResourceRow = ({
  days,
  resource,
  teamId,
  districtId,
  gridRow,
  userRights,
  updatedTimeStamp,
  listedWorkOrders,
  showScheduledWorkOrders,
  sendUsersDataToIFS,
  isResourceErpIdsSent,
  checkIfResourceDataIsValid,
  resourceErpIdsSuccessMessage,
  ...props
}) => {
  return (
    <Table.Row
      key={resource.id}
      className={[
        styles.resourceRow,
        {
          [styles.resourceRow_dropTarget__above]:
            gridRow.orientationOfStripe === 'above',

          [styles.resourceRow_dropTarget__below]:
            gridRow.orientationOfStripe === 'below',
        },
      ]}
      data-row-e2e-test={resource.id}
      {...props}
    >
      <SchedulerTableCell
        draggableFor={
          userRights.reorderResourcesInTeams ? 'resource-reordering' : null
        }
        draggableValue={{
          resourceIdToBeMoved: gridRow.resourceId,
        }}
        droppableFor={
          userRights.reorderResourcesInTeams ? 'resource-reordering' : null
        }
        onDrop2={({ resourceIdToBeMoved }, { dragOrientation }) => {
          gridRow[camelCase(`drop-${dragOrientation}`)]({
            resourceIdToBeMoved,
            resourceIdForReference: gridRow.resourceId,
          });
        }}
        onDragOver2={({ dragOrientation }) => {
          gridRow.setOrientationOfStripe(dragOrientation);
        }}
        onDragLeave2={() => {
          gridRow.setOrientationOfStripe(null);
        }}
        data-drag-resource-test={gridRow.resourceId}
        data-drag-resource-e2e-test={gridRow.resourceId}
      >
        <ResourceRowHeader
          resource={resource}
          teamId={teamId}
          districtId={districtId}
          userRights={userRights}
        />
      </SchedulerTableCell>

      <Map items={days}>
        {day => (
          <SchedulerTableCell key={day.resourceIdAndDate}>
            <ResourceDayCell
              resource={resource}
              listedWorkOrders={listedWorkOrders}
              gridCell={day.gridCell}
              userRights={userRights}
              updatedTimeStamp={updatedTimeStamp}
              showScheduledWorkOrders={showScheduledWorkOrders}
              sendUsersDataToIFS={sendUsersDataToIFS}
              isResourceErpIdsSent={isResourceErpIdsSent}
              checkIfResourceDataIsValid={checkIfResourceDataIsValid}
              resourceErpIdsSuccessMessage={resourceErpIdsSuccessMessage}
            />
          </SchedulerTableCell>
        )}
      </Map>
    </Table.Row>
  );
};

export default decorate(observer)(ResourceRow);
