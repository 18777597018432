import noop from 'lodash/fp/noop';
import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

const getUsers = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/users',
    method: 'GET',
  },
  decoratorConfig: {
    spinnerName: 'getting-users',
    showSpinnerInstantly: false,
    stubificationFunction: noop,
  },
});

export default getUsers;
