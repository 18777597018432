import bodyParametersInputMapper from 'shared-between-everything/src/doings/bodyParametersInputMapper/bodyParametersInputMapper';
import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

const callForSendingOfNotificationEmails = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/scheduler/send-notification-emails',
    method: 'POST',
    inputMapper: bodyParametersInputMapper,
  },

  decoratorConfig: {
    spinnerName: 'sending-notification-emails',
    showSpinnerInstantly: false,
  },
});

export default callForSendingOfNotificationEmails;
