import {
  maintainDistricts,
  maintainResources,
  maintainTeams,
  maintainUsers,
  maintainWorkOrderExpectedEfforts,
  maintainWorkOrders,
  seeTeamAbsenceScheduler,
} from 'shared-between-everything/src/userRightConstants';
import { maintainEffortDefinitionCatalogs } from 'shared-between-everything/src/userRightValueObjects';
import LoginRequiredModel from 'shared-between-front-ends/src/App/LoginRequired/LoginRequiredModel/LoginRequiredModel';
import UserRightRequiredModel from 'shared-between-front-ends/src/App/UserRightRequired/UserRightRequiredModel/UserRightRequiredModel';
import EffortDefinitionCatalogs from './EffortDefinitionCatalogs/EffortDefinitionCatalogs';
import EffortDefinitionCatalogsModel from './EffortDefinitionCatalogs/EffortDefinitionCatalogsModel/EffortDefinitionCatalogsModel';
import LoginRequired from './LoginRequired/LoginRequired';
import NotFound from './NotFound/NotFound';
import AppointmentEffortEntries from './ResourceDashboard/AppointmentEffortEntries/AppointmentEffortEntries';
import AppointmentEffortEntriesModel from './ResourceDashboard/AppointmentEffortEntries/AppointmentEffortEntriesModel/AppointmentEffortEntriesModel';
import ResourceDashboard from './ResourceDashboard/ResourceDashboard';
import ResourceDashboardModel from './ResourceDashboard/ResourceDashboardModel/ResourceDashboardModel';
import UserRightRequired from './UserRightRequired/UserRightRequired';
import UserRights from './UserRights/UserRights';
import CreateDistrict from './district/CreateDistrict/CreateDistrict';
import Districts from './district/Districts/Districts';
import UpdateDistrict from './district/UpdateDistrict/UpdateDistrict';
import AllResources from './resource/AllResources/AllResources';
import CreateResource from './resource/CreateResource/CreateResource';
import UpdateResource from './resource/UpdateResource/UpdateResource';
import AbsenceScheduler from './schedulers/AbsenceScheduler/AbsenceScheduler';
import Scheduler from './schedulers/Scheduler/Scheduler';
import CreateTeam from './team/CreateTeam/CreateTeam';
import TeamEffortCatalogMaintenance from './team/UpdateTeam/TeamEffortCatalogMaintenance/TeamEffortCatalogMaintenance';
import TeamEffortCatalogMaintenanceModel from './team/UpdateTeam/TeamEffortCatalogMaintenance/TeamEffortCatalogMaintenanceModel';
import UpdateTeam from './team/UpdateTeam/UpdateTeam';
import UpdateTeamModel from './team/UpdateTeam/UpdateTeamModel';
import CreateTeamResource from './team/resource/CreateTeamResource/CreateTeamResource';
import TeamResources from './team/resource/TeamResources/TeamResources';
import UpdateTeamResource from './team/resource/UpdateTeamResource/UpdateTeamResource';
import CreateWorkOrder from './work-order/CreateWorkOrder/CreateWorkOrder';
import UpdateWorkOrder from './work-order/UpdateWorkOrder/UpdateWorkOrder';
import UpdateWorkOrderModel from './work-order/UpdateWorkOrder/UpdateWorkOrderModel';
import UpdateWorkOrderExpectedEfforts from './work-order/UpdateWorkOrderExpectedEfforts/UpdateWorkOrderExpectedEfforts';
import UpdateWorkOrderExpectedEffortsModel from './work-order/UpdateWorkOrderExpectedEfforts/UpdateWorkOrderExpectedEffortsModel/UpdateWorkOrderExpectedEffortsModel';
import WorkOrderRoster from './work-order/WorkOrderRoster/WorkOrderRoster';

const routes = [
  {
    name: 'login-required',
    backRoute: null,
    View: LoginRequired,
    ViewModel: LoginRequiredModel,
  },
  {
    name: 'user-right-required',
    backRoute: null,
    View: UserRightRequired,
    ViewModel: UserRightRequiredModel,
  },
  {
    name: 'not-found',
    backRoute: null,
    View: NotFound,
    ViewModel: null,
  },
  {
    path: '/',
    name: 'districts',
    backRoute: null,
    loginIsRequired: true,
    View: Districts,
  },
  {
    path: '/user-rights',
    name: 'user-right-maintenance',
    backRoute: null,
    loginIsRequired: true,
    requiredUserRight: maintainUsers,
    View: UserRights,
  },
  {
    path: '/resources',
    name: 'all-resources',
    backRoute: 'districts',
    loginIsRequired: true,
    requiredUserRight: maintainResources,
    View: AllResources,
  },
  {
    path: '/resources/create',
    name: 'create-resource',
    backRoute: 'all-resources',
    loginIsRequired: true,
    requiredUserRight: maintainResources,
    View: CreateResource,
  },
  {
    path: '/resources/:resourceId',
    name: 'update-resource',
    backRoute: 'all-resources',
    loginIsRequired: true,
    requiredUserRight: maintainResources,
    View: UpdateResource,
  },
  {
    path: '/districts/create',
    name: 'create-district',
    backRoute: 'districts',
    loginIsRequired: true,
    requiredUserRight: maintainDistricts,
    View: CreateDistrict,
  },
  {
    path: '/districts/:districtId',
    name: 'update-district',
    backRoute: 'districts',
    loginIsRequired: true,
    requiredUserRight: maintainDistricts,
    View: UpdateDistrict,
  },
  {
    path: '/districts/:districtId/teams/create',
    name: 'district-create-team',
    backRoute: 'districts',
    loginIsRequired: true,
    requiredUserRight: maintainTeams,
    View: CreateTeam,
  },
  {
    path: '/districts/:districtId/teams/:teamId/scheduler',
    name: 'district-team-scheduler',
    backRoute: null,
    loginIsRequired: true,
    View: Scheduler,
  },
  {
    path: '/districts/:districtId/teams/:teamId',
    name: 'district-update-team',
    backRoute: 'districts',
    loginIsRequired: true,
    requiredUserRight: maintainTeams,
    ViewModel: UpdateTeamModel,
    View: UpdateTeam,
  },
  {
    path: '/districts/:districtId/teams/:teamId/scheduler/effort-catalogs',

    name: 'district-update-team/effort-catalogs',
    backRoute: 'district-update-team',
    loginIsRequired: true,
    requiredUserRight: maintainTeams,
    ViewModel: TeamEffortCatalogMaintenanceModel,
    View: TeamEffortCatalogMaintenance,
  },
  {
    path: '/districts/:districtId/teams/:teamId/scheduler/team-maintenance',
    name: 'district-team-scheduler/team-maintenance',
    backRoute: 'district-team-scheduler',
    loginIsRequired: true,
    requiredUserRight: maintainTeams,
    ViewModel: UpdateTeamModel,
    View: UpdateTeam,
  },
  {
    path:
      '/districts/:districtId/teams/:teamId/scheduler/team-maintenance/effort-catalogs',

    name: 'district-team-scheduler/team-maintenance/effort-catalogs',
    backRoute: 'district-team-scheduler/team-maintenance',
    loginIsRequired: true,
    requiredUserRight: maintainTeams,
    ViewModel: TeamEffortCatalogMaintenanceModel,
    View: TeamEffortCatalogMaintenance,
  },
  {
    path: '/districts/:districtId/teams/:teamId/absence-scheduler',
    name: 'district-team-absence-scheduler',
    backRoute: null,
    loginIsRequired: true,
    requiredUserRight: seeTeamAbsenceScheduler,
    View: AbsenceScheduler,
  },
  {
    path: '/districts/:districtId/teams/:teamId/work-orders',
    name: 'district-team-work-orders',
    backRoute: null,
    loginIsRequired: true,
    requiredUserRight: maintainWorkOrders,
    View: WorkOrderRoster,
  },
  {
    path: '/districts/:districtId/teams/:teamId/resources',
    name: 'district-team-resources',
    backRoute: 'districts',
    loginIsRequired: true,
    requiredUserRight: maintainResources,
    View: TeamResources,
  },
  {
    path: '/districts/:districtId/teams/:teamId/resources/create',
    name: 'district-team-create-resource',
    backRoute: 'district-team-scheduler',
    loginIsRequired: true,
    requiredUserRight: maintainResources,
    View: CreateTeamResource,
  },
  {
    path: '/districts/:districtId/teams/:teamId/resources/:resourceId',
    name: 'district-team-update-resource',
    backRoute: 'district-team-resources',
    loginIsRequired: true,
    requiredUserRight: maintainResources,
    View: UpdateTeamResource,
  },
  {
    path: '/districts/:districtId/teams/:teamId/resources/:resourceId/plan',
    name: 'district-team-resource-plan',
    backRoute: null,
    loginIsRequired: true,
    View: ResourceDashboard,
    ViewModel: ResourceDashboardModel,
  },
  {
    path: '/districts/:districtId/teams/:teamId/work-orders/create',
    name: 'district-team-create-work-order',
    backRoute: 'district-team-scheduler',
    loginIsRequired: true,
    requiredUserRight: maintainWorkOrders,
    View: CreateWorkOrder,
  },

  {
    path:
      '/districts/:districtId/teams/:teamId/scheduler/work-orders/:workOrderId',
    name: 'district-team-update-work-order-from-scheduler',
    backRoute: 'district-team-scheduler',
    loginIsRequired: true,
    requiredUserRight: maintainWorkOrders,
    ViewModel: UpdateWorkOrderModel,
    View: UpdateWorkOrder,
  },
  {
    path:
      '/districts/:districtId/teams/:teamId/scheduler/work-orders/:workOrderId/expected-efforts',
    name: 'district-team-update-work-order-from-scheduler/expected-efforts',
    backRoute: 'district-team-update-work-order-from-scheduler',
    loginIsRequired: true,
    requiredUserRight: maintainWorkOrderExpectedEfforts,
    ViewModel: UpdateWorkOrderExpectedEffortsModel,
    View: UpdateWorkOrderExpectedEfforts,
  },

  {
    path:
      '/districts/:districtId/teams/:teamId/absence-scheduler/work-orders/:workOrderId',
    name: 'district-team-update-work-order-from-absence-scheduler',
    backRoute: 'district-team-absence-scheduler',
    loginIsRequired: true,
    requiredUserRight: maintainWorkOrders,
    ViewModel: UpdateWorkOrderModel,
    View: UpdateWorkOrder,
  },
  {
    path:
      '/districts/:districtId/teams/:teamId/absence-scheduler/work-orders/:workOrderId/expected-efforts',
    name:
      'district-team-update-work-order-from-absence-scheduler/expected-efforts',
    backRoute: 'district-team-update-work-order-from-absence-scheduler',
    loginIsRequired: true,
    requiredUserRight: maintainWorkOrderExpectedEfforts,
    ViewModel: UpdateWorkOrderExpectedEffortsModel,
    View: UpdateWorkOrderExpectedEfforts,
  },

  {
    path: '/districts/:districtId/teams/:teamId/work-orders/:workOrderId',
    name: 'district-team-update-work-order-from-work-orders',
    backRoute: 'district-team-work-orders',
    loginIsRequired: true,
    requiredUserRight: maintainWorkOrders,
    ViewModel: UpdateWorkOrderModel,
    View: UpdateWorkOrder,
  },
  {
    path:
      '/districts/:districtId/teams/:teamId/work-orders/:workOrderId/expected-efforts',
    name: 'district-team-update-work-order-from-work-orders/expected-efforts',
    backRoute: 'district-team-update-work-order-from-work-orders',
    loginIsRequired: true,
    requiredUserRight: maintainWorkOrderExpectedEfforts,
    ViewModel: UpdateWorkOrderExpectedEffortsModel,
    View: UpdateWorkOrderExpectedEfforts,
  },

  {
    path: '/resource-dashboard/:resourceId',
    name: 'resource-dashboard',
    backRoute: null,
    loginIsRequired: true,
    requiredUserRight: null,
    ViewModel: ResourceDashboardModel,
    View: ResourceDashboard,
  },
  {
    path: '/resource-dashboard/:resourceId/select/:appointmentId',
    name: 'resource-dashboard-select-appointment',
    backRoute: null,
    loginIsRequired: true,
    requiredUserRight: null,
    ViewModel: ResourceDashboardModel,
    View: ResourceDashboard,
  },
  {
    path: '/resource-dashboard/:resourceId/appointments/:appointmentId',
    name: 'resource-dashboard/appointment-effort-entries',
    backRoute: 'resource-dashboard',
    loginIsRequired: true,
    requiredUserRight: null,
    ViewModel: AppointmentEffortEntriesModel,
    View: AppointmentEffortEntries,
  },
  {
    path: '/effort-definition-catalogs',
    name: 'effort-definition-catalogs',
    backRoute: 'districts',
    loginIsRequired: true,
    requiredUserRight: maintainEffortDefinitionCatalogs.type,
    ViewModel: EffortDefinitionCatalogsModel,
    View: EffortDefinitionCatalogs,
  },
];

export default routes;
