import React from 'react';
import Button from 'shared-between-front-ends/src/components/private/Button/Button';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import Activatable from 'shared-between-front-ends/src/components/public/Popover/Activatable/Activatable';
import Popover from 'shared-between-front-ends/src/components/public/Popover/Popover';

const UrgencyIcon = ({
  backgroundColor,
  workOrderErpId,
  urgencyState,
  hoverLabel,
}) => {
  return (
    <Popover
      popovers={[
        {
          activationId: `popover-for-notification-hover-${workOrderErpId}`,
          activateOn: 'hover',
          position: 'left top to right top of children',

          content: (
            <Panel.Content
              padding={{ size: 'xs' }}
              backgroundColor={backgroundColor}
            >
              <BodyText flexItem color="white">
                {hoverLabel}
              </BodyText>
            </Panel.Content>
          ),
        },
      ]}
    >
      <Activatable>
        {() => (
          <Button
            data-urgent-test
            backgroundColor="transparent"
            size="sm"
            padding={{ size: 0 }}
            onClick={urgencyState.toggleCompletion}
          >
            <Icon
              name="circle-exclamation"
              color={urgencyState.value ? 'error' : 'grey30'}
              size="md"
            />
          </Button>
        )}
      </Activatable>
    </Popover>
  );
};

export default UrgencyIcon;
