import React from 'react';
import localTranslate from '../../../doings/localTranslate/localTranslate';

import BodyText from '../../public/BodyText/BodyText';
import Element from '../Element/Element';
import Span from '../Element/Span/Span';
import styles from './Input.module.scss';
import inputTranslations from './inputTranslations';

const translate = localTranslate(inputTranslations);

function Input({ value, onChange, readOnly, className, ...props }) {
  const onNativeChange = ({ target: { value } }) => onChange(value);

  return (
    <BodyText size="xxs">
      {!readOnly && (
        <Element
          occupyHorizontalSpace
          className={[styles.input, className]}
          tagName="input"
          onChange={onNativeChange}
          placeholder={translate('placeholder')}
          padding={{ horizontal: 'sm', vertical: 'xs' }}
          border={{
            size: 'sm',
            color: 'grey70',
            radius: { size: 'md' },
          }}
          value={value}
          {...props}
        />
      )}

      {readOnly && <Span>{value}</Span>}
    </BodyText>
  );
}

export default Input;
