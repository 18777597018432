import React from 'react';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import MarginSpace from 'shared-between-front-ends/src/components/private/MarginSpace/MarginSpace';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import Popover from 'shared-between-front-ends/src/components/public/Popover/Popover';
import PrimaryButton from 'shared-between-front-ends/src/components/public/PrimaryButton/PrimaryButton';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import MeasurementAmount from '../../MeasurementAmount/MeasurementAmount';
import resourceDashboardTranslations from '../../resourceDashboardTranslations';

const translate = localTranslate(resourceDashboardTranslations);

/** The effort description info box popover */
const EffortDescriptionPopover = ({
  activationId,
  closeEffortDescriptionPopover,
  catalogItem,
  descriptionIsShown,
  includingIsShown,
  excludingIsShown,
  ...props
}) => {
  const roundedDurationPerUnit = {
    amount: Math.round(catalogItem.duration.amount * 100) / 100,
    measurementUnitId: 'hour',
  };

  // custom style
  const styles = {
    display: 'inline',
    verticalAlign: 'bottom',
  };

  return (
    <Popover
      style={styles}
      activationId={activationId}
      activateOn="click"
      position="vertical center of screen"
      content={
        <MarginSpace compact>
          <Panel>
            <Flex
              centeredVertically
              color="white"
              backgroundColor="modalHeader"
              padding={{ size: 'sm' }}
            >
              <Icon size="md" name="circle-info" compact />

              <Gutter size="sm" />

              <BodyText>{translate('effortDescription')}</BodyText>
            </Flex>
            <Panel.Content padding={{ size: 'lg' }}>
              <HeavyText size="xs">{catalogItem.name}</HeavyText>

              <Div
                shown={descriptionIsShown}
                data-show-expected-effort-definition-description-test={
                  catalogItem.id
                }
              >
                <Gutter size="sm" />

                <BodyText>{catalogItem.description}</BodyText>
              </Div>

              <Div
                shown={includingIsShown}
                data-show-expected-effort-definition-including-test={
                  catalogItem.id
                }
              >
                <Gutter size="sm" />

                <HeavyText>{translate('including')}</HeavyText>

                <Gutter size="xs" />

                <BodyText>{catalogItem.including}</BodyText>
              </Div>

              <Div
                shown={excludingIsShown}
                data-show-expected-effort-definition-excluding-test={
                  catalogItem.id
                }
              >
                <Gutter size="sm" />

                <HeavyText>{translate('excluding')}</HeavyText>

                <Gutter size="xs" />

                <BodyText>{catalogItem.excluding}</BodyText>
              </Div>

              <Gutter size="sm" />

              <Div>
                <HeavyText>{translate('durationPerUnit')}</HeavyText>

                <Gutter size="xs" />

                <BodyText>
                  <MeasurementAmount value={roundedDurationPerUnit} />
                </BodyText>
              </Div>

              <Flex centered>
                <PrimaryButton
                  onClick={closeEffortDescriptionPopover}
                  data-close-effort-description-test
                >
                  {translate('close')}
                </PrimaryButton>
              </Flex>
            </Panel.Content>
          </Panel>
        </MarginSpace>
      }
    >
      {props.children}
    </Popover>
  );
};

export default EffortDescriptionPopover;
