import { computed } from 'mobx';
import getCurrentLanguageForFrontend from '../../../doings/localTranslate/getCurrentLanguageForFrontend';

const languageOptions = [
  {
    id: 'se',
    description: 'PÅ SVENSKA',
  },
  {
    id: 'en',
    description: 'IN ENGLISH',
  },
  {
    id: 'fi',
    description: 'SUOMEKSI',
  },
];

export default class LanguageSelectorModel {
  dependencies = {};

  constructor(
    browserStorage = require('../../../doings/browserStorage/browserStorage')
      .default,
    windowLocation = window.location,
    getCurrentLanguage = getCurrentLanguageForFrontend,
  ) {
    this.dependencies.browserStorage = browserStorage;
    this.dependencies.windowLocation = windowLocation;
    this.dependencies.getCurrentLanguage = getCurrentLanguage;
  }

  _selectLanguage = languageId => {
    this.dependencies.browserStorage.set('language', languageId);
    this.dependencies.windowLocation.reload();
  };

  @computed
  get currentLanguageId() {
    return this.dependencies.getCurrentLanguage();
  }

  @computed
  get languages() {
    return languageOptions.map(
      ({ id: languageId, description: languageDescription }) => {
        const selected = languageId === this.currentLanguageId;

        return {
          id: languageId,
          description: languageDescription,
          disabled: selected,
          color: selected ? 'primary' : 'action',
          select: () => this._selectLanguage(languageId),
        };
      },
    );
  }
}
