/* istanbul ignore file */

import {
  seeTeamAbsenceScheduler as seeTeamAbsenceSchedulerImport,
  maintainDistricts as maintainDistrictsImport,
  maintainTeams as maintainTeamsImport,
  removeTeams as removeTeamsImport,
  maintainResources as maintainResourcesImport,
  maintainWorkOrders as maintainWorkOrdersImport,
  createWorkOrdersOfAllTypes as createWorkOrdersOfAllTypesImport,
  scheduleWorkOrdersOfAllTypes as scheduleWorkOrdersOfAllTypesImport,
  doScheduling as doSchedulingImport,
  seeObfuscatedAppointments as seeObfuscatedAppointmentsImport,
  seeDistantAppointments as seeDistantAppointmentsImport,
  maintainUsers as maintainUsersImport,
  reorderResourcesInTeams as reorderResourcesInTeamsImport,
  maintainEffortDefinitionCatalogs as maintainEffortDefinitionCatalogsImport,
  maintainWorkOrderExpectedEfforts as maintainWorkOrderExpectedEffortsImport,
} from './userRightConstants';

export const seeTeamAbsenceScheduler = {
  type: seeTeamAbsenceSchedulerImport,
  translations: {
    en: { name: 'See team absence scheduler' },
    fi: { name: 'See team absence scheduler' },
    pl: { name: 'See team absence scheduler' },
    se: { name: 'See team absence scheduler' },
  },
};

export const maintainDistricts = {
  type: maintainDistrictsImport,
  translations: {
    en: { name: 'Maintain districts' },
    fi: { name: 'Maintain districts' },
    pl: { name: 'Maintain districts' },
    se: { name: 'Maintain districts' },
  },
};

export const maintainTeams = {
  type: maintainTeamsImport,
  translations: {
    en: { name: 'Maintain teams' },
    fi: { name: 'Maintain teams' },
    pl: { name: 'Maintain teams' },
    se: { name: 'Maintain teams' },
  },
};

export const removeTeams = {
  type: removeTeamsImport,
  translations: {
    en: { name: 'Remove teams' },
    fi: { name: 'Remove teams' },
    pl: { name: 'Remove teams' },
    se: { name: 'Remove teams' },
  },
};

export const maintainResources = {
  type: maintainResourcesImport,
  translations: {
    en: { name: 'Maintain resources' },
    fi: { name: 'Maintain resources' },
    pl: { name: 'Maintain resources' },
    se: { name: 'Maintain resources' },
  },
};

export const maintainWorkOrders = {
  type: maintainWorkOrdersImport,
  translations: {
    en: { name: 'Maintain work orders' },
    fi: { name: 'Maintain work orders' },
    pl: { name: 'Maintain work orders' },
    se: { name: 'Maintain work orders' },
  },
};

export const createWorkOrdersOfAllTypes = {
  type: createWorkOrdersOfAllTypesImport,
  translations: {
    fi: { name: 'Create work orders of all types' },
    pl: { name: 'Create work orders of all types' },
    en: { name: 'Create work orders of all types' },
    se: { name: 'Create work orders of all types' },
  },
};

export const scheduleWorkOrdersOfAllTypes = {
  type: scheduleWorkOrdersOfAllTypesImport,
  translations: {
    fi: { name: 'Schedule work orders of all types' },
    pl: { name: 'Schedule work orders of all types' },
    en: { name: 'Schedule work orders of all types' },
    se: { name: 'Schedule work orders of all types' },
  },
};

export const doScheduling = {
  type: doSchedulingImport,
  translations: {
    en: { name: 'Do scheduling' },
    fi: { name: 'Do scheduling' },
    pl: { name: 'Do scheduling' },
    se: { name: 'Do scheduling' },
  },
};

export const seeObfuscatedAppointments = {
  type: seeObfuscatedAppointmentsImport,
  translations: {
    en: { name: 'See obfuscated appointments' },
    fi: { name: 'See obfuscated appointments' },
    pl: { name: 'See obfuscated appointments' },
    se: { name: 'See obfuscated appointments' },
  },
};

export const seeDistantAppointments = {
  type: seeDistantAppointmentsImport,
  translations: {
    en: { name: 'See distant appointments' },
    fi: { name: 'See distant appointments' },
    pl: { name: 'See distant appointments' },
    se: { name: 'See distant appointments' },
  },
};

export const maintainUsers = {
  type: maintainUsersImport,
  translations: {
    en: { name: 'Maintain users' },
    fi: { name: 'Maintain users' },
    pl: { name: 'Maintain users' },
    se: { name: 'Maintain users' },
  },
};

export const reorderResourcesInTeams = {
  type: reorderResourcesInTeamsImport,
  translations: {
    en: { name: 'Reorder resources in teams' },
    fi: { name: 'Reorder resources in teams' },
    pl: { name: 'Reorder resources in teams' },
    se: { name: 'Reorder resources in teams' },
  },
};

export const maintainEffortDefinitionCatalogs = {
  type: maintainEffortDefinitionCatalogsImport,
  translations: {
    en: { name: 'Maintain catalogs' },
    fi: { name: 'Maintain catalogs' },
    pl: { name: 'Maintain catalogs' },
    se: { name: 'Maintain catalogs' },
  },
};

export const maintainWorkOrderExpectedEfforts = {
  type: maintainWorkOrderExpectedEffortsImport,
  translations: {
    en: { name: 'Maintain work order expected efforts' },
    fi: { name: 'Maintain work order expected efforts' },
    pl: { name: 'Maintain work order expected efforts' },
    se: { name: 'Maintain work order expected efforts' },
  },
};
