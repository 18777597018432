export default {
  en: {
    resources: 'Resources',
    resource: 'Resource',
    erpId: 'ERP ID',
    firstName: 'First Name',
    lastName: 'Last Name',
    role: 'Role',
    phoneNumber: 'Phone Number',
    emailAddress: 'Email Address',
    competences: 'Competences',
    competenceUpdate: 'Not yet updated',
    createResource: 'Create new resource',
    createResourceButton: 'Create new resource',
    createSuccess: 'Created',
    backButton: 'Back',
    updateResourceButton: 'Update',
    updateSuccess: 'Updated',
    deleteConfirmation: 'Confirm delete?',
    deleteSuccess: 'Resource deleted',
    roles: {
      mechanic: 'Mechanic',
      designer: 'Designer',
      foreman: 'Foreman',
      coordinator: 'Coordinator',
      subcontractor: 'Subcontractor',
      specialResource: 'Special Resource',
      partTimeEmp: 'Part time employee',
      notDefined: 'Not defined',
    },
  },
  fi: {
    resources: 'Henkilöstö',
    resource: 'Henkilö',
    erpId: 'Henkilönumero',
    firstName: 'Etunimi',
    lastName: 'Sukunimi',
    role: 'Rooli',
    phoneNumber: 'Puhelinnumero',
    emailAddress: 'Sähköposti',
    competences: 'Pätevyydet',
    competenceUpdate: 'Ei vielä päivitetty',
    createResource: 'Henkilön luonti',
    createResourceButton: 'Luo uusi henkilö',
    createSuccess: 'Luotu',
    backButton: 'Takaisin',
    updateResourceButton: 'Päivitä',
    updateSuccess: 'Päivitetty',
    deleteConfirmation: 'Poistetaanko?',
    deleteSuccess: 'Henkilö poistettu',
    roles: {
      mechanic: 'Asentaja',
      designer: 'Suunnittelija',
      foreman: 'Työnohjaaja',
      coordinator: 'Tuotantohenkilö',
      subcontractor: 'Alihankkija',
      specialResource: 'Erityinen resurssi',
      partTimeEmp: 'Osa-aikainen työntekijä',
      notDefined: 'Ei määritetty',
    },
  },
  pl: {
    resources: 'Zasoby',
    resource: 'Zasób',
    erpId: 'Nr IFS',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    role: 'Funkcja',
    phoneNumber: 'Numer telefonu',
    emailAddress: 'Adres e-mail',
    competences: 'Competences',
    competenceUpdate: 'Competences need to be updated',
    createResource: 'Utwórz nowy zasób',
    createResourceButton: 'Utwórz nowy zasób',
    createSuccess: 'Utworzono',
    backButton: 'Wstecz',
    updateResourceButton: 'Aktualizacja',
    updateSuccess: 'Zaktualizowano',
    deleteConfirmation: 'Potwierdzić usunięcie?',
    deleteSuccess: 'Zasób usunięty',
    roles: {
      mechanic: 'Mechanik',
      designer: 'Projektant',
      foreman: 'Kierownik',
      coordinator: 'Koordynator',
      subcontractor: 'Podwykonawca',
      specialResource: 'Special Resource',
      partTimeEmp: 'Part time employee',
      notDefined: 'Nie określono',
    },
  },
  se: {
    resources: 'Personal',
    resource: 'Personal',
    erpId: 'Användarid',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    role: 'Roll',
    phoneNumber: 'Telefonnummer',
    emailAddress: 'Email Adress',
    competences: 'Behörigheter',
    competenceUpdate: 'Inte uppdaterad ännu',
    createResource: 'Skapa ny användare',
    createResourceButton: 'Skapa ny användare',
    createSuccess: 'Skapad',
    backButton: 'Tillbaka',
    updateResourceButton: 'Uppdatera',
    updateSuccess: 'Updated',
    deleteConfirmation: 'Bekräfta borttagning?',
    deleteSuccess: 'Borttagning lyckades',
    roles: {
      mechanic: 'Tekniker',
      designer: 'Designer',
      foreman: 'Teamledare',
      coordinator: 'Coordinator',
      subcontractor: 'Subcontractor',
      specialResource: 'Special resurs',
      partTimeEmp: 'Deltidsanställd',
      notDefined: 'Inte definierad',
    },
  },
};
