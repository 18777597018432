import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import fromPairs from 'lodash/fp/fromPairs';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import groupBy from 'lodash/fp/groupBy';
import map from 'lodash/fp/map';
import matches from 'lodash/fp/matches';
import orderBy from 'lodash/fp/orderBy';
import size from 'lodash/fp/size';
import { action, computed, observable } from 'mobx';
import moment from 'moment-timezone';
import pipeline from 'shared-between-everything/src/doings/pipeline/pipeline';

import { values } from 'shared-between-everything/src/functionalProgramming';

import getWorkOrderTypeValueObject from 'shared-between-everything/src/getWorkOrderTypeValueObject';
import workOrderTypes from 'shared-between-everything/src/workOrderTypes';
import ActivationModel from 'shared-between-front-ends/src/components/public/Popover/ActivationModel/ActivationModel';
import whenRouteChangesTo from 'shared-between-front-ends/src/decorators/whenRouteChangesTo/whenRouteChangesTo';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import SchedulerModel from '../../Scheduler/SchedulerModel/SchedulerModel';
import AbsenceBudgetModel from '../SchedulerTableHeader/AbsenceBudget/AbsenceBudgetModel/AbsenceBudgetModel';
import createAbsenceBudgetImport from '../SchedulerTableHeader/AbsenceBudget/AbsenceBudgetModel/createAbsenceBudget/createAbsenceBudget';
import getAbsenceBudgetsImport from '../SchedulerTableHeader/AbsenceBudget/AbsenceBudgetModel/getAbsenceBudgets/getAbsenceBudgets';
import callForLeaveDaysForTeamResourcesImport from './callForLeaveDaysForTeamResources/callForLeaveDaysForTeamResources';

export default class AbsenceSchedulerModel {
  dependencies = {};

  constructor(
    schedulerModel = getModel(SchedulerModel),
    callForLeaveDaysForTeamResources = callForLeaveDaysForTeamResourcesImport,
    getAbsenceBudgets = getAbsenceBudgetsImport,
    createAbsenceBudget = createAbsenceBudgetImport,
    routingModel = getModel(RoutingModel),
    activationModel = getModel(ActivationModel),
  ) {
    this.dependencies.schedulerModel = schedulerModel;
    this.dependencies.callForLeaveDaysForTeamResources = callForLeaveDaysForTeamResources;
    this.dependencies.getAbsenceBudgets = getAbsenceBudgets;
    this.dependencies.createAbsenceBudget = createAbsenceBudget;
    this.dependencies.routingModel = routingModel;
    this.dependencies.activationModel = activationModel;

    this.setNumberOfWeeksShown = schedulerModel.setNumberOfWeeksShown;
    this.goBackToCurrentWeek = schedulerModel.goBackToCurrentWeek;
    this.goToNextWeek = schedulerModel.goToNextWeek;
    this.goToPreviousWeek = schedulerModel.goToPreviousWeek;
    this.goToDate = schedulerModel.goToDate;
    this.loadTeamDetails = schedulerModel.loadTeamDetails;
    this.deselectAppointment = schedulerModel.deselectAppointment;
    this.setSelectedAppointment = schedulerModel.setSelectedAppointment;
    this.clearWorkOrderSelection = schedulerModel.clearWorkOrderSelection;

    this.absenceBudgetModel = new AbsenceBudgetModel(
      getAbsenceBudgets,
      createAbsenceBudget,
      this,
      activationModel,
    );
  }

  @computed
  get teamId() {
    return this.dependencies.schedulerModel.teamId;
  }

  @computed
  get districtId() {
    return this.dependencies.schedulerModel.districtId;
  }

  @observable leaveDaysByResourceIdRaw = {};

  @action
  setLeaveDaysByResourceId = leaveDaysByResourceId => {
    this.leaveDaysByResourceIdRaw = leaveDaysByResourceId;
  };

  @computed
  get appointmentsFromCurrentWeek() {
    return [...this.appointmentsById.values()].filter(
      toAppointmentsfromCurrentWeek,
    );
  }

  @computed
  get leaveDaysByResourceId() {
    const getLeaveCounts = getLeaveCountsFor(
      this.absenceWorkOrderTypes,
      this.leaveDaysByResourceIdRaw,
      this.appointmentsFromCurrentWeek,
    );

    return pipeline(
      [...this.resourcesById.values()],
      map(({ id: resourceId }) => [resourceId, getLeaveCounts(resourceId)]),
      fromPairs,
    );
  }

  @computed
  get buttonForClearingWorkOrderSelectionIsEnabled() {
    return this.dependencies.schedulerModel
      .buttonForClearingWorkOrderSelectionIsEnabled;
  }

  @whenRouteChangesTo('district-team-absence-scheduler')
  refresh = async () => {
    this.dependencies.schedulerModel.refresh();
    this.absenceBudgetModel.refresh();

    const {
      response: resourceLeaveDays,
    } = await this.dependencies.callForLeaveDaysForTeamResources({
      teamId: this.dependencies.schedulerModel.teamId,
    });

    this.setLeaveDaysByResourceId(resourceLeaveDays);
  };

  @computed
  get teamName() {
    return this.dependencies.schedulerModel.teamName;
  }

  @computed
  get weeksInView() {
    return this.dependencies.schedulerModel.weeksInView;
  }

  @computed
  get showWeekNumberLabels() {
    return this.dependencies.schedulerModel.showWeekNumberLabels;
  }

  @computed
  get showDayOfWeekString() {
    return this.dependencies.schedulerModel.showDayOfWeekString;
  }

  @computed
  get showDayOfMonthString() {
    return this.dependencies.schedulerModel.showDayOfMonthString;
  }

  @computed
  get showNumberOfWeeksOptions() {
    return this.dependencies.schedulerModel.showNumberOfWeeksOptions;
  }

  @computed
  get daysInView() {
    return this.dependencies.schedulerModel.daysInView;
  }

  @computed
  get monthsOfSelectedDateRange() {
    return this.dependencies.schedulerModel.monthsOfSelectedDateRange;
  }

  @computed
  get daysOfSelectedDateRange() {
    return this.dependencies.schedulerModel.daysOfSelectedDateRange;
  }

  @computed
  get yearStringOfSelectedDateRange() {
    return this.dependencies.schedulerModel.yearStringOfSelectedDateRange;
  }

  changeViewToScheduler = () => {
    this.dependencies.routingModel.setRouteTo({
      name: 'district-team-scheduler',

      pathParameters: {
        districtId: this.districtId,
        teamId: this.teamId,
      },

      queryParameters: this.dependencies.routingModel.queryParameters,
    });
  };

  @computed
  get resourceRows() {
    return this.dependencies.schedulerModel.resourceRows;
  }

  @computed
  get resourcesById() {
    return this.dependencies.schedulerModel.resourcesById;
  }

  @computed
  get appointmentsById() {
    return this.dependencies.schedulerModel.appointmentsById;
  }

  @computed
  get selectedAppointment() {
    return this.dependencies.schedulerModel.selectedAppointment;
  }

  @computed
  get workOrdersById() {
    return this.dependencies.schedulerModel.workOrdersById;
  }

  @computed
  get absenceWorkOrders() {
    return sortedAbsenceWorkOrders([
      ...this.dependencies.schedulerModel.workOrdersById.values(),
    ]);
  }

  absenceWorkOrderTypes = pipeline(
    workOrderTypes,
    values,
    filter('absenceScheduling'),
    orderBy(['absenceScheduling.sortOrder'], ['asc']),
  );
}

const toAbsenceSpecificWorkOrderTypes = workOrder =>
  !!getWorkOrderTypeValueObject(workOrder.type).absenceScheduling;

const toSortableWorkOrders = workOrder => ({
  workOrder,
  sortOrder: getSortOrder(workOrder.type),
});

const sortedAbsenceWorkOrders = flow(
  filter(toAbsenceSpecificWorkOrderTypes),
  map(toSortableWorkOrders),
  orderBy(['sortOrder'], ['asc']),
  map('workOrder'),
);

const getSortOrder = workOrderType =>
  getWorkOrderTypeValueObject(workOrderType).absenceScheduling.sortOrder;

const toAppointmentsfromCurrentWeek = appointment =>
  dateTimeIsAtLeastFromCurrentWeek(appointment.startDateTime);

const dateTimeIsAtLeastFromCurrentWeek = dateTime =>
  moment(dateTime).isSameOrAfter(moment().startOf('isoWeek').utc());

const getAppointmentCountFor = (resourceId, appointments) => workOrderType =>
  pipeline(
    appointments,
    filter(matches({ resourceId })),
    groupBy('workOrderType'),
    get(workOrderType),
    size,
  );

const getAppointmentBalanceFor = (
  resourceId,
  leaveDaysByResourceIdRaw,
  getAppointmentCount,
) => workOrderType =>
  getOr(0, `${resourceId}.${workOrderType}Balance`, leaveDaysByResourceIdRaw) -
  getAppointmentCount(workOrderType);

const getLeaveCountsFor = (
  absenceWorkOrderTypes,
  leaveDaysByResourceIdRaw,
  appointmentsFromCurrentWeek,
) => resourceId => {
  const getAppointmentCount = getAppointmentCountFor(
    resourceId,
    appointmentsFromCurrentWeek,
  );

  const getAppointmentBalance = getAppointmentBalanceFor(
    resourceId,
    leaveDaysByResourceIdRaw,
    getAppointmentCount,
  );

  return pipeline(
    absenceWorkOrderTypes,

    map(workOrderType => [
      workOrderType.type,

      workOrderType.absenceScheduling.countValueAs === 'balance'
        ? getAppointmentBalance(workOrderType.type)
        : getAppointmentCount(workOrderType.type),
    ]),

    fromPairs,
  );
};
