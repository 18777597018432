import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Map from 'shared-between-front-ends/src/components/public/Map/Map';
import SeparatorLine from 'shared-between-front-ends/src/components/public/SeparatorLine/SeparatorLine';
import AppointmentBox from './AppointmentBox/AppointmentBox';

const ResourceDayCell = ({
  resource,
  gridCell,
  userRights,
  showScheduledWorkOrders,
  listedWorkOrders,
  sendUsersDataToIFS,
  isResourceErpIdsSent,
  checkIfResourceDataIsValid,
  resourceErpIdsSuccessMessage,
}) => {
  return (
    <Div
      highlight={gridCell.draggedOver}
      occupyVerticalSpace
      clickableAppearance={gridCell.hasClickableAppearance}
      backgroundColor={gridCell.backgroundColor}
      onClick={gridCell.selectForScheduling}
      droppableFor="appointment-scheduling"
      onDrop2={appointmentOrWorkOrder => {
        gridCell.drop(appointmentOrWorkOrder);
        gridCell.setDraggedOver(false);
      }}
      onDragEnter2={() => {
        gridCell.setDraggedOver(true);
      }}
      onDragLeave2={() => {
        gridCell.setDraggedOver(false);
      }}
      data-select-resource-day-for-scheduling-test={gridCell.resourceIdAndDate}
      data-select-resource-day-for-scheduling-e2e-test={
        gridCell.resourceIdAndDate
      }
    >
      <Flex occupyVerticalSpace occupyHorizontalSpace>
        {gridCell.day.isFirstDayOfMonth && (
          <SeparatorLine backgroundColor="tableBorder" occupyVerticalSpace />
        )}

        <Div flexItem padding={{ bottom: 'sm' }}>
          <Map items={gridCell.appointments} separator={<Gutter size="3xs" />}>
            {appointment => (
              <AppointmentBox
                resource={resource}
                listedWorkOrders={listedWorkOrders}
                appointment={appointment}
                userRights={userRights}
                showScheduledWorkOrders={showScheduledWorkOrders}
                sendUsersDataToIFS={sendUsersDataToIFS}
                isResourceErpIdsSent={isResourceErpIdsSent}
                checkIfResourceDataIsValid={checkIfResourceDataIsValid}
                resourceErpIdsSuccessMessage={resourceErpIdsSuccessMessage}
              />
            )}
          </Map>
        </Div>

        {gridCell.day.isLastDayOfMonth && (
          <SeparatorLine backgroundColor="tableBorder" occupyVerticalSpace />
        )}
      </Flex>
    </Div>
  );
};

export default decorate(observer)(ResourceDayCell);
