import React from 'react';

import Div from '../Element/Div/Div';
import styles from './MarginSpace.module.scss';

function MarginSpace({
  compact,
  padding = {},
  noPaddingForSmallerThanMd,
  noPaddingForSmallerThanSm,
  className,
  children,
  ...props
}) {
  const classNames = [
    styles.marginSpace,
    {
      [styles.marginSpace__noPaddingForSmallerThanMd]: noPaddingForSmallerThanMd,
      [styles.marginSpace__noPaddingForSmallerThanSm]: noPaddingForSmallerThanSm,
      [styles.marginSpace__compact]: compact,
    },
    className,
  ];

  const paddingWithHorizontalDefault = {
    horizontal: 'md',
    ...padding,
  };

  return (
    <Div
      padding={paddingWithHorizontalDefault}
      className={classNames}
      {...props}
    >
      {children}
    </Div>
  );
}

export default MarginSpace;
