import parameterInputMapperFor from 'shared-between-everything/src/doings/parameterInputMapperFor/parameterInputMapperFor';
import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

const callForModifyingNotificationStatusOfWorkOrder = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/work-order/{workOrderId}/modify-notification',
    method: 'POST',
    inputMapper: parameterInputMapperFor({
      pathParameters: {
        workOrderId: 'workOrderId',
      },

      bodyParameters: {
        notification: 'notification',
      },
    }),
  },
  decoratorConfig: {
    spinnerName: 'modifying-notification-status-of-work-order',
    showSpinnerInstantly: false,
  },
});

export default callForModifyingNotificationStatusOfWorkOrder;
