export const getIfsUrl = (workOrderErpId, userAgent) => {
  if (userAgent.match(/edg/i)) {
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    return `http://enas.eltelnetworks.se/client/runtime/Ifs.Fnd.Explorer.application?url=ifsapf%3AfrmWorkOrderReportInTab%3Faction%3Dget%26key1%3D${workOrderErpId}%26COMPANY%3D37`;
  } else if (userAgent.match(/chrome|chromium|crios/i)) {
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
    return `microsoft-edge:http://enas.eltelnetworks.se/client/runtime/Ifs.Fnd.Explorer.application?url=ifsapf%3AfrmWorkOrderReportInTab%3Faction%3Dget%26key1%3D${workOrderErpId}%26COMPANY%3D37`;
  } else {
    return 'unknown';
  }
};
