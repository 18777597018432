import { observer } from 'mobx-react';
import React from 'react';

import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import RouteLink from 'shared-between-front-ends/src/components/public/RouteLink/RouteLink';
import Title from 'shared-between-front-ends/src/components/public/Title/Title';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';

import resourceTranslations from '../../../../resource/resourceTranslations';

const translate = localTranslate(resourceTranslations);

const ResourceRowHeader = ({
  resource: {
    id: resourceId,
    backgroundColor,
    firstName,
    lastName,
    competences,
  },
  teamId,
  districtId,
}) => {
  const notUpdatedCompetences = translate('competenceUpdate');
  return (
    <RouteLink
      block
      backgroundColor={backgroundColor}
      routeName="district-team-update-resource"
      pathParameters={{
        districtId,
        resourceId,
        teamId,
      }}
      padding={{
        vertical: 'xs',
        horizontal: 'md',
      }}
      occupyVerticalSpace
      data-update-resource-test
    >
      <Flex occupyVerticalSpace pileVertically>
        <Title singleLine>
          {lastName}, {firstName}
        </Title>
        <BodyText singleLine size="3xs" color="fontColor">
          {competences ? competences : notUpdatedCompetences}
        </BodyText>
      </Flex>
    </RouteLink>
  );
};

export default decorate(observer)(ResourceRowHeader);
