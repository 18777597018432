const callForSubmitEffortEntriesInputMapper = ({
  appointmentId,
  resourceId,
  effortEntries,
  modifiedTimestamp,
}) => ({
  pathParameters: { appointmentId, resourceId },
  bodyParameters: { effortEntries, modifiedTimestamp },
});

export default callForSubmitEffortEntriesInputMapper;
