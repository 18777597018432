import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import MarginSpace from 'shared-between-front-ends/src/components/private/MarginSpace/MarginSpace';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Form from 'shared-between-front-ends/src/components/public/Form/Form';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Loader from 'shared-between-front-ends/src/components/public/Loader/Loader';
import Map from 'shared-between-front-ends/src/components/public/Map/Map';
import Page from 'shared-between-front-ends/src/components/public/Page/Page';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import Popover from 'shared-between-front-ends/src/components/public/Popover/Popover';
import PrimaryButton from 'shared-between-front-ends/src/components/public/PrimaryButton/PrimaryButton';
import SecondaryButton from 'shared-between-front-ends/src/components/public/SecondaryButton/SecondaryButton';
import SelectionInput from 'shared-between-front-ends/src/components/public/SelectionInput/SelectionInput';
import SemanticButton from 'shared-between-front-ends/src/components/public/SemanticButton/SemanticButton';
import TextInput from 'shared-between-front-ends/src/components/public/TextInput/TextInput';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import StripedBox from '../../../shared/StripedBox/StripedBox';
import SimpleNavigation from '../../SimpleNavigation/SimpleNavigation';
import resourceDashboardTranslations from '../resourceDashboardTranslations';
import EffortDescriptionPopover from './EffortDescriptionPopover/EffortDescriptionPopover';
import InteractiveEffort from './InteractiveEffort/InteractiveEffort';

const translate = localTranslate(resourceDashboardTranslations);

const AppointmentEffortEntries = ({ model }) => (
  <Page
    compact
    segmented
    data-appointment-effort-entries-test={model.appointmentId}
    backgroundColor="lightGrey"
  >
    <Loader unless={() => model.dataIsAvailable} padding={{ size: 'md' }}>
      {() => (
        <>
          <Page.Header>
            <SimpleNavigation
              currentResourceName={model.currentResourceName}
              teamName={model.teamName}
            />
          </Page.Header>

          <Page.Body>
            <Page.Row>
              <Flex centeredVertically>
                <SemanticButton
                  onClick={model.returnToResourceDashboard}
                  color="action"
                  data-return-to-resource-dashboard-test
                  data-return-to-resource-dashboard-e2e-test
                >
                  <Flex centeredVertically>
                    <Icon name="chevron-left" size="sm" />

                    <Gutter size="xs" />

                    <BodyText>{model.returnToResourceDashboardString}</BodyText>
                  </Flex>
                </SemanticButton>

                <Gutter flexItem size="sm" />

                <Popover
                  activationId="report-updated-notice-pop-over"
                  activateOn="manual"
                  position="vertical center of screen"
                  content={
                    <MarginSpace compact>
                      <Panel>
                        <Flex pileVertically>
                          <Flex
                            centered
                            color="white"
                            backgroundColor="modalHeader"
                            padding={{ size: 'sm' }}
                          >
                            <Icon name="screwdriver-wrench" compact />
                            <Gutter size="xs" />
                            <BodyText>{translate('reportUpdated')}</BodyText>
                          </Flex>

                          <Panel.Content padding={{ size: 'lg' }}>
                            <Flex pileVertically centered>
                              <BodyText>
                                {translate('reportUpdatedText')}
                              </BodyText>
                              <Gutter size="md" />
                              <PrimaryButton onClick={model.reloadPage}>
                                {translate('reloadPage')}
                              </PrimaryButton>
                            </Flex>
                          </Panel.Content>
                        </Flex>
                      </Panel>
                    </MarginSpace>
                  }
                />

                <Popover
                  activationId="adding-of-effort-definition-pop-over"
                  activateOn="manual"
                  position="vertical center of screen"
                  content={
                    <MarginSpace compact>
                      <Panel>
                        <Flex pileVertically height="xlgOrViewport">
                          <Flex
                            centered
                            color="white"
                            backgroundColor="modalHeader"
                            padding={{ size: 'sm' }}
                          >
                            <Icon name="screwdriver-wrench" compact />

                            <Gutter size="xs" />

                            <BodyText>{translate('addManualEffort')}</BodyText>
                          </Flex>

                          <Panel.Content padding={{ size: 'lg' }}>
                            <Loader unless={() => model.catalogsAreAvailable}>
                              {() => (
                                <>
                                  <SelectionInput
                                    autoFocus
                                    label={translate('selectCatalog')}
                                    block
                                    data-effort-definition-catalog-input-test
                                    selectionInputName={'catalogs'}
                                    model={model.newEffortDefinitionCatalog}
                                    data-catalogs-e2e-test={
                                      model.newEffortDefinitionCatalog
                                        .valueOptions.length - 1
                                    }
                                  />

                                  <Gutter size="lg" />

                                  <TextInput
                                    label={translate('selectCatalogItem')}
                                    model={model.catalogItemSearchInput}
                                    data-catalog-item-search-string-test
                                    data-catalog-item-input-e2e-test
                                  />
                                </>
                              )}
                            </Loader>
                          </Panel.Content>

                          <Panel.Content
                            scrollOverflow
                            flexItem
                            padding={{
                              top: 'zero',
                              size: 'sm',
                            }}
                          >
                            <Map
                              items={model.catalogItemSelectionOptions}
                              data-catalog-item-options-test
                              separator={<Gutter size="xs" />}
                            >
                              {({ name, id, catalogItem }) => (
                                <SemanticButton
                                  onClick={() =>
                                    model.setCatalogItem(catalogItem)
                                  }
                                  selected={model.isCatalogItemSelected(
                                    catalogItem,
                                  )}
                                  data-catalog-item-row-test
                                  data-catalog-item-id-test={id}
                                  data-catalog-item-e2e-test
                                  occupyHorizontalSpace
                                >
                                  <StripedBox>
                                    <Div
                                      padding={{
                                        size: 'xs',
                                      }}
                                    >
                                      <HeavyText>{name}</HeavyText>

                                      <EffortDescriptionPopover
                                        {...model.toCatalogItemWithPopover(
                                          catalogItem,
                                        )}
                                        catalogItem={catalogItem}
                                        {...catalogItem}
                                      >
                                        <Icon
                                          name="circle-info"
                                          size="md"
                                          color="action"
                                          alt={name}
                                        />
                                      </EffortDescriptionPopover>
                                    </Div>
                                  </StripedBox>
                                </SemanticButton>
                              )}
                            </Map>
                          </Panel.Content>

                          <Form
                            occupyHorizontalSpace
                            onSubmit={model.addEffortDefinition}
                            data-add-effort-definition-form-test
                          >
                            <Flex centeredVertically margin={{ size: 'md' }}>
                              <SecondaryButton
                                onClick={model.cancelAddingOfManualEffort}
                                data-cancel-adding-of-manual-effort-test
                                flexItem
                              >
                                {translate('cancel')}
                              </SecondaryButton>

                              <Gutter size="md" />

                              <PrimaryButton
                                enabled={
                                  model.newEffortDefinitionCanBeSubmitted
                                }
                                type="submit"
                                flexItem
                                data-submit-add-effort-definition-form-test
                                data-add-effort-e2e-test
                              >
                                {translate('addManualEffortShort')}
                              </PrimaryButton>
                            </Flex>
                          </Form>
                        </Flex>
                      </Panel>
                    </MarginSpace>
                  }
                  data-adding-of-effort-definition-pop-over-test
                  data-pop-over-for-adding-effort-definition-e2e-test
                >
                  <SecondaryButton
                    size="sm"
                    onClick={model.openPopOverForAddingEffortDefinition}
                    data-open-adding-of-effort-definition-test
                    data-add-effort-definition-e2e-test
                  >
                    <Flex centered>
                      <Icon name="plus" compact />

                      <Gutter size="xs" />

                      {translate('addManualEffort')}
                    </Flex>
                  </SecondaryButton>
                </Popover>
              </Flex>
            </Page.Row>

            <Page.Row
              shown={model.issuedEffortsAreShown}
              data-show-issued-efforts-test
            >
              <Flex centeredVertically>
                <Icon name="screwdriver-wrench" size="sm" />

                <Gutter size="xs" />

                <HeavyText color="primary">
                  {translate('issuedEfforts')}
                </HeavyText>
              </Flex>
            </Page.Row>

            <Div margin={{ bottom: 'md' }}>
              <Map items={model.issuedEfforts} separator={<Gutter size="sm" />}>
                {model => <InteractiveEffort type="issued" model={model} />}
              </Map>
            </Div>

            <Page.Row
              shown={model.manualEffortsAreShown}
              data-show-manual-efforts-test
            >
              <Flex centeredVertically>
                <Icon name="screwdriver-wrench" size="sm" />

                <Gutter size="xs" />

                <HeavyText color="primary">
                  {translate('manualEfforts')}
                </HeavyText>
              </Flex>
            </Page.Row>

            <Div>
              <Map
                items={model.newManualEfforts}
                separator={<Gutter size="sm" />}
              >
                {model => <InteractiveEffort type="manual" model={model} />}
              </Map>

              <Gutter
                shown={model.spaceBetweenNewAndOldManualEffortsNeedsGutter}
                size="sm"
              />

              <Map items={model.manualEfforts} separator={<Gutter size="sm" />}>
                {model => <InteractiveEffort type="manual" model={model} />}
              </Map>
            </Div>

            <Page.Row margin={false} shown={!model.hasEfforts}>
              <BodyText>{translate('noEfforts')}</BodyText>
            </Page.Row>
          </Page.Body>

          <Page.Footer padding={{ size: 'sm' }} backgroundColor="white">
            <Form
              shown={model.hasEfforts}
              onSubmit={model.submitEffortEntries}
              data-submit-report-test
            >
              <Flex centered>
                <SecondaryButton
                  width="md"
                  data-submit-report-test
                  data-save-e2e-test
                  boxShadow={{ size: 'lg' }}
                  hidden
                  type="submit"
                >
                  {translate('submitEffortEntries')}
                </SecondaryButton>
              </Flex>
            </Form>

            <Form
              shown={model.hasEfforts}
              data-submit-close-report-test
              onSubmit={model.submitEffortEntriesAndClose}
            >
              <Flex centered>
                <SecondaryButton
                  width="md"
                  data-submit-close-report-test
                  data-save-close-e2e-test
                  boxShadow={{ size: 'lg' }}
                  type="submit"
                >
                  {translate('submitAndClose')}
                </SecondaryButton>
              </Flex>
            </Form>
          </Page.Footer>
        </>
      )}
    </Loader>
  </Page>
);

export default decorate(observer)(AppointmentEffortEntries);
