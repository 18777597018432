/* istanbul ignore file */
import { action, computed, observable, reaction } from 'mobx';
import moment from 'moment-timezone';
import getRandomIdImport from 'shared-between-everything/src/doings/getRandomId/getRandomId';
import pipeline from 'shared-between-everything/src/doings/pipeline/pipeline';
import { map } from 'shared-between-everything/src/functionalProgramming';
import BooleanInputModel from 'shared-between-front-ends/src/components/public/CheckboxInput/BooleanInputModel';
import NotificationsModel from 'shared-between-front-ends/src/components/public/Notifications/NotificationsModel';
import ActivationModel from 'shared-between-front-ends/src/components/public/Popover/ActivationModel/ActivationModel';
import TextInputModel from 'shared-between-front-ends/src/components/public/TextInput/TextInputModel';
import when from 'shared-between-front-ends/src/decorators/when/when';
import whenRouteChangesTo from 'shared-between-front-ends/src/decorators/whenRouteChangesTo/whenRouteChangesTo';
import withDebounce from 'shared-between-front-ends/src/decorators/withDebounce/withDebounce';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import injectInstance from 'shared-between-front-ends/src/decorators/withModel/injectInstance';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import getForMapsAndObjects from 'shared-between-front-ends/src/doings/nested-map-and-object/getForMapsAndObjects/getForMapsAndObjects';
import setForMapsAndObjects from 'shared-between-front-ends/src/doings/nested-map-and-object/setForMapsAndObjects/setForMapsAndObjects';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import SessionModel from 'shared-between-front-ends/src/models/SessionModel/SessionModel';
import observed from 'shared-between-front-ends/src/observed';
import resourceDataValidator from 'shared-between-front-ends/src/validators/resourceDataValidator/resourceDataValidator';
import sendResourceDataToDataVault from '../../../resource/SendResourceDataToIFS/sendResourceDataToIFS';
import schedulerTranslations from '../../schedulerTranslations';
import GridCellModel from '../GridCellModel/GridCellModel';
import getWorkOrdersImport from '../getWorkOrders/getWorkOrders';
import AppointmentModel from './AppointmentModel/AppointmentModel';
import callForCancelAppointmentImport from './AppointmentModel/callForCancelAppointment/callForCancelAppointment';
import callForModifyAppointmentImport from './AppointmentModel/callForModifyAppointment/callForModifyAppointment';
import DataPopulationModel from './DataPopulationModel/DataPopulationModel';
import DateScopeModel from './DateScopeModel/DateScopeModel';
import ResourceModel from './ResourceModel/ResourceModel';
import WorkOrderListModel from './WorkOrderListModel/WorkOrderListModel';
import WorkOrderModel from './WorkOrderModel/WorkOrderModel';
import callForModifyingDoneStatusOfWorkOrderImport from './callForModifyingDoneStatusOfWorkOrder/callForModifyingDoneStatusOfWorkOrder';
import callForModifyingLunchStatusOfAppointmentImport from './callForModifyingLunchStatusOfAppointment/callForModifyingLunchStatusOfAppointment';
import callForModifyingNightShiftStatusOfAppointmentImport from './callForModifyingNightShiftStatusOfAppointment/callForModifyingNightShiftStatusOfAppointment';
import callForModifyingNotificationStatusOfWorkOrderImport from './callForModifyingNotificationStatusOfWorkOrder/callForModifyingNotificationStatusOfWorkOrder';
import callForModifyingUrgencyOfWorkOrderImport from './callForModifyingUrgencyOfWorkOrder/callForModifyingUrgencyOfWorkOrder';
import callForReorderingOfResourcesImport from './callForReorderingOfResources/callForReorderingOfResources';
import callForResourcesInTeamImport from './callForResourcesInTeam/callForResourcesInTeam';
import callForSendingOfNotificationEmailsImport from './callForSendingOfNotificationEmails/callForSendingOfNotificationEmails';
import createAppointmentImport from './createAppointment/createAppointment';
import getAppointmentsImport from './getAppointments/getAppointments';
import getDayIsNationalHolidayImport from './getDayIsNationalHoliday/getDayIsNationalHoliday';
import { reorderAbove, reorderBelow } from './reorder/reorder';

const translate = localTranslate(schedulerTranslations);

export default class SchedulerModel {
  dependencies = {};

  constructor({
    activationModel = getModel(ActivationModel),
    callForCancelAppointment = callForCancelAppointmentImport,
    callForModifyAppointment = callForModifyAppointmentImport,
    callForModifyingDoneStatusOfWorkOrder = callForModifyingDoneStatusOfWorkOrderImport,
    callForModifyingUrgencyOfWorkOrder = callForModifyingUrgencyOfWorkOrderImport,
    callForModifyingNotificationStatusOfWorkOrder = callForModifyingNotificationStatusOfWorkOrderImport,
    callForModifyingNightShiftStatusOfAppointment = callForModifyingNightShiftStatusOfAppointmentImport,
    callForModifyingLunchStatusOfAppointment = callForModifyingLunchStatusOfAppointmentImport,
    callForReorderingOfResources = callForReorderingOfResourcesImport,
    callForResourcesInTeam = callForResourcesInTeamImport,
    callForSendingOfNotificationEmails = callForSendingOfNotificationEmailsImport,
    createAppointment = createAppointmentImport,
    getAppointments = getAppointmentsImport,
    getDayIsNationalHoliday = getDayIsNationalHolidayImport,
    getRandomId = getRandomIdImport,
    getWorkOrders = getWorkOrdersImport,
    maximumAmountOfListedWorkOrders = 100,
    notificationsModel = getModel(NotificationsModel),
    routingModel = getModel(RoutingModel),
    sessionModel = getModel(SessionModel),
    sendResourceDataToIFS = sendResourceDataToDataVault,
    performResourceDataValidation = resourceDataValidator,
  } = {}) {
    this.dependencies.activationModel = activationModel;
    this.dependencies.callForCancelAppointment = callForCancelAppointment;
    this.dependencies.callForReorderingOfResources = callForReorderingOfResources;
    this.dependencies.callForModifyAppointment = callForModifyAppointment;
    this.dependencies.callForResourcesInTeam = callForResourcesInTeam;
    this.dependencies.callForSendingOfNotificationEmails = callForSendingOfNotificationEmails;
    this.dependencies.createAppointment = createAppointment;
    this.dependencies.getAppointments = getAppointments;
    this.dependencies.getDayIsNationalHoliday = getDayIsNationalHoliday;
    this.dependencies.getRandomId = getRandomId;
    this.dependencies.getWorkOrders = getWorkOrders;
    this.dependencies.maximumAmountOfListedWorkOrders = maximumAmountOfListedWorkOrders;
    this.dependencies.notificationsModel = notificationsModel;
    this.dependencies.routingModel = routingModel;
    this.dependencies.sessionModel = sessionModel;
    this.dependencies.callForModifyingUrgencyOfWorkOrder = callForModifyingUrgencyOfWorkOrder;
    this.dependencies.callForModifyingDoneStatusOfWorkOrder = callForModifyingDoneStatusOfWorkOrder;
    this.dependencies.callForModifyingNotificationStatusOfWorkOrder = callForModifyingNotificationStatusOfWorkOrder;
    this.dependencies.callForModifyingNightShiftStatusOfAppointment = callForModifyingNightShiftStatusOfAppointment;
    this.dependencies.callForModifyingLunchStatusOfAppointment = callForModifyingLunchStatusOfAppointment;
    this.dependencies.performResourceDataValidation = performResourceDataValidation;
    this.dependencies.sendResourceDataToIFS = sendResourceDataToIFS;

    this.dataPopulationModel = new DataPopulationModel(
      getAppointments,
      callForResourcesInTeam,
      getWorkOrders,
      this.createWorkOrder,
      this.createAppointment,
      this.createResource,
      this.dependencies.sessionModel,
    );

    this.showScheduledWorkOrders = new BooleanInputModel({
      defaultValue: false,
    });

    this.workOrderListModel = new WorkOrderListModel(
      routingModel,
      sessionModel,
      this.workOrdersById,
      this.dependencies.maximumAmountOfListedWorkOrders,
      this.appointmentsById,
      this.showScheduledWorkOrders,
    );

    this.dateScopeModel = new DateScopeModel(
      routingModel,
      getDayIsNationalHoliday,
    );

    this.goToDate = new TextInputModel({
      required: false,
      defaultValue: moment().format('YYYY-WW'),
    });
  }

  @when(observed('goToDate.value'))
  @withDebounce(1000)
  debouncedUpdate = () => {
    if (moment(this.goToDate.value).isValid()) {
      this.goToWeekOfSelectedDate(this.goToDate.value);
    }
  };

  @computed
  get showWeekNumberLabels() {
    return this.dateScopeModel.showWeekNumberLabels;
  }

  @computed
  get showDayOfWeekString() {
    return this.dateScopeModel.showDayOfWeekString;
  }

  @computed
  get showDayOfMonthString() {
    return this.dateScopeModel.showDayOfMonthString;
  }

  @computed
  get allowChangingViewToAbsenceScheduler() {
    return !!this.dependencies.sessionModel.userRights.seeTeamAbsenceScheduler;
  }

  changeViewToAbsenceScheduler = () => {
    this.dependencies.routingModel.setRouteTo({
      name: 'district-team-absence-scheduler',
      pathParameters: {
        districtId: this.districtId,
        teamId: this.teamId,
      },
      queryParameters: this.dependencies.routingModel.queryParameters,
    });
  };

  @computed
  get showWeekendAsSelectable() {
    return this.dateScopeModel.showWeekendAsSelectable;
  }

  sendNotificationEmails = async () => {
    const {
      response: outcomeOfSentEmails,
    } = await this.dependencies.callForSendingOfNotificationEmails({
      teamId: this.teamId,
    });

    const numberOfSentEmails = outcomeOfSentEmails.successes.length;

    if (numberOfSentEmails) {
      this.dependencies.notificationsModel.setSuccess(
        translate('notificationEmailsGotSent', {
          numberOfSentEmails,
        }),

        {
          'data-number-of-sent-emails-e2e-test': numberOfSentEmails,
        },
      );
    } else {
      this.dependencies.notificationsModel.setSuccess(
        translate('noNotificationEmailsGotSent'),

        {
          'data-notification-for-no-sent-emails-e2e-test': true,
        },
      );
    }
  };

  @computed
  get teamId() {
    return this.dependencies.routingModel.pathParameters.teamId;
  }

  @computed
  get weekendsAreShown() {
    return this.dateScopeModel.weekendsAreShown;
  }

  @computed
  get weeksInView() {
    return this.dateScopeModel.weeksInView;
  }

  @computed
  get showNumberOfWeeksOptions() {
    return this.dateScopeModel.showNumberOfWeeksOptions;
  }

  @computed
  get showWeekendsOptions() {
    return this.dateScopeModel.showWeekendsOptions;
  }

  setNumberOfWeeksShown = weeks => {
    this.dateScopeModel.setNumberOfWeeksShown(weeks);
  };

  @computed
  get selectedWorkOrderCategory() {
    return this.workOrderListModel.selectedWorkOrderCategory;
  }

  @computed
  get workOrderListFilterInput() {
    return this.workOrderListModel.filterStringInput;
  }

  @computed
  get listedWorkOrders() {
    return this.workOrderListModel.listedWorkOrders;
  }

  @computed
  get daysInView() {
    return this.dateScopeModel.daysInView;
  }

  showWeekends = show => {
    this.dateScopeModel.showWeekends(show);
  };

  changeListedWorkOrders = type => {
    this.workOrderListModel.changeListedWorkOrders(type);
  };

  @computed
  get daysOfSelectedDateRange() {
    return this.dateScopeModel.daysOfSelectedDateRange;
  }

  @computed
  get monthsOfSelectedDateRange() {
    return this.dateScopeModel.monthsOfSelectedDateRange;
  }

  @computed
  get yearStringOfSelectedDateRange() {
    return this.dateScopeModel.yearStringOfSelectedDateRange;
  }

  @computed
  get selectedDateRange() {
    return this.dateScopeModel.selectedDateRange;
  }

  @observable updatedTimeStamp = 0;

  @computed
  get _resources() {
    return [...this.resourcesById.values()];
  }

  @computed
  get resourceRows() {
    return pipeline(
      this._resources,
      map(resource =>
        observable({
          resourceId: resource.id,
          days: this.daysOfSelectedDateRange.map(({ ...day }) => ({
            ...day,
            resourceId: resource.id,
            resourceIdAndDate: `${resource.id}/${day.date}`,
            gridCell: this.createGridCell({
              resource,
              day,
            }),
          })),

          dropAbove: this._dropFor(reorderAbove),
          dropBelow: this._dropFor(reorderBelow),

          orientationOfStripe: null,

          setOrientationOfStripe: action(function (dragOrientation) {
            this.orientationOfStripe = dragOrientation;
          }),
        }),
      ),
    );
  }

  _dropFor = reorder => async ({
    resourceIdToBeMoved,
    resourceIdForReference,
  }) => {
    const resourceIdsInOrder = pipeline(
      this._resources,
      map('id'),
      reorder(resourceIdForReference, resourceIdToBeMoved),
    );

    await this.dependencies.callForReorderingOfResources({
      teamId: this.teamId,
      resourceIdsInOrder: resourceIdsInOrder,
    });

    this.refresh({
      teamId: this.teamId,
    });
  };

  goBackToCurrentWeek = () => {
    this.dateScopeModel.goBackToCurrentWeek();
    this.goToDate.setValue(null);
  };

  goToWeekOfSelectedDate = selectedDate => {
    this.dateScopeModel.goToWeekOfSelectedDate(selectedDate);
  };

  goToNextWeek = () => {
    this.dateScopeModel.goForwardWeeks(1);
  };

  goToPreviousWeek = () => {
    this.dateScopeModel.goForwardWeeks(-1);
  };

  @observable selectedWorkOrderId = null;

  @action
  setSelectedWorkOrderId = workOrderId => {
    this.selectedWorkOrderId = workOrderId;
  };

  @action
  clearWorkOrderSelection = () => {
    this.selectedWorkOrderId = null;
    this.selectedHours = null;
  };

  @observable selectedHours = null;

  @observable selectedStartTime = null;

  @observable selectedEndTime = null;

  @observable selectedNightShiftStatus = null;

  @action
  setSelectedHours = hours => {
    this.selectedHours = hours;
  };

  @action
  setSelectedStartTime = startTime => {
    this.selectedStartTime = startTime;
  };

  @action
  setSelectedEndTime = endTime => {
    this.selectedEndTime = endTime;
  };

  @action
  setSelectedNightShiftStatus = nightShiftStatus => {
    this.selectedNightShiftStatus = nightShiftStatus;
  };

  @computed
  get buttonForClearingWorkOrderSelectionIsEnabled() {
    return !!this.selectedWorkOrderId;
  }

  @whenRouteChangesTo('district-team-scheduler')
  refresh = () => {
    this.dataPopulationModel.refresh({
      teamId: this.teamId,
    });
  };

  createWorkOrder = ({ workOrderData }) =>
    new WorkOrderModel({
      workOrderData,
      schedulerModel: this,
      setSelectedWorkOrderId: this.setSelectedWorkOrderId,
      callForModifyingUrgencyOfWorkOrder: this.dependencies
        .callForModifyingUrgencyOfWorkOrder,
      callForModifyingDoneStatusOfWorkOrder: this.dependencies
        .callForModifyingDoneStatusOfWorkOrder,
      callForModifyingNotificationStatusOfWorkOrder: this.dependencies
        .callForModifyingNotificationStatusOfWorkOrder,
    });

  createGridCell = ({ resource, day }) =>
    new GridCellModel(
      this.scheduleWorkOrderToGridCell,
      this,
      resource,
      day,
      this.gridData,
      this.appointmentsById,
    );

  createAppointment = appointmentData => {
    const appointmentModel = injectInstance(
      AppointmentModel,
      this.dependencies.callForModifyingLunchStatusOfAppointment,
      this.dependencies.callForModifyingNightShiftStatusOfAppointment,
      this.dependencies.sessionModel.userRights,
      this.dependencies.createAppointment,
      this.dependencies.callForModifyAppointment,
      this.workOrdersById,
      this,
      this.dependencies.activationModel,
      this.dependencies.routingModel,
      this.dependencies.callForCancelAppointment,
      appointmentData,
      () => {
        this.updatedTimeStamp = Date.now();
      },
    );

    const startReactiveIndexing = startReactiveIndexingFor(
      this.gridData,
      this.appointmentsById,
    );

    startReactiveIndexing(appointmentModel);

    return appointmentModel;
  };

  createResource = ({ resourceData }) => new ResourceModel(resourceData);

  @computed
  get districtId() {
    return this.dependencies.routingModel.pathParameters.districtId;
  }

  @computed
  get userRights() {
    return this.dependencies.sessionModel.userRights;
  }

  @action
  scheduleWorkOrderToGridCell = ({
    workOrderId,
    resourceId,
    date,
    startTime,
    endTime,
    nightShift,
  }) => {
    const startTimeHour = (startTime && startTime.split(':')[0]) || '00';
    const startTimeMinute = (startTime && startTime.split(':')[1]) || '00';

    const endTimeHour = (endTime && endTime.split(':')[0]) || '00';
    const endTimeMinute = (endTime && endTime.split(':')[1]) || '00';

    const nightShiftStatus = nightShift || null;

    const appointmentData = {
      id: this.dependencies.getRandomId(),
      resourceId,
      teamId: this.teamId,
      workOrderId,
      info: '',
      lunch: null,
      startDateTime: moment(date)
        .hour(startTimeHour)
        .minute(startTimeMinute)
        .second(0)
        .utc()
        .format(),
      endDateTime: moment(date)
        .hour(endTimeHour)
        .minute(endTimeMinute)
        .second(0)
        .utc()
        .format(),
      nightShift: nightShiftStatus,
    };

    const appointment = this.createAppointment(appointmentData);

    appointment.createInBackend();
  };

  @observable isResourceErpIdsSent = false;

  @observable resourceErpIdsSuccessMessage = null;

  checkIfResourceDataIsValid = userData =>
    this.dependencies.performResourceDataValidation(userData);

  @action
  setIsResourceErpIdsSent = () => {
    if (
      this.isResourceErpIdsSent === false &&
      this.resourceErpIdsSuccessMessage !== null
    ) {
      this.isResourceErpIdsSent = true;
    } else {
      this.isResourceErpIdsSent = false;
      this.resourceErpIdsSuccessMessage = null;
    }
  };

  @action
  setResourceErpIdsSuccessMessage = message =>
    (this.resourceErpIdsSuccessMessage = message);

  sendUsersDataToIFS = async ({ resourceErpId, workOrderErpId }) => {
    const {
      callWasSuccessful,
      response: { message },
    } = await this.dependencies.sendResourceDataToIFS({
      resourceErpId: resourceErpId,
      workOrderErpId: workOrderErpId,
    });

    if (callWasSuccessful) {
      if (message === 'ok') {
        this.setResourceErpIdsSuccessMessage('sentSuccessfullyToIFS');
      } else {
        this.setResourceErpIdsSuccessMessage('resourceErpIdNotRegistered');
      }
    } else {
      this.setResourceErpIdsSuccessMessage('resourceErpIdCallNotSuccessful');
    }

    this.setIsResourceErpIdsSent();
  };

  @computed get resourcesById() {
    return this.dataPopulationModel.resourcesById;
  }

  @computed get workOrdersById() {
    return this.dataPopulationModel.workOrdersById;
  }

  @computed get appointmentsById() {
    return this.dataPopulationModel.appointmentsById;
  }

  @observable
  gridData = new Map();
}

const startReactiveIndexingFor = (
  gridData,
  appointmentsById,
) => appointmentModel => {
  let oldResourceId;
  let oldDate;

  reaction(
    () => ({
      newResourceId: appointmentModel.resourceId,
      newDate: appointmentModel.date,
    }),
    ({ newResourceId, newDate }) => {
      const appointmentIsBeingDeleted = !newResourceId || !newDate;
      const appointmentIsBeingRelocated = oldResourceId && oldDate;

      if (appointmentIsBeingRelocated || appointmentIsBeingDeleted) {
        getForMapsAndObjects(
          gridData,
          `resources.${oldResourceId}.days.${oldDate}.appointments`,
        ).delete(appointmentModel.id);
      }

      if (appointmentIsBeingDeleted) {
        appointmentsById.delete(appointmentModel.id, appointmentModel);
      } else {
        setForMapsAndObjects(
          gridData,
          `resources.${newResourceId}.days.${newDate}.appointments.${appointmentModel.id}`,
          appointmentModel,
        );

        appointmentsById.set(appointmentModel.id, appointmentModel);
      }

      oldResourceId = newResourceId;
      oldDate = newDate;
    },
    { fireImmediately: true },
  );
};
