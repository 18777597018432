import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

const submitUpdateResource = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/resources/{resourceId}',
    method: 'PUT',
  },
  decoratorConfig: {
    spinnerName: 'updating-resource',
    showSpinnerInstantly: true,
  },
});

export default submitUpdateResource;
