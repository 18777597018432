import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Form from 'shared-between-front-ends/src/components/public/Form/Form';
import MainHeading from 'shared-between-front-ends/src/components/public/MainHeading/MainHeading';
import Page from 'shared-between-front-ends/src/components/public/Page/Page';
import withModel from 'shared-between-front-ends/src/decorators/withModel/withModel';
import SpinnerTentative from 'shared-between-front-ends/src/decorators/withSpinnerFor/SpinnerTentative/SpinnerTentative';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import Navigation from '../../../Navigation/Navigation';
import resourceTranslations from '../../../resource/resourceTranslations';
import CreateTeamResourceModel from './CreateTeamResourceModel';
import ResourceForm from './ResourceForm';
import SpecialResourceForm from './SpecialResourceForm';

const translate = localTranslate(resourceTranslations);

const CreateTeamResource = ({
  erpId,
  firstName,
  lastName,
  phoneNumber,
  emailAddress,
  competences,
  role,
  submit,
  isValid,
  isValidForSpecialResource,
  isSpecialResource,
}) => {
  return (
    <Page data-create-resource-page-e2e-test>
      <Navigation />

      <Page.Row alignWithPanel margin={{ bottom: 'md' }}>
        <MainHeading>{translate('createResource')}</MainHeading>
      </Page.Row>

      <SpinnerTentative
        spinnerName="creating-resource"
        data-create-resource-spinner-test
      >
        <Form onSubmit={submit}>
          {!isSpecialResource && (
            <ResourceForm
              erpId={erpId}
              firstName={firstName}
              lastName={lastName}
              phoneNumber={phoneNumber}
              emailAddress={emailAddress}
              competences={competences}
              role={role}
              isValid={isValid}
            />
          )}

          {isSpecialResource && (
            <SpecialResourceForm
              firstName={firstName}
              lastName={lastName}
              role={role}
              isValidForSpecialResource={isValidForSpecialResource}
            />
          )}
        </Form>
      </SpinnerTentative>
    </Page>
  );
};

export default decorate(
  withModel({
    Model: CreateTeamResourceModel,
    props: [
      {
        name: 'erpId',
        modelPath: 'erpId',
      },
      {
        name: 'firstName',
        modelPath: 'firstName',
      },
      {
        name: 'lastName',
        modelPath: 'lastName',
      },
      {
        name: 'phoneNumber',
        modelPath: 'phoneNumber',
      },
      {
        name: 'emailAddress',
        modelPath: 'emailAddress',
      },
      {
        name: 'competences',
        modelPath: 'competences',
      },
      {
        name: 'role',
        modelPath: 'role',
      },
      {
        name: 'submit',
        modelPath: 'submit',
      },
      {
        name: 'isValid',
        modelPath: 'isValid',
      },
      {
        name: 'isValidForSpecialResource',
        modelPath: 'isValidForSpecialResource',
      },
      {
        name: 'isSpecialResource',
        modelPath: 'isSpecialResource',
      },
    ],
  }),
)(CreateTeamResource);
