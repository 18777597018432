export default {
  en: {
    required: 'Required',
    minLength: 'At least {length} characters are required',
    maxLength: 'At most {length} characters are permitted',
    missingUpperCaseCharacter: 'At least one upper case character is required',
    missingLowerCaseCharacter: 'At least one lower case character is required',
    missingDigit: 'At least one number is required',
    invalidCharacter: 'Invalid characters',
    invalidCharacterSequence: 'Eg. 123 and abc are not permitted',
    passwordsNotMatching: 'Passwords do not match',
    passwordsAreTheSame: 'New password must be different from old password',
    invalidEmailAddressFormat: 'Given email is not valid',
    noInternationalPrefix: 'International prefix (eg. +46) is missing',
    invalidPostalCode: 'Given postal code is not valid',
    invalidInteger: 'Given character should be an integer',
    isOneOf: 'Not one of "{values}"',
    isNumber: 'Not a number',
    isPositiveNumber: 'Number should be at least 0',
    invalidUrl: 'Given URL is not valid',
    disallowedFileExtension:
      'File extension "{fileExtension}" is not allowed. Allowed file extensions are {allowedFileExtensions}',
  },
  fi: {
    required: 'Pakollinen',
    minLength: 'Vähintään {length} merkkiä vaadittu',
    maxLength: 'Korkeintaan {length} merkkiä sallittu',
    missingUpperCaseCharacter: 'Vähintään yksi iso kirjain vaadittu',
    missingLowerCaseCharacter: 'Vähintään yksi pieni kirjain vaadittu',
    missingDigit: 'Vähintään yksi numero vaadittu',
    invalidCharacter: 'Salasanassa on kiellettyjä merkkejä',
    invalidCharacterSequence: 'Esim. 123 ja abc eivät ole sallittuja',
    passwordsNotMatching: 'Salasanat eivät täsmää',
    passwordsAreTheSame: 'Uusi salasana ei voi olla sama kuin vanha salasanasi',
    invalidEmailAddressFormat: 'Sähköpostiosoite on viallinen',
    noInternationalPrefix: 'Numerosta puuttuu maatunnus (esim. +46)',
    invalidPostalCode: 'Postinumero on virheellinen',
    invalidInteger: 'Annetun merkin tulee olla kokonaisluku',
    isOneOf: 'Ei kuulu joukkoon "{values}"',
    isNumber: 'Ei numero',
    isPositiveNumber: 'Numeron täytyy olla vähintään 0',
    invalidUrl: 'URL ei ole oikeassa muodossa',
    disallowedFileExtension:
      'Tiedostomuoto "{fileExtension}" ei ole sallittu. Sallitut tiedostomuodot ovat {allowedFileExtensions}',
  },
  pl: {
    required: 'Wymagane',
    minLength: 'Co najmniej {length} znaków jest wymaganych',
    maxLength: 'Co najwyżej {length} znaków jest dozwolonych',
    missingUpperCaseCharacter: 'Wymagana jest co najmniej jedna wielka litera',
    missingLowerCaseCharacter: 'Wymagana jest co najmniej jedna mała litera',
    missingDigit: 'Wymagana jest co najmniej jeden cyfra',
    invalidCharacter: 'Nieprawidłowe znaki',
    invalidCharacterSequence: 'Np. 123 i abc są niedozwolone',
    passwordsNotMatching: 'Hasło nie są takie same',
    passwordsAreTheSame: 'Nowe hasło musi różnić się od starego',
    invalidEmailAddressFormat: 'Podany email jest nieprawidłowy',
    noInternationalPrefix: 'Brak międzynarodowego prefiksu (np. +46)',
    invalidPostalCode: 'Podany kod pocztowy jest nieprawidłowy',
    invalidInteger: 'Podany znak powinien być liczbą całkowitą',
    isOneOf: 'To nie jest jedna z "{values}"',
    isNumber: 'To nie jest liczba',
    isPositiveNumber: 'Liczba powinna wynosić co najmniej 0',
    invalidUrl: 'Given URL is not valid',
    disallowedFileExtension:
      'File extension "{fileExtension}" is not allowed. Allowed file extensions are {allowedFileExtensions}',
  },
  se: {
    required: 'Obligatoriskt',
    minLength: 'Minst {length} tecken krävs',
    maxLength: 'Högst {length} tecken är tillåtna',
    missingUpperCaseCharacter: 'Minst en stor bokstav krävs',
    missingLowerCaseCharacter: 'Minst en liten bokstav krävs',
    missingDigit: 'Minst en siffra krävs',
    invalidCharacter: 'Ogiltiga tecken',
    invalidCharacterSequence: 'T.ex. 123 och abc är inte tillåtna',
    passwordsNotMatching: 'Lösenorden matchar inte',
    passwordsAreTheSame: 'Det nya lösenordet måste skilja sig från det gamla',
    invalidEmailAddressFormat: 'Givet e-postmeddelande är inte giltigt',
    noInternationalPrefix: 'Internationellt prefix (t.ex. +46) saknas',
    invalidPostalCode: 'Givet postnummer är inte giltigt',
    invalidInteger: 'Ett givet tecken ska vara ett heltal',
    isOneOf: 'Inte en av "{values}"',
    isNumber: 'Inte ett nummer',
    isPositiveNumber: 'Nummeret ska vara större än 0',
    invalidUrl: 'Länken är inte giltlig',
    disallowedFileExtension:
      'Filprefix "{fileExtension}" är inte giltligt. Giltliga filprefix är {allowedFileExtensions}',
  },
};
