import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import { baseline } from 'shared-between-front-ends/src/components/public/constants';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Page from 'shared-between-front-ends/src/components/public/Page/Page';
import StickyTable from 'shared-between-front-ends/src/components/public/Table/StickyTable';
import withModel from 'shared-between-front-ends/src/decorators/withModel/withModel';
import Navigation from '../../Navigation/Navigation';
import ResourceRow from './ResourceRow/ResourceRow';
import styles from './Scheduler.module.scss';
import SchedulerModel from './SchedulerModel/SchedulerModel';
import SchedulerTableHeader from './SchedulerTableHeader/SchedulerTableHeader';
import SchedulerWorkOrders from './SchedulerWorkOrders/SchedulerWorkOrders';

const Scheduler = ({ schedulerModel }) => {
  const {
    allowChangingViewToAbsenceScheduler,
    buttonForClearingWorkOrderSelectionIsEnabled,
    changeListedWorkOrders,
    changeViewToAbsenceScheduler,
    checkIfResourceDataIsValid,
    clearWorkOrderSelection,
    daysOfSelectedDateRange,
    districtId,
    listedWorkOrders,
    resourceRows,
    resourcesById,
    selectedWorkOrderCategory,
    sendNotificationEmails,
    showDayOfMonthString,
    showDayOfWeekString,
    showWeekendAsSelectable,
    showWeekNumberLabels,
    teamId,
    userRights,
    weekendsAreShown,
    workOrderListFilterInput,
    monthsOfSelectedDateRange,
    showScheduledWorkOrders,
    updatedTimeStamp,
    sendUsersDataToIFS,
    isResourceErpIdsSent,
    resourceErpIdsSuccessMessage,
  } = schedulerModel;

  return (
    <Page
      padding={{ vertical: 'zero' }}
      data-scheduler-page-test
      data-scheduler-e2e-test={teamId}
    >
      <Navigation margin={false} />

      <Flex>
        <StickyTable
          occupyVerticalSpace
          flexItem
          className={styles.scheduler_table}
          headers={[
            <SchedulerTableHeader
              userRights={userRights}
              sendNotificationEmails={sendNotificationEmails}
              showDayOfMonthString={showDayOfMonthString}
              showWeekNumberLabels={showWeekNumberLabels}
              showDayOfWeekString={showDayOfWeekString}
              showWeekendAsSelectable={showWeekendAsSelectable}
              changeViewToAbsenceScheduler={changeViewToAbsenceScheduler}
              allowChangingViewToAbsenceScheduler={
                allowChangingViewToAbsenceScheduler
              }
              monthsOfSelectedDateRange={monthsOfSelectedDateRange}
              data-table-header-test
            />,
          ]}
          rows={resourceRows}
          cols={getTableCols(daysOfSelectedDateRange)}
          data-weekends-are-shown-e2e-test={weekendsAreShown}
        >
          {toResourceRowFor({
            resourcesById,
            teamId,
            districtId,
            userRights,
            updatedTimeStamp,
            listedWorkOrders,
            showScheduledWorkOrders,
            sendUsersDataToIFS,
            isResourceErpIdsSent,
            checkIfResourceDataIsValid,
            resourceErpIdsSuccessMessage,
          })}
        </StickyTable>

        <SchedulerWorkOrders
          selectedWorkOrderCategory={selectedWorkOrderCategory}
          listedWorkOrders={listedWorkOrders}
          changeListedWorkOrders={changeListedWorkOrders}
          teamId={teamId}
          districtId={districtId}
          shown={userRights.doScheduling}
          clearWorkOrderSelection={clearWorkOrderSelection}
          buttonForClearingWorkOrderSelectionIsEnabled={
            buttonForClearingWorkOrderSelectionIsEnabled
          }
          workOrderListFilterInput={workOrderListFilterInput}
          showScheduledWorkOrders={showScheduledWorkOrders}
          data-shown-when-user-has-right-to-do-scheduling-test
        />
      </Flex>
    </Page>
  );
};

const getTableCols = daysOfSelectedDateRange => [
  {
    width: 26 * baseline,
  },
  ...daysOfSelectedDateRange.map(dayOfSelectedDateRange => ({
    width: dayOfSelectedDateRange.compact ? 2 * baseline : '100%',
  })),
];

const toResourceRowFor = ({
  resourcesById,
  teamId,
  districtId,
  userRights,
  updatedTimeStamp,
  listedWorkOrders,
  showScheduledWorkOrders,
  sendUsersDataToIFS,
  isResourceErpIdsSent,
  checkIfResourceDataIsValid,
  resourceErpIdsSuccessMessage,
}) => gridRow => {
  return (
    <ResourceRow
      key={gridRow.resourceId}
      days={gridRow.days}
      resource={resourcesById.get(gridRow.resourceId) || {}}
      teamId={teamId}
      districtId={districtId}
      gridRow={gridRow}
      userRights={userRights}
      updatedTimeStamp={updatedTimeStamp}
      listedWorkOrders={listedWorkOrders}
      showScheduledWorkOrders={showScheduledWorkOrders}
      sendUsersDataToIFS={sendUsersDataToIFS}
      isResourceErpIdsSent={isResourceErpIdsSent}
      checkIfResourceDataIsValid={checkIfResourceDataIsValid}
      resourceErpIdsSuccessMessage={resourceErpIdsSuccessMessage}
    />
  );
};

export default decorate(
  withModel({
    Model: SchedulerModel,
    props: [
      {
        name: 'schedulerModel',
      },
    ],
  }),
)(Scheduler);
