/* istanbul ignore file */
import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';

const MinimalAppointment = ({
  appointment: {
    workOrderErpId,
    backgroundColor,
    titleForScheduler,
    lunch,
    nightShift,
    workOrderType,
    workOrder: { notification, done },
  },
  listedWorkOrders,
  showScheduledWorkOrders,
  ...props
}) => {
  const isAnScheduledWorkOrder = () => {
    if (listedWorkOrders && listedWorkOrders.length <= 7) {
      const appointmentsFound = listedWorkOrders.map(
        workOrder => workOrder.erpId,
      );
      return !appointmentsFound.includes(workOrderErpId);
    }
    return false;
  };

  const isAppointmentFound =
    showScheduledWorkOrders &&
    showScheduledWorkOrders.value &&
    listedWorkOrders &&
    listedWorkOrders.length <= 7 &&
    isAnScheduledWorkOrder();

  return (
    <Flex
      centered
      backgroundColor={
        done.value
          ? 'doneBackground'
          : nightShift.value
          ? 'primary'
          : isAppointmentFound
          ? '#E3E3E3'
          : backgroundColor
      }
      padding={{
        horizontal: titleForScheduler ? 'xs' : 'zero',
      }}
      {...props}
      relative
    >
      {notification.value && (
        <Div
          absolutePosition={
            titleForScheduler
              ? 'my-top-right-to-top-right-of-parent'
              : 'my-dimensions-to-match-parents'
          }
        >
          <Flex centeredHorizontally>
            <Icon name="bell" color="action" size="sm" />
          </Flex>
        </Div>
      )}

      {nightShift.value && (
        <Div
          absolutePosition="my-top-left-to-top-left-of-parent"
          padding={{ size: '3xs' }}
        >
          <Flex centeredHorizontally>
            {titleForScheduler && <Icon name="moon" color="grey20" size="xs" />}
          </Flex>
        </Div>
      )}

      <BodyText color="white" singleLine>
        {workOrderType === 'workOrder' || workOrderType === 'commonWork'
          ? titleForScheduler &&
            (lunch.value
              ? titleForScheduler - 0.5 + ' h'
              : titleForScheduler + ' h')
          : titleForScheduler && titleForScheduler}

        {!titleForScheduler && <span>&nbsp;</span>}
      </BodyText>
    </Flex>
  );
};

export default decorate(observer)(MinimalAppointment);
