import {
  matches,
  none,
  toLower,
} from 'shared-between-everything/src/functionalProgramming';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';

import noDuplicateIdsValidatorTranslations from './noDuplicateIdsValidatorTranslations';

const translate = localTranslate(noDuplicateIdsValidatorTranslations);

const noDuplicateIdsValidator = thingsWithId => id =>
  none(
    matches({ id: toLower(id) }),
    thingsWithId.map(({ id }) => ({ id: toLower(id) })),
  )
    ? null
    : translate('duplicateId');

export default noDuplicateIdsValidator;
