import csvToJson from 'csvtojson';
import defaultEmptyTo from 'shared-between-everything/src/doings/defaultEmptyTo/defaultEmptyTo';

import {
  map,
  pipeline,
} from 'shared-between-everything/src/functionalProgramming';
import utf8 from 'utf8';

const getRowsFromCsv = async input =>
  pipeline(
    input,
    getRowsFromCSV,
    map(map(utf8.decode)),
    map(map(defaultEmptyTo(null))),
  );

const getRowsFromCSV = input =>
  new Promise(resolve => {
    csvToJson({
      output: 'csv',
      delimiter: ';',
    })
      .fromString(input)
      .then(rows => {
        resolve(rows);
      });
  });

export default getRowsFromCsv;
