import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';
import pathParametersInputMapper from 'shared-between-front-ends/src/doings/pathParametersInputMapper/pathParametersInputMapper';

const callForEffortDefinitionCatalogs = getAuthenticatedCall({
  apiCallConfig: {
    path:
      '/api/resource-dashboard/get-effort-definition-catalog-items/{teamId}',

    method: 'GET',

    inputMapper: pathParametersInputMapper,
  },
});

export default callForEffortDefinitionCatalogs;
