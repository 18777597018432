import flow from 'lodash/fp/flow';
import replace from 'lodash/fp/replace';

import InputModelBaseClass from '../../../InputModelBaseClass';

const numberToHourStringFormatter = flow(x => x + 'h', replace('.', ','));

export default class HourInputModel extends InputModelBaseClass {
  constructor({ validators = [], ...rest } = {}) {
    super({
      outboundFormatters: [numberToHourStringFormatter],
      validators: [...validators],
      defaultValue: 0,
      ...rest,
    });
  }

  plus = () => {
    this.setValue(this.value + 0.5);
  };

  minus = () => {
    if (this.value - 0.5 >= 0) {
      this.setValue(this.value - 0.5);
    }
  };

  setValueFor = value => () => {
    this.setValue(value);
  };
}
