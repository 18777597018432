import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import { getIfsUrl } from 'shared-between-everything/src/doings/getIfsUrl/getIfsUrl';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import CheckboxInput from 'shared-between-front-ends/src/components/public/CheckboxInput/CheckboxInput';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import HourInput from 'shared-between-front-ends/src/components/public/HourInput/HourInput';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Link from 'shared-between-front-ends/src/components/public/Link/Link';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import SecondaryButton from 'shared-between-front-ends/src/components/public/SecondaryButton/SecondaryButton';
import SeparatorLine from 'shared-between-front-ends/src/components/public/SeparatorLine/SeparatorLine';
import TextAreaInput from 'shared-between-front-ends/src/components/public/TextAreaInput/TextAreaInput';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import schedulerTranslations from '../../../../../schedulerTranslations';
import styles from './AppointmentPopoverContent.module.scss';
import ConfirmationMessage from './ConfirmationMessage/ConfirmationMessage';
import ifsLogo from './IFS_logo.png';
import IconAndLabel from './IconAndLabel/IconAndLabel';
import NightShiftIcon from './NightShiftIcon/NightShiftIcon';
import NotificationIcon from './NotificationIcon/NotificationIcon';
import UrgencyIcon from './UrgencyIcon/UrgencyIcon';

const translate = localTranslate(schedulerTranslations);

const AppointmentPopoverContent = ({
  appointment: {
    id,
    delete: deleteAppointment,
    selectWorkOrder,
    duration,
    startTime,
    endTime,
    backgroundColor,
    workOrderName,
    workOrderErpId,
    info,
    lunch,
    nightShift,
    workOrder: { urgent, done, notification, type },
    workOrderDoneStatusCanBeUpdated,
    workOrderUrgencyCanBeUpdated,
    navigateToUpdateWorkOrder,
    workOrderLink,
  },
  resource,
  sendUsersDataToIFS,
  isResourceErpIdsSent,
  userRights,
  checkIfResourceDataIsValid,
  resourceErpIdsSuccessMessage,
  setIsResourceErpIdsSent,
  ...props
}) => {
  const submitUsersDataToIFS = async () => {
    await sendUsersDataToIFS({
      resourceErpId: resource.erpId,
      workOrderErpId: workOrderErpId,
    });
  };

  const isResourceDataValid = checkIfResourceDataIsValid({
    resourceErpId: resource.erpId,
  });

  const currentIfsUrl = getIfsUrl(workOrderErpId, navigator.userAgent);

  return (
    <Panel width="lg" {...props}>
      <Panel.Content padding={{ size: 'sm' }} backgroundColor={backgroundColor}>
        <Flex centeredVertically spaceBetween color="white">
          <HeavyText flexItem>{workOrderName}</HeavyText>

          <Gutter />

          <Div flexItem>
            <HeavyText
              color="white"
              style={{
                letterSpacing: '2px',
              }}
            >
              {workOrderErpId}
            </HeavyText>
          </Div>
        </Flex>
      </Panel.Content>

      <Panel.Content padding={{ size: 'md', bottom: 'lg' }}>
        <Flex centeredVertically>
          <Flex centered>
            <HourInput
              lunch={lunch}
              model={{ duration, startTime, endTime, type }}
            />
          </Flex>

          <Gutter size="xxs" />

          <SeparatorLine size="sm" />

          <Div flexItem>
            <Link
              onClick={deleteAppointment}
              defaultColors
              data-delete-appointment-test
              shown={!!userRights.doScheduling}
            >
              <IconAndLabel iconName="calendar-xmark">
                {translate('deleteAppointment')}
              </IconAndLabel>
            </Link>

            <SeparatorLine
              size="xs"
              horizontal
              shown={userRights.doScheduling}
            />

            <Link
              onClick={navigateToUpdateWorkOrder}
              defaultColors
              data-navigate-to-update-work-order-test
              shown={!!userRights.maintainWorkOrders}
            >
              <IconAndLabel iconName="rectangle-list">
                {translate('openWorkOrder')}
              </IconAndLabel>
            </Link>

            <SeparatorLine
              size="xs"
              horizontal
              shown={userRights.maintainWorkOrders && userRights.doScheduling}
            />

            <Link
              onClick={selectWorkOrder}
              defaultColors
              data-select-work-order-test
              shown={!!userRights.doScheduling}
            >
              <IconAndLabel iconName="copy">
                {translate('selectWorkOrder')}
              </IconAndLabel>
            </Link>

            {workOrderErpId && (
              <Div>
                <SeparatorLine size="xs" horizontal />

                <Flex centeredVertically>
                  <img className={styles.logo} alt="ifs_logo" src={ifsLogo} />

                  <Gutter size={'sm'} />

                  <Link href={currentIfsUrl} target="blank">
                    <HeavyText color="action" hoverColor="actionHover">
                      {translate('openWorkOrderInIfs')}
                    </HeavyText>
                  </Link>
                </Flex>
              </Div>
            )}

            {workOrderLink && (
              <Div>
                <SeparatorLine size="xs" horizontal />

                <Link
                  href={workOrderLink}
                  defaultColors
                  openInNewWindow
                  data-work-order-specific-link-test
                >
                  <IconAndLabel iconName="up-right-from-square">
                    {translate('openWorkOrderLink')}
                  </IconAndLabel>
                </Link>
              </Div>
            )}
          </Div>
        </Flex>

        <Div shown={userRights.doScheduling || info.outboundValue}>
          <Gutter />

          <TextAreaInput
            label={translate('info')}
            labelExtra={
              <Icon
                name="file-lines"
                size="sm"
                color="grey90"
                margin={{ left: 'xs' }}
              />
            }
            model={info}
            data-info-test
          />
        </Div>

        <Div
          shown={
            workOrderDoneStatusCanBeUpdated || workOrderUrgencyCanBeUpdated
          }
        >
          <Flex centeredVertically spaceBetween>
            <Flex centeredVertically>
              <CheckboxInput
                label={translate('done')}
                model={done}
                data-done-test
                shown={workOrderDoneStatusCanBeUpdated}
              />

              <Gutter size="sm" />

              <UrgencyIcon
                backgroundColor={backgroundColor}
                workOrderErpId={workOrderErpId}
                urgencyState={urgent}
                hoverLabel={
                  urgent.value ? translate('notUrgent') : translate('urgent')
                }
              />

              <Gutter size="xs" />

              <NotificationIcon
                backgroundColor={backgroundColor}
                workOrderErpId={workOrderErpId}
                notificationState={notification}
                hoverLabel={
                  notification.value
                    ? translate('unnotify')
                    : translate('notify')
                }
              />

              <Gutter size="xs" />

              <NightShiftIcon
                backgroundColor={backgroundColor}
                appointmentId={id}
                nightShiftState={nightShift}
                hoverLabel={
                  nightShift.value
                    ? translate('notNightShift')
                    : translate('nightShift')
                }
              />
            </Flex>

            <Gutter size="sm" />

            {workOrderErpId && resource.role !== 'specialResource' && (
              <Div relative>
                <Flex centeredHorizontally>
                  <SecondaryButton
                    data-send-user-to-ifs
                    size="sm"
                    enabled={isResourceDataValid}
                    onClick={submitUsersDataToIFS}
                  >
                    <Flex centeredVertically>
                      <Icon name="user" size="sm" />

                      <Gutter size="xs" />

                      <BodyText size="3xs" capitalize>
                        {translate('sendUserDataToIFS')}
                      </BodyText>
                    </Flex>
                  </SecondaryButton>
                </Flex>

                <ConfirmationMessage
                  isResourceErpIdsSent={isResourceErpIdsSent}
                  resourceErpIdsSuccessMessage={resourceErpIdsSuccessMessage}
                  isResourceDataValid={isResourceDataValid}
                />
              </Div>
            )}
          </Flex>
        </Div>
      </Panel.Content>
    </Panel>
  );
};

export default decorate(observer)(AppointmentPopoverContent);
