import parameterInputMapperFor from 'shared-between-everything/src/doings/parameterInputMapperFor/parameterInputMapperFor';
import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

const callForModifyingNightShiftStatusOfAppointment = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/appointment/{appointmentId}/modify-night-shift',
    method: 'POST',
    inputMapper: parameterInputMapperFor({
      pathParameters: {
        appointmentId: 'appointmentId',
      },

      bodyParameters: {
        nightShift: 'nightShift',
      },
    }),
  },
  decoratorConfig: {
    spinnerName: 'modifying-night-shift-status-of-appointment',
    showSpinnerInstantly: false,
  },
});

export default callForModifyingNightShiftStatusOfAppointment;
