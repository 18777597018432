import React from 'react';

import Div from '../../../private/Element/Div/Div';
import { PanelTransparencyContextConsumer } from '../Panel';

const paddingForTransparentPanel = {
  horizontal: 'md',
  vertical: 'zero',
};
const defaultPadding = {
  size: 'md',
};

function PanelContent({
  className,
  padding: explicitPadding,
  children,
  ...props
}) {
  return (
    <PanelTransparencyContextConsumer>
      {({ panelIsTransparent }) => {
        const padding =
          explicitPadding ||
          (panelIsTransparent && paddingForTransparentPanel) ||
          defaultPadding;

        return (
          <Div padding={padding} {...props}>
            {children}
          </Div>
        );
      }}
    </PanelTransparencyContextConsumer>
  );
}

export default PanelContent;
