import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

const getResource = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/resources/{resourceId}',
    method: 'GET',
  },
  decoratorConfig: {
    spinnerName: 'getting-resource',
    showSpinnerInstantly: true,
  },
});

export default getResource;
