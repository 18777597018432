import React from 'react';
import Flex from '../../Flex/Flex';

export default ({ children, ...props }) => (
  <Flex
    border={{
      radius: { size: 'lg' },
      color: 'grey6',
      size: 'sm',
    }}
    backgroundColor="white"
    hideOverflow
    {...props}
  >
    {children}
  </Flex>
);
