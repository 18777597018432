import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';
import pathParametersInputMapper from 'shared-between-front-ends/src/doings/pathParametersInputMapper/pathParametersInputMapper';

const callForDeleteDistrict = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/delete-district/{districtId}',
    method: 'POST',
    inputMapper: pathParametersInputMapper,
  },
  decoratorConfig: {
    spinnerName: 'deleting-district',
    showSpinnerInstantly: true,
  },
});

export default callForDeleteDistrict;
