import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

const createAppointment = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/scheduler/schedule-appointment',
    method: 'POST',
  },
  decoratorConfig: {
    spinnerName: 'creating-appointment',
    showSpinnerInstantly: false,
  },
});
export default createAppointment;
