import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Table from 'shared-between-front-ends/src/components/public/Table/Table';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Map from 'shared-between-front-ends/src/components/public/Map/Map';
import SemanticButton from 'shared-between-front-ends/src/components/public/SemanticButton/SemanticButton';
import schedulerTranslations from '../../../schedulerTranslations';
import SchedulerTableHeadingCell from '../SchedulerTableHeadingCell/SchedulerTableHeadingCell';

const translate = localTranslate(schedulerTranslations);

const AbsenceBudget = ({
  budgetMonths,
  goToNextMonth,
  goToPreviousMonth,
  daysOfSelectedDateRange,
}) => {
  return (
    <Table.Row>
      <SchedulerTableHeadingCell
        color="white"
        backgroundColor="eltelGrey"
        padding={{
          left: 'md',
          bottom: 'zero',
          top: 'xxs',
        }}
      >
        <BodyText block>{translate('absenceBudgetHeader')}</BodyText>

        <BodyText size="3xs" block>
          {translate('absenceBudgetSubheader')}
        </BodyText>
      </SchedulerTableHeadingCell>

      <SchedulerTableHeadingCell colSpan={7 + daysOfSelectedDateRange.length}>
        <Flex
          spaceBetween
          padding={{
            horizontal: 'sm',
            vertical: 'xs',
          }}
          pileVerticallyTo="md"
        >
          <Div>
            <SemanticButton
              onClick={goToPreviousMonth}
              defaultColors
              data-for-decrementing-starting-month-test
            >
              <Icon name="chevron-left" size="md" />
            </SemanticButton>
          </Div>

          <Map items={budgetMonths}>
            {({
              actualAbsenceDayCount,
              budgetedAbsenceDayCount,
              month,
              year,
              select,
            }) => (
              <SemanticButton
                flexItem
                onClick={select}
                color="action"
                hoverColor="actionHover"
                data-setting-selected-month-test={`${year}-${month}`}
              >
                <Flex centeredHorizontally>
                  <BodyText>{translate(`shortMonths[${month - 1}]`)}</BodyText>

                  <Gutter size="xxs" />

                  <BodyText>{year}:</BodyText>

                  <Gutter size="xxs" />

                  <BodyText color="black">
                    {actualAbsenceDayCount}/{budgetedAbsenceDayCount}
                  </BodyText>
                </Flex>
              </SemanticButton>
            )}
          </Map>

          <Div>
            <SemanticButton
              onClick={goToNextMonth}
              defaultColors
              data-for-incrementing-starting-month-test
            >
              <Icon name="chevron-right" size="md" />
            </SemanticButton>
          </Div>
        </Flex>
      </SchedulerTableHeadingCell>
    </Table.Row>
  );
};
export default decorate(observer)(AbsenceBudget);
