import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Map from 'shared-between-front-ends/src/components/public/Map/Map';
import SeparatorLine from 'shared-between-front-ends/src/components/public/SeparatorLine/SeparatorLine';
import preventDefault from 'shared-between-front-ends/src/doings/preventDefault/preventDefault';
import SchedulerTableCell from '../../SchedulerTableCell/SchedulerTableCell';
import AppointmentBox from './AppointmentBox/AppointmentBox';

const ResourceDayCell = ({ gridCell }) => {
  return (
    <SchedulerTableCell
      backgroundColor={gridCell.backgroundColor}
      clickableAppearance={gridCell.hasClickableAppearance}
      onClick={gridCell.selectForScheduling}
      onDrop={event => {
        const appointmentOrWorkOrder = JSON.parse(
          event.dataTransfer.getData('appointmentOrWorkOrder'),
        );

        gridCell.drop(appointmentOrWorkOrder);
      }}
      onDragOver={preventDefault}
      data-table-cell-test={gridCell.resourceIdAndDate}
    >
      <Flex occupyVerticalSpace occupyHorizontalSpace>
        {gridCell.day.isFirstDayOfMonth && (
          <SeparatorLine backgroundColor="tableBorder" occupyVerticalSpace />
        )}

        <Div flexItem padding={{ bottom: 'sm' }}>
          <Map items={gridCell.appointments} separator={<Gutter size="3xs" />}>
            {appointment =>
              appointment.isRelevantToAbsenceScheduling ? (
                <AppointmentBox appointment={appointment} />
              ) : (
                <Div
                  padding={{
                    horizontal: 'xxs',
                    vertical: 'xs',
                  }}
                  backgroundColor="grey6"
                >
                  {/* <BodyText singleLine>&nbsp;</BodyText> */}
                </Div>
              )
            }
          </Map>
        </Div>

        {gridCell.day.isLastDayOfMonth && (
          <SeparatorLine backgroundColor="tableBorder" occupyVerticalSpace />
        )}
      </Flex>
    </SchedulerTableCell>
  );
};

export default decorate(observer)(ResourceDayCell);
