import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';
import pathParametersInputMapper from 'shared-between-front-ends/src/doings/pathParametersInputMapper/pathParametersInputMapper';

const callForResourcesInTeam = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/teams/{teamId}/resources',
    method: 'GET',
    inputMapper: pathParametersInputMapper,
  },
  decoratorConfig: {
    spinnerName: 'loading-resources',
    showSpinnerInstantly: false,
  },
});

export default callForResourcesInTeam;
