import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import Address from 'shared-between-front-ends/src/components/public/Address/Address';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Link from 'shared-between-front-ends/src/components/public/Link/Link';
import Map from 'shared-between-front-ends/src/components/public/Map/Map';
import Popover from 'shared-between-front-ends/src/components/public/Popover/Popover';
import SecondaryButton from 'shared-between-front-ends/src/components/public/SecondaryButton/SecondaryButton';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import StripedBox from '../../../shared/StripedBox/StripedBox';
import resourceDashboardTranslations from '../resourceDashboardTranslations';
import styles from './AppointmentBox.module.scss';
import ExpectedEffort from './ExpectedEffort/ExpectedEffort';
import WorkOrderDescriptionPopover from './WorkOrderDescriptionPopover/WorkOrderDescriptionPopover';

const translate = localTranslate(resourceDashboardTranslations);

const AppointmentBox = ({ normalizedItem }) => {
  return (
    <StripedBox
      color={normalizedItem.workOrder.color}
      className={styles.appointmentBox}
      data-appointment-test={normalizedItem.appointment.id}
      data-appointment-e2e-test={normalizedItem.workOrder.id}
    >
      <Link
        block
        occupyHorizontalSpace
        padding={{ size: 'xs' }}
        onClick={normalizedItem.appointmentDetailVisibility.toggle}
        data-appointment-id={normalizedItem.appointment.id}
        data-toggle-appointment-test={normalizedItem.appointment.id}
        data-expand-e2e-test={normalizedItem.appointment.id}
        color="fontColor"
        hoverColor="fontColor"
      >
        <Flex spaceBetween centered>
          <BodyText>{normalizedItem.workOrder.name}</BodyText>

          <Gutter size="xxs" />

          <HeavyText size="3xs" color="black">
            {normalizedItem.startEndTimesToString}
          </HeavyText>
        </Flex>

        <Gutter size="sm" shown={normalizedItem.addressOrErpIdIsPresent} />

        <Flex
          spaceBetween
          alignBottom
          shown={normalizedItem.addressOrErpIdIsPresent}
        >
          <Address
            streetAddress={normalizedItem.workOrder.address}
            postalCode={normalizedItem.workOrder.postalCode}
            city={normalizedItem.workOrder.city}
            size="3xs"
          />

          <Gutter />

          <BodyText size="3xs">{normalizedItem.workOrder.erpId}</BodyText>
        </Flex>

        <Gutter size="sm" />

        <Flex
          hidden={normalizedItem.appointmentDetailVisibility.internalValue}
          centered
        >
          <Div flexItem>
            <Flex centeredVertically>
              <Icon
                name="circle-info"
                color="primary"
                size="sm"
                title={normalizedItem.appointment.info}
                shown={normalizedItem.appointment.info}
              />

              <Gutter
                size="xs"
                shown={
                  normalizedItem.appointment.info &&
                  normalizedItem.hasActualEffortEntries
                }
              />

              <Icon
                shown={normalizedItem.hasActualEffortEntries}
                name="screwdriver-wrench"
                size="sm"
                color={
                  normalizedItem.hasActualEffortEntriesForDay
                    ? 'success'
                    : 'grey6'
                }
                title={
                  normalizedItem.hasActualEffortEntriesForDay
                    ? translate('hasActualEffortEntriesForDay')
                    : translate('hasActualEffortEntries')
                }
              />
            </Flex>
          </Div>

          <Icon name="chevron-down" color="indicationGrey" size="sm" />

          <Div flexItem />
        </Flex>
      </Link>

      <Div
        shown={normalizedItem.appointmentDetailVisibility.internalValue}
        data-appointment-details-test={normalizedItem.appointment.id}
      >
        <Div
          padding={{
            size: 'xs',
            bottom: false,
          }}
        >
          <TitleAndContent
            title={
              <Flex centeredVertically>
                <HeavyText color="primary">
                  {translate('description')}
                </HeavyText>

                <Gutter size="sm" />

                <SecondaryButton
                  onClick={normalizedItem.showWorkOrderDescriptionPopover}
                  size="sm"
                  data-edit-work-order-description-test={
                    normalizedItem.appointment.id
                  }
                  data-edit-work-order-description-e2e-test={
                    normalizedItem.appointment.id
                  }
                >
                  {translate('editWorkOrderDescription')}
                </SecondaryButton>
              </Flex>
            }
          >
            <BodyText tagName="pre" size="3xs">
              {normalizedItem.committedWorkOrderDescriptionInput.outboundValue}
            </BodyText>

            <Popover
              activationModel={normalizedItem.activationModel}
              activateOn="manual"
              activationId={`work-order-description-popover-for-${normalizedItem.appointment.id}`}
              content={
                <WorkOrderDescriptionPopover normalizedItem={normalizedItem} />
              }
              position="vertical center of screen"
              data-edit-work-order-description-popover-test={
                normalizedItem.appointment.id
              }
            />
          </TitleAndContent>

          <Gutter size="sm" hidden={!normalizedItem.appointment.info} />

          <TitleAndContent
            title={
              <Flex centeredVertically>
                <HeavyText>{translate('info')}</HeavyText>

                <Gutter size="xxs" />

                <Icon
                  flexItem
                  name="circle-info"
                  color="primary"
                  size="sm"
                  width="xs"
                />
              </Flex>
            }
            hidden={!normalizedItem.appointment.info}
          >
            <BodyText size="3xs">{normalizedItem.appointment.info}</BodyText>
          </TitleAndContent>

          <Gutter
            size="sm"
            hidden={!normalizedItem.otherResourcesInSameWorkOrderString}
          />

          <TitleAndContent
            title={translate('otherResources')}
            hidden={!normalizedItem.otherResourcesInSameWorkOrderString}
          >
            <BodyText size="3xs">
              {normalizedItem.otherResourcesInSameWorkOrderString}
            </BodyText>
          </TitleAndContent>

          <Gutter size="sm" hidden={!normalizedItem.workOrder.link} />

          <TitleAndContent
            title={translate('link')}
            hidden={!normalizedItem.workOrder.link}
          >
            <BodyText size="3xs" singleLine>
              <Link
                href={normalizedItem.workOrder.link}
                openInNewWindow
                defaultColors
              >
                {normalizedItem.workOrder.link}
              </Link>
            </BodyText>
          </TitleAndContent>

          <Gutter size="lg" />

          <Div
            margin={{
              bottom: 'sm',
            }}
            shown={normalizedItem.issuedEffortsAreShown}
            data-show-issued-efforts-test={normalizedItem.appointment.id}
          >
            <Flex centeredVertically>
              <Icon name="screwdriver-wrench" />

              <Gutter size="xs" />

              <HeavyText color="primary">
                {translate('issuedEfforts')}
              </HeavyText>
            </Flex>

            <Gutter size="xs" />

            <Map
              items={normalizedItem.issuedEfforts}
              separator={<Gutter size="sm" />}
            >
              {effort => <ExpectedEffort {...effort} />}
            </Map>
          </Div>

          {normalizedItem.issuedEffortsAreShown &&
            normalizedItem.manualEffortsAreShown && <Gutter size="xs" />}

          <Div
            margin={{
              bottom: 'sm',
            }}
            shown={normalizedItem.manualEffortsAreShown}
            data-show-manual-efforts-test={normalizedItem.appointment.id}
          >
            <Flex centeredVertically>
              <Icon name="screwdriver-wrench" />

              <Gutter size="xs" />

              <HeavyText color="primary">
                {translate('manualEfforts')}
              </HeavyText>
            </Flex>

            <Gutter size="xs" />

            <Map
              items={normalizedItem.manualEfforts}
              separator={<Gutter size="sm" />}
            >
              {effort => <ExpectedEffort {...effort} />}
            </Map>
          </Div>

          <Flex centered>
            <SecondaryButton
              data-navigate-to-effort-entries-test={
                normalizedItem.appointment.id
              }
              onClick={normalizedItem.navigateToEffortEntries}
            >
              {translate('navigateToEffortEntries')}
            </SecondaryButton>
          </Flex>

          <Gutter size="sm" />
        </Div>

        <Link
          block
          padding={{ bottom: 'xs' }}
          occupyHorizontalSpace
          onClick={normalizedItem.appointmentDetailVisibility.setValueToFalse}
          data-hide-appointment-details-test={normalizedItem.appointment.id}
        >
          <Flex centered>
            <Icon name="chevron-up" color="indicationGrey" size="sm" />
          </Flex>
        </Link>
      </Div>
    </StripedBox>
  );
};

const TitleAndContent = ({ title, children, ...props }) => {
  return (
    <Div {...props}>
      <HeavyText color="primary" block>
        {title}
      </HeavyText>

      {children}
    </Div>
  );
};

export default decorate(observer)(AppointmentBox);
