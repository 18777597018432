import { action, computed, observable } from 'mobx';

export default class ConfirmationDialogModel {
  @observable confirmationsMap = new Map();
  @computed get confirmations() {
    return [...this.confirmationsMap.values()];
  }

  @action resolveConfirmation = key => {
    this.confirmationsMap.delete(key);
  };

  @action
  getConfirmation(message) {
    const key = Math.random();
    let accept;
    let reject;

    const confirmationPromise = new Promise(resolve => {
      accept = () => {
        this.resolveConfirmation(key);

        resolve(true);
      };

      reject = () => {
        this.resolveConfirmation(key);

        resolve(false);
      };
    });

    this.confirmationsMap.set(key, {
      message,
      accept,
      reject,
    });

    return confirmationPromise;
  }
}
