import parameterInputMapperFor from 'shared-between-everything/src/doings/parameterInputMapperFor/parameterInputMapperFor';
import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

const callForUpdateOfExpectedEfforts = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/work-order-maintenance/{workOrderId}/update-expected-efforts/',

    method: 'POST',

    inputMapper: parameterInputMapperFor({
      pathParameters: { workOrderId: 'workOrderId' },

      bodyParameters: {
        expectedEfforts: 'expectedEfforts',
      },
    }),
  },
});

export default callForUpdateOfExpectedEfforts;
