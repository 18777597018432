import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';
import pathParametersInputMapper from 'shared-between-front-ends/src/doings/pathParametersInputMapper/pathParametersInputMapper';

const callForLeaveDaysForTeamResources = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/absence-scheduler/get-leave-days-for-team-resources/{teamId}',
    method: 'GET',
    inputMapper: pathParametersInputMapper,
  },
  decoratorConfig: {
    spinnerName: 'getting-leave-days-for-team-resources',
    showSpinnerInstantly: false,
  },
});

export default callForLeaveDaysForTeamResources;
