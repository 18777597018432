import toNumber from 'lodash/fp/toNumber';
import { action, computed } from 'mobx';
import moment from 'moment-timezone';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import getDaysFor from '../getDaysFor/getDaysFor';
import getMonths from '../getMonths/getMonths';
import schedulerTranslations from '../../../schedulerTranslations';
import last from 'lodash/fp/last';
import first from 'lodash/fp/first';

const translate = localTranslate(schedulerTranslations);

export default class DateScopeModel {
  constructor(routingModel, getDayIsNationalHoliday) {
    this.routingModel = routingModel;
    this.getDayIsNationalHoliday = getDayIsNationalHoliday;
  }

  get showNumberOfWeeksOptions() {
    return [
      {
        label: '1',
        value: 1,
        disabled: this.weeksInView === 1,
      },
      {
        label: '2',
        value: 2,
        disabled: this.weeksInView === 2,
      },
      {
        label: '4',
        value: 4,
        disabled: this.weeksInView === 4,
      },
      {
        label: '8',
        value: 8,
        disabled: this.weeksInView === 8,
      },
      {
        label: '12',
        value: 12,
        disabled: this.weeksInView === 12,
      },
      {
        label: '16',
        value: 16,
        disabled: this.weeksInView === 16,
      },
    ];
  }

  get showWeekendsOptions() {
    return [
      {
        label: translate('yes'),
        value: true,
        disabled: this.weekendsAreShown === true,
      },
      {
        label: translate('no'),
        value: false,
        disabled: this.weekendsAreShown === false,
      },
    ];
  }

  @computed
  get weekendsAreShown() {
    return (
      this.routingModel.queryParameters.weekendsAreShown === 'true' ||
      this.weeksInView > 4
    );
  }

  @computed
  get weeksInView() {
    return toNumber(this.routingModel.queryParameters.weeksInView || '2');
  }

  @computed
  get showWeekNumberLabels() {
    return this.weeksInView < 12;
  }

  @computed
  get showDayOfWeekString() {
    return this.weeksInView < 4;
  }

  @computed
  get showDayOfMonthString() {
    return this.weeksInView < 8;
  }

  @computed
  get showWeekendAsSelectable() {
    return this.weeksInView < 8;
  }

  @computed
  get daysInView() {
    return this.weeksInView * 7;
  }

  @action
  showWeekends = show => {
    this.routingModel.mergeQueryParameters({
      weekendsAreShown: show,
    });
  };

  @action
  setNumberOfWeeksShown = weeks => {
    this.routingModel.mergeQueryParameters({
      weeksInView: weeks,
    });
  };

  @computed
  get daysOfSelectedDateRange() {
    return getDaysFor(this.getDayIsNationalHoliday)({
      selectedDateRange: this.selectedDateRange,
      weekendsAreShown: this.weekendsAreShown,
    });
  }

  @computed
  get monthsOfSelectedDateRange() {
    return getMonths(this.selectedDateRange);
  }

  @computed
  get yearStringOfSelectedDateRange() {
    const months = getMonths(this.selectedDateRange);

    const firstMonth = first(months);
    const lastMonth = last(months);

    const monthsSpanOverTwoYears = firstMonth.year !== lastMonth.year;

    if (monthsSpanOverTwoYears) {
      return `${firstMonth.year} - ${lastMonth.year}`;
    }

    return `${lastMonth.year}`;
  }

  @computed
  get selectedDateRange() {
    const start = this.startDate;
    const end = this.endDate;

    return { start, end };
  }

  @computed
  get startDate() {
    const currentWeekStartDate = moment()
      .isoWeekday(1)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .format(moment.HTML5_FMT.DATE);

    const { start = currentWeekStartDate } = this.routingModel.queryParameters;

    return start;
  }

  @computed
  get endDate() {
    const end = moment(this.startDate)
      .isoWeekday(this.daysInView)
      .endOf('day')
      .format(moment.HTML5_FMT.DATE);

    return end;
  }

  goToWeekOfSelectedDate = selectedDate => {
    const currentWeekSelectedDate = moment(selectedDate)
      .isoWeekday(1)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .format(moment.HTML5_FMT.DATE);

    this.routingModel.mergeQueryParameters({
      start: currentWeekSelectedDate,
    });
  };

  goBackToCurrentWeek = () => {
    this.routingModel.mergeQueryParameters({
      start: undefined,
    });
  };

  goForwardWeeks = numberOfWeeks => {
    const start = getDateFromWeekOffset(this.startDate, numberOfWeeks);

    this.routingModel.mergeQueryParameters({ start });
  };
}

const getDateFromWeekOffset = (dateTime, numberOfWeeks) =>
  moment(dateTime).add(numberOfWeeks, 'week').format(moment.HTML5_FMT.DATE);
