import { observer } from 'mobx-react';
import React from 'react';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Form from 'shared-between-front-ends/src/components/public/Form/Form';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import Loader from 'shared-between-front-ends/src/components/public/Loader/Loader';
import MainHeading from 'shared-between-front-ends/src/components/public/MainHeading/MainHeading';
import Map from 'shared-between-front-ends/src/components/public/Map/Map';
import MeasurementAmountInput from 'shared-between-front-ends/src/components/public/MeasurementAmountInput/MeasurementAmountInput';
import Page from 'shared-between-front-ends/src/components/public/Page/Page';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import PrimaryButton from 'shared-between-front-ends/src/components/public/PrimaryButton/PrimaryButton';
import RouteToggleGroup from 'shared-between-front-ends/src/components/public/RouteToggleGroup/RouteToggleGroup';
import SecondaryButton from 'shared-between-front-ends/src/components/public/SecondaryButton/SecondaryButton';
import SelectionInput from 'shared-between-front-ends/src/components/public/SelectionInput/SelectionInput';
import Subheading from 'shared-between-front-ends/src/components/public/Subheading/Subheading';
import TextInput from 'shared-between-front-ends/src/components/public/TextInput/TextInput';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import Navigation from '../../Navigation/Navigation';
import updateWorkOrderExpectedEffortsTranslations from './updateWorkOrderExpectedEffortsTranslations';

const translate = localTranslate(updateWorkOrderExpectedEffortsTranslations);

const UpdateWorkOrderExpectedEfforts = ({ model }) => (
  <Page
    data-work-order-expected-efforts-page-test
    data-work-order-expected-efforts-page-e2e-test={model.workOrderId}
  >
    <Navigation />

    <Form onSubmit={model.submit} data-submit-test>
      <Page.Row alignWithPanel margin={{ bottom: 'md' }}>
        <Flex alignBottom>
          <MainHeading flexItem>
            {translate('expectedEffortsPageHeader')}
          </MainHeading>

          <Gutter flexItem />

          <PrimaryButton type="submit" data-submit-test data-submit-e2e-test>
            {translate('submit')}
          </PrimaryButton>
        </Flex>
      </Page.Row>

      <Page.Row alignWithPanel>
        <RouteToggleGroup
          routes={model.tabRoutes}
          routingModel={model.dependencies.routingModel}
          inline
        />
      </Page.Row>

      <Loader when={() => model.loading}>
        {() => (
          <>
            <Page.Row>
              <Panel>
                <Panel.Content>
                  <Subheading>{translate('catalog')}</Subheading>

                  <Gutter size="sm" />

                  <SelectionInput
                    model={model.catalogInput}
                    data-catalog-input-test
                    data-catalogs-e2e-test
                  />
                </Panel.Content>
              </Panel>
            </Page.Row>

            <Page.Row>
              <Panel>
                <Panel.Content>
                  <Subheading>{translate('issued')}</Subheading>

                  <Gutter size="sm" />

                  <Map
                    items={model.issuedExpectedEfforts}
                    placeholder={<BodyText>{translate('none')}</BodyText>}
                  >
                    {({ catalogItem, doDeissue, effortInput }) => (
                      <Flex centeredVertically striped padding={{ size: 'xs' }}>
                        <HeavyText>{catalogItem.name}</HeavyText>

                        <Gutter flexItem />

                        <MeasurementAmountInput
                          width="sm"
                          model={effortInput}
                          data-effort-definition-effort={catalogItem.id}
                          data-expected-amount-e2e-test
                        />

                        <Gutter />

                        <SecondaryButton
                          size="sm"
                          onClick={doDeissue}
                          data-deissue-effort-definition-test={catalogItem.id}
                        >
                          {translate('deissue')}
                        </SecondaryButton>
                      </Flex>
                    )}
                  </Map>
                </Panel.Content>
              </Panel>
            </Page.Row>

            <Page.Row>
              <Panel>
                <Panel.Content>
                  <Subheading>{translate('manual')}</Subheading>

                  <Gutter size="sm" />

                  <Map
                    items={model.manualExpectedEfforts}
                    placeholder={<BodyText>{translate('none')}</BodyText>}
                  >
                    {({ catalogItem, doIssue }) => (
                      <Flex centeredVertically striped padding={{ size: 'xs' }}>
                        <HeavyText>{catalogItem.name}</HeavyText>

                        <Gutter flexItem />

                        <SecondaryButton
                          size="sm"
                          onClick={doIssue}
                          data-issue-effort-definition-test={catalogItem.id}
                        >
                          {translate('issue')}
                        </SecondaryButton>
                      </Flex>
                    )}
                  </Map>
                </Panel.Content>
              </Panel>
            </Page.Row>

            <Page.Row>
              <Panel>
                <Panel.Content>
                  <Subheading>{translate('nonIssued')}</Subheading>

                  <Gutter size="sm" />

                  <TextInput
                    model={model.expectedEffortSearchInput}
                    placeholder={translate('searchExpectedEfforts')}
                    data-effort-definition-search-test
                  />

                  <Gutter size="sm" />

                  <Map
                    items={model.unexpectedEfforts}
                    placeholder={<BodyText>{translate('none')}</BodyText>}
                  >
                    {({ catalogItem, doIssue }) => (
                      <Flex centeredVertically striped padding={{ size: 'xs' }}>
                        <HeavyText>{catalogItem.name}</HeavyText>

                        <Gutter flexItem />

                        <SecondaryButton
                          size="sm"
                          onClick={doIssue}
                          data-issue-effort-definition-test={catalogItem.id}
                          data-issue-e2e-test={catalogItem.name}
                        >
                          {translate('issue')}
                        </SecondaryButton>
                      </Flex>
                    )}
                  </Map>
                </Panel.Content>
              </Panel>
            </Page.Row>
          </>
        )}
      </Loader>
    </Form>
  </Page>
);

export default observer(UpdateWorkOrderExpectedEfforts);
