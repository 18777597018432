import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';
import pathParametersInputMapper from 'shared-between-front-ends/src/doings/pathParametersInputMapper/pathParametersInputMapper';

const callForExpectedEfforts = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/work-order-maintenance/{workOrderId}/get-expected-efforts/',

    method: 'GET',

    inputMapper: pathParametersInputMapper,
  },
});

export default callForExpectedEfforts;
