import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import Address from 'shared-between-front-ends/src/components/public/Address/Address';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Activatable from 'shared-between-front-ends/src/components/public/Popover/Activatable/Activatable';
import Popover from 'shared-between-front-ends/src/components/public/Popover/Popover';
import SeparatorLine from 'shared-between-front-ends/src/components/public/SeparatorLine/SeparatorLine';
import StripedBox from '../../../../../../shared/StripedBox/StripedBox';
import styles from './AppointmentBox.module.scss';
import AppointmentDetails from './AppointmentDetails/AppointmentDetails';
import AppointmentPopoverContent from './AppointmentPopoverContent/AppointmentPopoverContent';
import MinimalAppointment from './MinimalAppointment/MinimalAppointment';

const AppointmentBox = ({
  resource,
  appointment,
  userRights,
  showScheduledWorkOrders,
  listedWorkOrders,
  sendUsersDataToIFS,
  isResourceErpIdsSent,
  checkIfResourceDataIsValid,
  resourceErpIdsSuccessMessage,
}) => {
  return appointment.isObfuscated ? (
    <ObfuscatedAppointmentBoxContent />
  ) : (
    <Popover
      popovers={[
        {
          activationId: `popover-for-appointment-${appointment.id}`,
          activateOn: 'manual',
          position: 'top center to center of children',

          content: (
            <AppointmentPopoverContent
              appointment={appointment}
              resource={resource}
              stopEventPropagation
              userRights={userRights}
              sendUsersDataToIFS={sendUsersDataToIFS}
              isResourceErpIdsSent={isResourceErpIdsSent}
              checkIfResourceDataIsValid={checkIfResourceDataIsValid}
              resourceErpIdsSuccessMessage={resourceErpIdsSuccessMessage}
            />
          ),
        },

        {
          activationId: `popover-for-appointment-hover-${appointment.id}`,
          activateOn: 'hover',
          position: 'left top to right bottom of children',

          content: (
            <StripedBox
              color={appointment.backgroundColor}
              width="md"
              data-popover-for-hovered-appointment-e2e-test
            >
              <Div padding={{ size: 'xs' }}>
                <Flex pileVertically>
                  <BodyText size="xxs" color="action">
                    {appointment.startEndTimesToString.value}
                  </BodyText>
                  <HeavyText>{appointment.workOrderName}</HeavyText>

                  <BodyText size="3xs" block>
                    {appointment.workOrderErpId}
                  </BodyText>
                </Flex>

                <BodyText size="3xs" block>
                  <Address
                    streetAddress={appointment.workOrder.address}
                    postalCode={appointment.workOrder.postalCode}
                    city={appointment.workOrder.city}
                  />
                </BodyText>

                <SeparatorLine
                  horizontal
                  size="xs"
                  shown={!!appointment.info.outboundValue}
                />

                <Flex
                  shown={!!appointment.info.outboundValue}
                  centeredVertically
                  spaceBetween
                >
                  <BodyText size="3xs" breakingLine>
                    {appointment.info.outboundValue}
                  </BodyText>

                  <Gutter size="xs" />

                  <Icon
                    name="file-lines"
                    size="sm"
                    color="grey90"
                    title={appointment.info.value}
                  />
                </Flex>

                <SeparatorLine
                  shown={!!appointment.workOrder.description}
                  horizontal
                  size="xs"
                />

                <BodyText
                  size="3xs"
                  breakingLine
                  shown={!!appointment.workOrder.description}
                >
                  {appointment.workOrder.description}
                </BodyText>
              </Div>
            </StripedBox>
          ),
        },
      ]}
      data-appointment-popover-test={appointment.id}
    >
      <Activatable
        activationId={`work-order-popover-for-${appointment.workOrderId}`}
      >
        {({ activationStatus: relatedWorkOrderIsHovered }) => (
          <Div
            className={{
              [styles.appointmentBox]: true,
              [styles.appointmentBox__highlighted]: relatedWorkOrderIsHovered,
            }}
            onClick={appointment.select}
            highlight={appointment.disabled}
            border={appointment.border}
            draggableFor={
              appointment.isDraggable ? 'appointment-scheduling' : null
            }
            draggableValue={{
              appointmentId: appointment.id,
            }}
            data-appointment-box-for-appointment-test={appointment.id}
            data-appointment-e2e-test={appointment.id}
            data-color-e2e-test={appointment.backgroundColor}
            data-highlighted-e2e-test={relatedWorkOrderIsHovered}
          >
            {appointment.detailsAreVisible && (
              <AppointmentDetails
                appointment={appointment}
                listedWorkOrders={listedWorkOrders}
                showScheduledWorkOrders={showScheduledWorkOrders}
              />
            )}

            {!appointment.detailsAreVisible && (
              <MinimalAppointment
                appointment={appointment}
                listedWorkOrders={listedWorkOrders}
                showScheduledWorkOrders={showScheduledWorkOrders}
              />
            )}
          </Div>
        )}
      </Activatable>
    </Popover>
  );
};

const ObfuscatedAppointmentBoxContent = () => (
  <Div occupyHorizontalSpace backgroundColor="grey6">
    &nbsp;
  </Div>
);

export default decorate(observer)(AppointmentBox);
