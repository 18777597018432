import {
  contentTypes,
  contentTypeStringsByContentType,
} from '../../contentTypes';

import {
  camelCase,
  has,
  includes,
  invoke,
  isString,
  mapKeys,
  pipeline,
  some,
} from '../../functionalProgramming';

import encodeToUrlEncoding from './encodeToUrlEncoding/encodeToUrlEncoding';
import fetch from './fetch';

export const dependencies = {
  fetch,
};

export default async ({
  url,
  method,
  body,
  headers,
  contentType = contentTypes.json,
}) => {
  let response;

  const contentTypeString = contentTypeStringsByContentType[contentType];

  const requestHeaders = {
    'Content-Type': contentTypeString,
    Accept: 'application/json',
    ...headers,
  };

  try {
    const bodyParameters = getBodyParametersFor(contentType)(body);

    response = await dependencies.fetch(url, {
      method,
      headers: requestHeaders,
      body: bodyParameters,
    });
  } catch (error) {
    console.log(error);
    return {
      callWasSuccessful: false,
      error: {
        code: 'back-end-call-reject',
        message: error,
      },
    };
  }

  const callWasSuccessful = response.ok;

  const returnValue = await getResponseOrError(response);

  if (!callWasSuccessful && isString(returnValue)) {
    return {
      callWasSuccessful: false,
      error: {
        code: 'back-end-call-failure',
        message: returnValue,
      },
    };
  }

  if (resultIsAlreadyWrappedAsCallWasSuccessful(returnValue)) {
    return { ...returnValue, headers: response.headers };
  }

  return {
    callWasSuccessful,
    [callWasSuccessful ? 'response' : 'error']: returnValue,
  };
};

const getAllHeaders = response =>
  pipeline(response.headers, invoke('raw'), mapKeys(camelCase));

const getResponseOrError = async response => {
  const responseHeaders = getAllHeaders(response);

  let returnValue = undefined;

  const isPlainTextResponse = some(
    includes('text/plain'),
    responseHeaders.contentType,
  );

  if (response.status !== 204) {
    try {
      if (isPlainTextResponse) {
        returnValue = await response.text();
      } else {
        returnValue = await response.json();
      }
    } catch (error) {}
  }

  return returnValue;
};

const getBodyParametersFor = contentType => body =>
  contentType === contentTypes.json
    ? JSON.stringify(body)
    : contentType === contentTypes.urlEncoded
    ? encodeToUrlEncoding(body)
    : body;

const resultIsAlreadyWrappedAsCallWasSuccessful = has('callWasSuccessful');
