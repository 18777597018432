import {
  first,
  pipeline,
  split,
} from 'shared-between-everything/src/functionalProgramming';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import workOrderTranslations from './workOrderTranslations';

const translate = localTranslate(workOrderTranslations);

const getWorkOrderUpdateTabNavigationRoutes = routingModel => {
  const mainRouteName = pipeline(routingModel.routeName, split('/'), first);

  return [
    {
      label: translate('maintenancePage'),
      routeName: mainRouteName,
      props: {
        'data-go-to-work-order-details-tab-test': true,
      },
    },

    {
      label: translate('expectedEfforts'),
      routeName: `${mainRouteName}/expected-efforts`,
      props: {
        'data-go-to-expected-efforts-tab-test': true,
        'data-open-expected-efforts-e2e-test': true,
      },
    },
  ];
};

export default getWorkOrderUpdateTabNavigationRoutes;
