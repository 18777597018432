import React from 'react';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';

const EllipsisLink = ({ ...props }) => (
  <Div
    size="sm"
    inlineBlock
    backgroundColor="white"
    padding={{ horizontal: 'xxs' }}
    border={{
      size: 'md',
      color: 'action',
      radius: { size: 'rounded' },
    }}
    {...props}
  >
    <Icon name="ellipsis" size="sm" />
  </Div>
);

export default EllipsisLink;
