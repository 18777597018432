export default {
  en: {
    teams: 'Teams',
    team: 'Team',
    teamName: 'Name',
    costCentres: 'Cost centres',
    departments: 'Departments',
    createTeam: 'Create new team',
    createTeamButton: 'Create new team',
    createSuccess: 'Created',
    backButton: 'Back',
    updateTeamButton: 'Update',
    updateSuccess: 'Team updated',
    deleteConfirmation: 'Confirm delete?',
    deleteSuccess: 'Team deleted',
    foremen: 'Foremen',
    teamManager: 'Team manager',
    openTeamDetailTab: 'Details',
    openTeamEffortCatalogMaintenanceTab: 'Effort catalogs',
    addCatalogForTeam: 'Add',
    removeCatalogFromTeam: 'Remove',
    noCatalogs: 'Empty',
    selectedTeamCatalogs: 'Selected team catalogs',
    nonSelectedTeamCatalogs: 'Non-selected team catalogs',
    teamEffortDefinitionCatalogsHeading: 'Effort catalogs - {teamName}',
    submitTeamCatalogs: 'Update',
    teamCatalogUpdateSuccess: 'Team catalog update success',
  },
  fi: {
    teams: 'Tiimit',
    team: 'Tiimi',
    teamName: 'Nimi',
    costCentres: 'Kustannuspaikat',
    departments: 'Osastot',
    createTeam: 'Luo uusi tiimi',
    createTeamButton: 'Luo uusi tiimi',
    createSuccess: 'Luotu',
    backButton: 'Takaisin',
    updateTeamButton: 'Päivitä tiimi',
    updateSuccess: 'Tiimi päivitetty',
    deleteConfirmation: 'Poistetaanko?',
    deleteSuccess: 'Tiimi poistettu',
    foremen: 'Työpäälliköt',
    teamManager: 'Tiimipäällikkö',
    openTeamDetailTab: 'Tiedot',
    openTeamEffortCatalogMaintenanceTab: 'Yksikkölistat',
    addCatalogForTeam: 'Lisää tiimille',
    removeCatalogFromTeam: 'Poista tiimiltä',
    noCatalogs: 'Tyhjä',
    selectedTeamCatalogs: 'Valitut yksikkölistat',
    nonSelectedTeamCatalogs: 'Muut yksikkölistat',
    teamEffortDefinitionCatalogsHeading: '{teamName}',
    submitTeamCatalogs: 'Päivitä yksikkölistat',
    teamCatalogUpdateSuccess: 'Yksikkölistat päivitetty',
  },
  pl: {
    teams: 'Zespóły',
    team: 'Zespół',
    teamName: 'Imię',
    costCentres: 'Centrum kosztowe',
    departments: 'Działy',
    createTeam: 'Utwórz nowy zespół',
    createTeamButton: 'Utwórz nowy zespół',
    createSuccess: 'Utworzono',
    backButton: 'Wstecz',
    updateTeamButton: 'Aktualizuj',
    updateSuccess: 'Zespół zaktualizowany',
    deleteConfirmation: 'Potwierdź usunięcie?',
    deleteSuccess: 'Zespół usunięty',
    foremen: 'Kierownik zespołu',
    teamManager: 'Kierownik działu',
    openTeamDetailTab: 'Details',
    openTeamEffortCatalogMaintenanceTab: 'Effort catalogs',
    addCatalogForTeam: 'Add',
    removeCatalogFromTeam: 'Remove',
    noCatalogs: 'Empty',
    selectedTeamCatalogs: 'Selected team catalogs',
    nonSelectedTeamCatalogs: 'Non-selected team catalogs',
    teamEffortDefinitionCatalogsHeading: 'Effort catalogs - {teamName}',
    submitTeamCatalogs: 'Update',
    teamCatalogUpdateSuccess: 'Team catalog update success',
  },
  se: {
    teams: 'Team',
    team: 'Team',
    teamName: 'Namn',
    costCentres: 'Avdelning',
    departments: 'CA',
    createTeam: 'Skapa nytt team',
    createTeamButton: 'Skapa nytt team',
    createSuccess: 'Skapad',
    backButton: 'Tillbaka',
    updateTeamButton: 'Uppdatera',
    updateSuccess: 'Team uppdaterad',
    deleteConfirmation: 'Bekräfta borttagning?',
    deleteSuccess: 'Team borttagen',
    foremen: 'Arbetsledare',
    teamManager: 'Teamchef',
    openTeamDetailTab: 'Detaljer',
    openTeamEffortCatalogMaintenanceTab: 'Arbetsmoment',
    addCatalogForTeam: 'Lägg till',
    removeCatalogFromTeam: 'Ta bort',
    noCatalogs: 'Tom',
    selectedTeamCatalogs: 'Välj arbetsmoment per team',
    nonSelectedTeamCatalogs: 'Inga valda arbetsmoment per team',
    teamEffortDefinitionCatalogsHeading: 'Effort arbetsmoment - {teamName}',
    submitTeamCatalogs: 'Uppdatera',
    teamCatalogUpdateSuccess: 'Teamets arbetsmoment uppdaterat',
  },
};
