import identity from 'lodash/fp/identity';
import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

const getTeams = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/teams',
    method: 'GET',
    outputMapper: identity,
  },
  decoratorConfig: {
    spinnerName: 'loading-teams',
  },
});

export default getTeams;
