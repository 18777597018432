import moment from 'moment-timezone';

export default (date, timeInHours) => {
  if (timeInHours === null) {
    return null;
  }

  if (timeInHours === undefined) {
    return null;
  }

  const hoursMinutes = timeInHours.split(':');

  return moment(date)
    .hours(hoursMinutes[0])
    .minute(hoursMinutes[1])
    .utc()
    .format();
};
