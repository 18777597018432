import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

const callForEffortDefinitionCatalogs = getAuthenticatedCall({
  apiCallConfig: {
    path:
      '/api/effort-definition-catalog-maintenance/get-effort-definition-catalogs',
    method: 'GET',
  },
  decoratorConfig: {},
});

export default callForEffortDefinitionCatalogs;
