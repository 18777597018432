import parameterInputMapperFor from 'shared-between-everything/src/doings/parameterInputMapperFor/parameterInputMapperFor';
import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

const callForUpdateOfCatalogs = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/modify-team/{teamId}/update-effort-catalogs',

    method: 'POST',

    inputMapper: parameterInputMapperFor({
      pathParameters: {
        teamId: 'teamId',
      },

      bodyParameters: 'catalogIds',
    }),
  },
});

export default callForUpdateOfCatalogs;
