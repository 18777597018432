import React from 'react';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import GoBackButton from 'shared-between-front-ends/src/components/public/GoBackButton/GoBackButton';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Page from 'shared-between-front-ends/src/components/public/Page/Page';
import PrimaryButton from 'shared-between-front-ends/src/components/public/PrimaryButton/PrimaryButton';
import RadioButtonGroup from 'shared-between-front-ends/src/components/public/RadioButtonGroup/RadioButtonGroup';
import TextInput from 'shared-between-front-ends/src/components/public/TextInput/TextInput';
import TileBag from 'shared-between-front-ends/src/components/public/TileBag/TileBag';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';

import resourceTranslations from '../../../resource/resourceTranslations';

const translate = localTranslate(resourceTranslations);

const SpecialResourceForm = ({
  firstName,
  lastName,
  role,
  isValidForSpecialResource,
}) => {
  return (
    <Div>
      <Page.Row withPanel>
        <TileBag
          tiles={[
            <TextInput
              label={translate('firstName')}
              model={firstName}
              data-first-name-input-test
            />,

            <TextInput
              label={translate('lastName')}
              model={lastName}
              data-last-name-input-test
            />,

            <RadioButtonGroup
              label={translate('role')}
              model={role}
              data-radio-button-input-test
            />,
          ]}
        />
      </Page.Row>

      <Page.Row alignWithPanel margin={false}>
        <Flex spaceBetween>
          <GoBackButton>{translate('backButton')}</GoBackButton>

          <Gutter />

          <PrimaryButton
            data-submit-button-test
            type="submit"
            enabled={isValidForSpecialResource}
          >
            {translate('createResourceButton')}
          </PrimaryButton>
        </Flex>
      </Page.Row>
    </Div>
  );
};

export default SpecialResourceForm;
