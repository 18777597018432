import { action, computed, observable } from 'mobx';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import ConfirmationDialogModel from 'shared-between-front-ends/src/App/ConfirmationDialog/ConfirmationDialogModel';
import whenRouteChangesTo from 'shared-between-front-ends/src/decorators/whenRouteChangesTo/whenRouteChangesTo';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import deleteBusinessObjectImport from 'shared-between-front-ends/src/doings/deleteBusinessObject/deleteBusinessObject';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';

import resourceTranslations from '../../../resource/resourceTranslations';

const translate = localTranslate(resourceTranslations);

export default class TeamResourcesModel {
  dependencies = {};

  constructor(
    callForResourcesInTeam = require('./callForResourcesInTeam/callForResourcesInTeam')
      .default,
    routingModel = getModel(RoutingModel),
    confirmationDialogModel = getModel(ConfirmationDialogModel),
    deleteBusinessObject = deleteBusinessObjectImport,
    submitUpdateResource = require('./submitUpdateResource/submitUpdateResource')
      .default,
  ) {
    this.dependencies.callForResourcesInTeam = callForResourcesInTeam;
    this.dependencies.routingModel = routingModel;
    this.dependencies.confirmationDialogModel = confirmationDialogModel;
    this.dependencies.deleteBusinessObject = deleteBusinessObject;
    this.dependencies.submitUpdateResource = submitUpdateResource;
  }

  @observable
  rawResources = [];

  @action
  setRawResources = rawResources => {
    this.rawResources = rawResources;
  };

  @computed
  get teamId() {
    return this.dependencies.routingModel.pathParameters.teamId;
  }

  @computed
  get resources() {
    return this.rawResources.map(resource => ({
      ...resource,

      update: () => {
        this.dependencies.routingModel.setRouteTo({
          name: 'district-team-update-resource',
          pathParameters: {
            districtId: this.dependencies.routingModel.pathParameters
              .districtId,
            teamId: this.teamId,
            resourceId: resource.id,
          },
        });
      },

      delete: async () => {
        const {
          deletionWasDone,
        } = await this.dependencies.deleteBusinessObject({
          path: `/api/resources/${resource.id}`,
          spinnerName: 'deleting-resource',
          confirmationMessage: translate('deleteConfirmation'),
          successMessage: translate('deleteSuccess'),
        });

        if (deletionWasDone) {
          this.refresh();
        }
      },
    }));
  }

  @whenRouteChangesTo('district-team-resources')
  refresh = async () => {
    const {
      response: resources,
    } = await this.dependencies.callForResourcesInTeam({
      teamId: this.teamId,
    });

    this.setRawResources(resources);
  };
}
