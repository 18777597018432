import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

const getWorkOrder = getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/teams/{teamId}/work-orders/{workOrderId}',
    method: 'GET',
  },
  decoratorConfig: {
    spinnerName: 'getting-work-order',
    showSpinnerInstantly: true,
  },
});

export default getWorkOrder;
