/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';
import { Manager, Popper, Reference } from 'react-popper';

import {
  partition,
  pipeline,
} from 'shared-between-everything/src/functionalProgramming';

import Div from '../../private/Element/Div/Div';
import Map from '../Map/Map';

const Position = ({ what, how, whats = [{ what, how }], children }) => {
  const [
    centerOfScreenPositionings,
    anchoredPositionings,
    appointmentHoverPositionings,
  ] = pipeline(whats, partition({ how: 'vertical center of screen' }));

  return (
    <Manager>
      <Reference>{({ ref }) => children({ ref })}</Reference>

      <Map items={centerOfScreenPositionings}>
        {({ what }) =>
          ReactDOM.createPortal(
            what({
              style: {
                position: 'fixed',
                top: '50%',
                left: 0,
                right: 0,
                transform: 'translate(0, -50%)',
                zIndex: 20,
              },
            }),
            document.querySelector('body'),
          )
        }
      </Map>

      <Map items={anchoredPositionings}>
        {({ what, how = 'left top to right top of children' }) => {
          const { flip, offset, placement } = configurations[how];

          return (
            <Div hidden>
              {ReactDOM.createPortal(
                <Popper
                  placement={placement}
                  modifiers={{
                    preventOverflow: { enabled: false },
                    hide: { enabled: false },
                    offset: { offset: offset },
                    flip: { enabled: flip },
                  }}
                >
                  {({ ref, placement, style }) =>
                    what({
                      ref,
                      placement,
                      style: {
                        ...style,
                        zIndex: 20,
                      },
                    })
                  }
                </Popper>,
                document.querySelector('body'),
              )}
            </Div>
          );
        }}
      </Map>

      <Map items={appointmentHoverPositionings}>
        {({ what, how = 'left top to right bottom of children' }) => {
          const { flip, offset, placement } = configurations[how];

          return (
            <Div hidden>
              {ReactDOM.createPortal(
                <Popper
                  placement={placement}
                  modifiers={{
                    preventOverflow: { enabled: false },
                    hide: { enabled: false },
                    offset: { offset: offset },
                    flip: { enabled: flip },
                  }}
                >
                  {({ ref, placement, style }) =>
                    what({
                      ref,
                      placement,
                      style: {
                        ...style,
                        zIndex: 20,
                      },
                    })
                  }
                </Popper>,
                document.querySelector('body'),
              )}
            </Div>
          );
        }}
      </Map>
    </Manager>
  );
};

const configurations = {
  'left top to right top of children': {
    placement: 'right-start',
    offset: '0,4',
    flip: true,
  },

  'left top to right bottom of children': {
    placement: 'right-end',
    offset: '0,4',
    flip: true,
  },

  'top center to center of children': {
    placement: 'bottom',
    offset: '0,-50%r',
    flip: false,
  },
};

export default Position;
