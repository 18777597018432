import noop from 'lodash/fp/noop';
import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import schedulerTranslations from '../../../../../resma/front-end/src/App/schedulers/schedulerTranslations';
import localTranslate from '../../../doings/localTranslate/localTranslate';
import Div from '../../private/Element/Div/Div';
import BodyText from '../BodyText/BodyText';
import CheckboxInput from '../CheckboxInput/CheckboxInput';
import Flex from '../Flex/Flex';
import Gutter from '../Gutter/Gutter';
import TimeInput from '../TimeInput/TimeInput';

const translate = localTranslate(schedulerTranslations);

const HourInput = ({
  model,
  lunch,
  paddingSize = 'zero',
  readOnly = model.duration.readOnly,
  setValueFor = model.setValueFor || noop,
  ...props
}) => {
  return readOnly ? (
    <Div data-hour-values-test {...props}>
      <Flex centered>
        <Flex centered pileVertically width="sm">
          <BodyText>{translate('startTime')}</BodyText>

          <Gutter size="xs" />

          <BodyText size="xxs">{model.startTime.value}</BodyText>
        </Flex>

        <Flex centered pileVertically width="sm">
          <BodyText>{translate('endTime')}</BodyText>

          <Gutter size="xs" />

          <BodyText size="xxs">{model.endTime.value} </BodyText>
        </Flex>
      </Flex>

      <Gutter size="md" />

      <Flex centered>
        <BodyText size="xxs">{translate('appointmentDuration')}</BodyText>

        <Gutter size="md" />

        <BodyText size="xxs">{`${model.duration.value} h`}</BodyText>
      </Flex>
    </Div>
  ) : (
    <Div data-hour-input-controls-test {...props}>
      <Flex centered>
        <Flex centered pileVertically width="sm">
          <BodyText>{translate('startTime')}</BodyText>

          <Gutter size="xs" />

          <TimeInput
            model={model.startTime}
            padding={{ horizontal: 'sm', vertical: 'xxs' }}
            data-input-start-time-test
          />
        </Flex>

        <Flex centered pileVertically width="sm">
          <BodyText>{translate('endTime')}</BodyText>

          <Gutter size="xs" />

          <TimeInput
            model={model.endTime}
            padding={{ horizontal: 'sm', vertical: 'xxs' }}
            data-input-end-time-test
          />
        </Flex>
      </Flex>

      <Gutter size="md" />

      {(model.type === 'workOrder' || model.type === 'teamCommon') && (
        <Flex alignRight data-lunch-flex-test padding={{ right: 'sm' }}>
          <BodyText>{translate('lunch')}</BodyText>

          <Gutter size="lg" />

          <CheckboxInput data-lunch-test model={lunch} />
        </Flex>
      )}

      <Flex centered>
        <BodyText size="xxs">{translate('appointmentDuration')}</BodyText>

        <Gutter size="md" />

        <BodyText size="xxs">
          {model.type === 'workOrder' && lunch.value !== null
            ? `${model.duration.value - 0.5} h`
            : `${model.duration.value} h`}
        </BodyText>
      </Flex>
    </Div>
  );
};

export default decorate(observer)(HourInput);
