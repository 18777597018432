const createAbsenceBudgetInputMapper = ({
  teamId,
  yearAndMonth,
  absenceBudget,
}) => ({
  pathParameters: {
    teamId: teamId,
  },
  bodyParameters: {
    yearAndMonth: yearAndMonth,
    absenceBudget: absenceBudget,
  },
});

export default createAbsenceBudgetInputMapper;
