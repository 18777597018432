import { lowerCase, upperCase } from 'lodash/fp';
import getOr from 'lodash/fp/getOr';
import { computed } from 'mobx';
import EmailInputModel from 'shared-between-front-ends/src/components/public/EmailInput/EmailInputModel';
import NotificationsModel from 'shared-between-front-ends/src/components/public/Notifications/NotificationsModel';
import PhoneNumberInputModel from 'shared-between-front-ends/src/components/public/PhoneNumberInput/PhoneNumberInputModel';
import RadioButtonGroupModel from 'shared-between-front-ends/src/components/public/RadioButtonGroup/RadioButtonGroupModel';
import TextInputModel from 'shared-between-front-ends/src/components/public/TextInput/TextInputModel';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import injectInstance from 'shared-between-front-ends/src/decorators/withModel/injectInstance';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';

import TeamSelectInputModel from '../../team/TeamSelectInput/TeamSelectInputModel';
import resourceTranslations from '../resourceTranslations';

const translate = localTranslate(resourceTranslations);

export default class CreateResourceModel {
  dependencies = {};

  constructor(
    getEveryInputModelIsValid = require('shared-between-front-ends/src/doings/getEveryInputModelIsValid/getEveryInputModelIsValid')
      .default,
    routingModel = getModel(RoutingModel),
    submitCreateResource = require('./submitCreateResource/submitCreateResource')
      .default,
    notificationsModel = getModel(NotificationsModel),
  ) {
    this.dependencies.getEveryInputModelIsValid = getEveryInputModelIsValid;
    this.dependencies.routingModel = routingModel;
    this.dependencies.submitCreateResource = submitCreateResource;
    this.dependencies.notificationsModel = notificationsModel;
  }

  erpId = new TextInputModel({ required: true });

  firstName = new TextInputModel({ required: true });

  lastName = new TextInputModel({ required: true });

  phoneNumber = new PhoneNumberInputModel({
    required: true,
  });

  emailAddress = new EmailInputModel({ required: true });

  competences = new TextInputModel({ required: true });

  role = new RadioButtonGroupModel({
    readOnly: false,
    required: true,
    values: [
      {
        label: translate('roles.mechanic'),
        value: 'mechanic',
      },

      {
        label: translate('roles.designer'),
        value: 'designer',
      },

      {
        label: translate('roles.foreman'),
        value: 'foreman',
      },

      {
        label: translate('roles.coordinator'),
        value: 'coordinator',
      },

      {
        label: translate('roles.subcontractor'),
        value: 'subcontractor',
      },

      {
        label: translate('roles.partTimeEmp'),
        value: 'partTimeEmp',
      },

      {
        label: translate('roles.specialResource'),
        value: 'specialResource',
      },
    ],
  });

  team = injectInstance(TeamSelectInputModel);

  setValuesForSpecialResource = () => {
    // Setting ERP-Id value
    const trimmedFirstNameForErpId = upperCase(
      this.firstName.value.replace(/ /g, '').substring(0, 3),
    );
    const trimmedLastNameForErpId = upperCase(
      this.lastName.value.replace(/ /g, '').substring(0, 3),
    );
    const erpIdForSpecialResource = `#${trimmedFirstNameForErpId}_${trimmedLastNameForErpId}#`;
    this.erpId.setValue(erpIdForSpecialResource);

    // Setting email addreess value
    const trimmedFirstNameForEmail = lowerCase(this.firstName.value)
      .replace(/ /g, '')
      .substring(0, 6);
    const trimmedLastNameForEmail = lowerCase(this.lastName.value)
      .replace(/ /g, '')
      .substring(0, 6);
    const emailAddressSpecialResource = `${trimmedFirstNameForEmail}.${trimmedLastNameForEmail}@fakemail.se`;
    this.emailAddress.setValue(emailAddressSpecialResource);

    // Setting phone number value
    this.phoneNumber.setValue('+46123456789');

    // Setting competences value
    this.competences.setValue('Special Competence');
  };

  submit = async () => {
    if (this.role.value === 'specialResource') {
      this.setValuesForSpecialResource();
    }

    await this.dependencies.submitCreateResource({
      erpId: this.erpId.value,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      phoneNumber: this.phoneNumber.value,
      emailAddress: this.emailAddress.value,
      competences: this.competences.value,
      role: this.role.value,
      teamId: getOr(null, 'team.value.id', this),
    });

    this.dependencies.notificationsModel.setSuccess(translate('createSuccess'));

    this.erpId.clear();
    this.firstName.clear();
    this.lastName.clear();
    this.phoneNumber.clear();
    this.emailAddress.clear();
    this.competences.clear();
    this.role.clear();
    this.team.clear();

    this.dependencies.routingModel.goBack();
  };

  @computed
  get isValid() {
    return this.dependencies.getEveryInputModelIsValid(
      this.erpId,
      this.firstName,
      this.lastName,
      this.phoneNumber,
      this.emailAddress,
      this.competences,
      this.role,
    );
  }

  @computed
  get isSpecialResource() {
    return this.role.value === 'specialResource';
  }

  @computed
  get isValidForSpecialResource() {
    return this.dependencies.getEveryInputModelIsValid(
      this.firstName,
      this.lastName,
      this.role,
    );
  }
}
