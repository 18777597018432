import { action, computed, observable } from 'mobx';
import ConfirmationDialogModel from 'shared-between-front-ends/src/App/ConfirmationDialog/ConfirmationDialogModel';
import NotificationsModel from 'shared-between-front-ends/src/components/public/Notifications/NotificationsModel';
import whenObserved from 'shared-between-front-ends/src/decorators/whenObserved/whenObserved';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import getTeamsImport from '../getTeams/getTeams';
import deleteTeamImport from './deleteTeam/deleteTeam';

import teamTranslations from '../teamTranslations';

const translate = localTranslate(teamTranslations);

export default class TeamsModel {
  dependencies = {};

  constructor(
    getTeams = getTeamsImport,
    routingModel = getModel(RoutingModel),
    confirmationDialogModel = getModel(ConfirmationDialogModel),
    notificationsModel = getModel(NotificationsModel),
    deleteTeam = deleteTeamImport,
  ) {
    this.dependencies.getTeams = getTeams;
    this.dependencies.routingModel = routingModel;
    this.dependencies.confirmationDialogModel = confirmationDialogModel;
    this.dependencies.notificationsModel = notificationsModel;
    this.dependencies.deleteTeam = deleteTeam;
  }

  @observable
  rawTeams = [];

  @action
  setRawTeams = rawTeams => {
    this.rawTeams = rawTeams;
  };

  @computed get teams() {
    return this.rawTeams.map(team => ({
      ...team,

      update: () => {
        this.dependencies.routingModel.setRouteTo({
          name: 'district-update-team',
          pathParameters: {
            districtId: team.districtId,
            teamId: team.id,
          },
        });
      },

      delete: async () => {
        const shouldDelete = await this.dependencies.confirmationDialogModel.getConfirmation(
          translate('deleteConfirmation'),
        );

        if (!shouldDelete) {
          return;
        }

        await this.dependencies.deleteTeam({
          teamId: team.id,
        });

        this.dependencies.notificationsModel.setSuccess(
          translate('deleteSuccess'),
        );

        this.refresh();
      },
    }));
  }

  @whenObserved('teams')
  refresh = async () => {
    const { response: teams } = await this.dependencies.getTeams();

    this.setRawTeams(teams);
  };
}
